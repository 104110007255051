import blue from "@material-ui/core/colors/blue";

const NORMAL_PREP_TIME_THRESHHOLD = 15 * 60; // 15 minutes into Seconds
const DELAYED_PREP_TIME_THRESHHOLD = 25 * 60; // 25 minutes into Seconds

const OrderOutlineSuccessColor = "#B9D47F";
const OrderBackgroundSuccessColor = "#B9D47F4A";
const OrderOutlineIdleColor = "#FFBB59";
const OrderOutlinePreErrorColor = "rgba(255, 136, 0, 1)";
const OrderBackgroundPreErrorColor = "rgba(255, 136, 0, 0.1)";
const OrderBackgroundErrorColor = "rgba(200, 0, 0, 0.15)";
const OrderOutlineErrorColor = "#F2695D";

const OrderStyleColors = (outlineColor, backgroundColor) => ({
  outlineColor,
  backgroundColor,
});

export const generateCardStyleByColors = (outlineColor, backgroundColor) => ({
  boxShadow: `0px 0px 0px 6px ${outlineColor} inset`,
  backgroundColor,
});

export const orderListItemMuiStyles = (defaultTheme) => ({
  root: {
    color: "white",
    flexDirection: "row",
    boxShadow: "none",
    borderRadius: 20,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: defaultTheme.transitions.create("transform", {
      duration: defaultTheme.transitions.duration.shortest,
    }),
    fontSize: "5.5vh",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  orderNumber: {
    marginRight: "1vw",
    textAlign: "right",
    fontWeight: 600,
    "@media (max-width: 560px)": {
      fontSize: "3.2rem",
    },
  },
  cardDetails: {
    backgroundColor: "#DDD",
  },
  expandedContent: {
    alignItems: "center",
    justifyContent: "center",
  },
  venueItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
  },
  dotVenueItem: {
    fontSize: "0.6em",
    marginRight: "4px",
  },
  expandedWrapper: {
    margin: "inherit",
    boxShadow: "none",
  },
  doneButton: {
    fontSize: 65,
    "@media (max-width: 560px)": {
      fontSize: 50,
    },
  },
  alarmButton: {
    fontSize: 65,
    "@media (max-width: 560px)": {
      fontSize: 50,
    },
  },
  printButton: {
    fontSize: 52,
    color: "rgba(128,128,128,1)",
  },
  printButtonDisabled: {
    fontSize: 52,
    color: "rgba(128,128,128,0.4)",
  },
  fabDoneContainer: {
    width: 92,
    height: 92,
    "@media (max-width: 560px)": {
      width: 75,
      height: 75,
    },
    marginLeft: 16,
  },
  fabDoneLabel: {
    width: "80%",
    height: "80%",
    borderStyle: "solid",
    borderRadius: "50%",
    borderWidth: "4px",
  },
  fabPrintContainer: {
    width: 60,
    height: 60,
    marginLeft: 16,
  },
  divider: {
    color: "black",
    borderBottom: "1px solid #d3d3d3",
    marginTop: "1rem",
  },
  content: {
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    marginTop: "2.5%",
  },
  cardContentDetails: {
    padding: 8,
    width: "100%",
    "&:last-child": { padding: 0 },
  },
  itemOptions: {
    paddingLeft: "1rem",
  },
  expandIcon: {
    textAlign: "center",
  },
  clickableCard: {
    paddingLeft: 32,
    paddingRight: 16,
    paddingTop: 8,
    borderRadius: 16,
  },
  expandButton: {
    marginBottom: 0,
  },
  timerCounter: {
    fontWeight: "bold",
  },
  expandableItem: {
    padding: 0,
  },
  progress: {
    position: "absolute",
    top: "-6px",
    right: -5,
    height: "105px !important",
    width: "105px !important",
    "@media (max-width: 560px)": {
      top: "-5px",
      right: -10,
      height: "85px !important",
      width: "85px !important",
    },
    color: blue[500],
  },
  progressContainer: {
    position: "relative",
    marginRight: "1vw",
    textAlign: "right",
  },
});

export const getStyleColorsByOrderTime = (
  doneAt,
  orderTimeDiff,
  isHobbyCook
) => {
  if (isHobbyCook) {
    if (doneAt) {
      return OrderStyleColors(OrderOutlineSuccessColor, "white");
    }
    return OrderStyleColors(OrderOutlineIdleColor, "white");
  }
  if (orderTimeDiff < 0 && !doneAt) {
    return OrderStyleColors("rgba(128,128,128,1)", "rgba(128,128,128,0.3)");
  }
  if (orderTimeDiff < NORMAL_PREP_TIME_THRESHHOLD) {
    return OrderStyleColors(
      OrderOutlineSuccessColor,
      OrderBackgroundSuccessColor
    );
  }
  if (
    orderTimeDiff >= NORMAL_PREP_TIME_THRESHHOLD &&
    orderTimeDiff < DELAYED_PREP_TIME_THRESHHOLD
  ) {
    return OrderStyleColors(
      OrderOutlinePreErrorColor,
      OrderBackgroundPreErrorColor
    );
  }
  return OrderStyleColors(OrderOutlineErrorColor, OrderBackgroundErrorColor);
};
