import * as types from "../types";

export const retrieveVenue = (venueId) => ({
  type: types.venue.RETRIEVE_VENUE.REQUEST,
  venueId,
});

export const retrieveVenueSuccess = (venue) => ({
  type: types.venue.RETRIEVE_VENUE.SUCCESS,
  venue,
});

export const retrieveVenueFailure = (error) => ({
  type: types.venue.RETRIEVE_VENUE.FAILURE,
  error,
});
export const toggleSectionAvailability = (venueId, sectionId, available) => ({
  type: types.venue.TOGGLE_SECTION_AVAILABILITY.REQUEST,
  venueId,
  sectionId,
  available,
});

export const toggleSectionAvailabilitySuccess = (
  venueId,
  sectionId,
  available
) => ({
  type: types.venue.TOGGLE_SECTION_AVAILABILITY.SUCCESS,
  venueId,
  sectionId,
  available,
});

export const toggleSectionAvailabilityFailure = (venueId, error) => ({
  type: types.venue.TOGGLE_SECTION_AVAILABILITY.FAILURE,
  venueId,
  error,
});

export const toggleItemAvailability = (venueId, itemId, available) => ({
  type: types.venue.TOGGLE_ITEM_AVAILABILITY.REQUEST,
  venueId,
  itemId,
  available,
});

export const toggleItemAvailabilitySuccess = (venueId, itemId, available) => ({
  type: types.venue.TOGGLE_ITEM_AVAILABILITY.SUCCESS,
  venueId,
  itemId,
  available,
});

export const toggleItemAvailabilityFailure = (venueId, error) => ({
  type: types.venue.TOGGLE_ITEM_AVAILABILITY.FAILURE,
  venueId,
  error,
});

export const toggleOptionAvailability = (venueId, optionId, available) => ({
  type: types.venue.TOGGLE_OPTION_AVAILABILITY.REQUEST,
  venueId,
  optionId,
  available,
});

export const toggleOptionAvailabilitySuccess = (
  venueId,
  optionId,
  available
) => ({
  type: types.venue.TOGGLE_OPTION_AVAILABILITY.SUCCESS,
  venueId,
  optionId,
  available,
});

export const toggleOptionAvailabilityFailure = (venueId, error) => ({
  type: types.venue.TOGGLE_OPTION_AVAILABILITY.FAILURE,
  venueId,
  error,
});

export const toggleVenueAvailability = (venueId, status) => ({
  type: types.venue.TOGGLE_VENUE_AVAILABILITY.REQUEST,
  venueId,
  status,
});

export const toggleVenueAvailabilitySuccess = (venueId, available) => ({
  type: types.venue.TOGGLE_VENUE_AVAILABILITY.SUCCESS,
  venueId,
  available,
});

export const toggleVenueAvailabilityFailure = (venueId, error) => ({
  type: types.venue.TOGGLE_VENUE_AVAILABILITY.FAILURE,
  venueId,
  error,
});
export const updateVenueTraffic = (venueId, status) => ({
  type: types.venue.UDPATE_VENUE_TRAFFIC.REQUEST,
  venueId,
  status,
});

export const updateVenueTrafficSuccess = (venueId, status, lastUpdate) => ({
  type: types.venue.UDPATE_VENUE_TRAFFIC.SUCCESS,
  venueId,
  status,
  lastUpdate,
});

export const updateVenueTrafficFailure = (venueId, error) => ({
  type: types.venue.UDPATE_VENUE_TRAFFIC.FAILURE,
  venueId,
  error,
});

export const enableAutomaticVenueTraffic = (venueId) => ({
  type: types.venue.AUTOMATIC_VENUE_TRAFFIC.ENABLE,
  venueId,
});

export const disableAutomaticVenueTraffic = (venueId) => ({
  type: types.venue.AUTOMATIC_VENUE_TRAFFIC.DISABLE,
  venueId,
});

export const createItem = (venueId, payload) => ({
  type: types.venue.VENUE_CREATE_ITEM.REQUEST,
  venueId,
  payload,
});

export const createItemSuccess = (venueId, venue) => ({
  type: types.venue.VENUE_CREATE_ITEM.SUCCESS,
  venueId,
  venue,
});

export const createItemFailure = (venueId, error) => ({
  type: types.venue.VENUE_CREATE_ITEM.FAILURE,
  venueId,
  error,
});

export const updateItem = (venueId, itemId, payload) => ({
  type: types.venue.VENUE_UPDATE_ITEM.REQUEST,
  venueId,
  itemId,
  payload,
});

export const updateItemSuccess = (venueId, venue) => ({
  type: types.venue.VENUE_UPDATE_ITEM.SUCCESS,
  venueId,
  venue,
});

export const updateItemFailure = (venueId, error) => ({
  type: types.venue.VENUE_UPDATE_ITEM.FAILURE,
  venueId,
  error,
});

export const updateItemFiles = (venueId, itemId, itemImage, itemVideos) => ({
  type: types.venue.VENUE_UPDATE_ITEM.UPDATE_FILES,
  venueId,
  itemId,
  itemImage,
  itemVideos,
});

export const deleteItem = (venueId, itemId, sectionId, items) => ({
  type: types.venue.VENUE_DELETE_ITEM.REQUEST,
  venueId,
  itemId,
  sectionId,
  items,
});

export const deleteItemSuccess = (venueId, itemId, sectionId) => ({
  type: types.venue.VENUE_DELETE_ITEM.SUCCESS,
  venueId,
  itemId,
  sectionId,
});

export const deleteItemFailure = (venueId, error) => ({
  type: types.venue.VENUE_DELETE_ITEM.FAILURE,
  venueId,
  error,
});

export const toggleDeliveryOrdersStatus = (venueId, status) => ({
  type: types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.REQUEST,
  venueId,
  status,
});

export const toggleDeliveryOrdersStatusSuccess = (venueId, available) => ({
  type: types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.SUCCESS,
  venueId,
  available,
});

export const toggleDeliveryOrdersStatusFailure = (venueId, error) => ({
  type: types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.FAILURE,
  venueId,
  error,
});

export const addExistingOptionToItem = (venueId, itemId, optionId) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_ITEM.REQUEST,
  venueId,
  itemId,
  optionId,
});

export const addExistingOptionToItemSuccess = (venueId, venue) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_ITEM.SUCCESS,
  venueId,
  venue,
});

export const addExistingOptionToItemFailure = (venueId, error) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_ITEM.FAILURE,
  venueId,
  error,
});

export const addExistingOptionToOption = (
  venueId,
  optionId,
  option,
  newOptionId
) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_OPTION.REQUEST,
  venueId,
  optionId,
  option,
  newOptionId,
});

export const addExistingOptionToOptionSuccess = (venueId, venue) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_OPTION.SUCCESS,
  venueId,
  venue,
});

export const addExistingOptionToOptionFailure = (venueId, error) => ({
  type: types.venue.ADD_EXISTING_OPTION_TO_OPTION.FAILURE,
  venueId,
  error,
});

export const createSection = (venueId, names) => ({
  type: types.venue.VENUE_CREATE_SECTION.REQUEST,
  venueId,
  names,
});

export const createSectionSuccess = (sectionId, names) => ({
  type: types.venue.VENUE_CREATE_SECTION.SUCCESS,
  sectionId,
  names,
});

export const createSectionFailure = (venueId, error) => ({
  type: types.venue.VENUE_CREATE_SECTION.FAILURE,
  venueId,
  error,
});

export const updateSection = (venueId, sectionId, names) => ({
  type: types.venue.VENUE_UPDATE_SECTION.REQUEST,
  venueId,
  sectionId,
  names,
});

export const updateSectionSuccess = (venueId, sectionId) => ({
  type: types.venue.VENUE_UPDATE_SECTION.SUCCESS,
  venueId,
  sectionId,
});

export const updateSectionFailure = (sectionId, error) => ({
  type: types.venue.VENUE_UPDATE_SECTION.FAILURE,
  sectionId,
  error,
});

export const updateSectionOrder = (venueId, sectionOrder) => ({
  type: types.venue.VENUE_UPDATE_SECTION_ORDER.REQUEST,
  venueId,
  sectionOrder,
});

export const updateSectionOrderSuccess = (venueId, sectionOrder) => ({
  type: types.venue.VENUE_UPDATE_SECTION_ORDER.SUCCESS,
  venueId,
  sectionOrder,
});

export const updateSectionOrderFailure = (venueId, error) => ({
  type: types.venue.VENUE_UPDATE_SECTION_ORDER.FAILURE,
  venueId,
  error,
});
