import React from "react";
import PropTypes from "prop-types";
import { Typography, Paper, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Moment } from "moment";

const styles: any = {
  paper: {
    width: 160,
    height: 170,
    display: "flex",
    flexDirection: "column",
  },
  imgContainer: {
    height: 90,
    width: "100%",
    display: "flex",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  img: {
    maxHeight: 70,
    maxWidth: "calc(100% - 40px)",
  },
  contentContainer: {
    position: "relative",
    flexGrow: 1,
  },
  discountBadge: {
    backgroundColor: "#00AAF3",
    position: "absolute",
    left: 10,
    top: -14,
    paddingLeft: 10,
    paddingRight: 10,
  },
  discountText: {
    fontWeight: "bold",
    color: "#fff",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  name: {
    fontWeight: "bold",
    fontSize: 16,
  },
  paddedName: {
    fontWeight: "bold",
    fontSize: 16,
    paddingTop: 12,
  },
  supertitle: {
    fontWeight: "bold",
    color: "#00AAF3",
    fontSize: 10,
  },
};

interface CampaignPreviewProps {
  name?: string;
  logo: any;
  amount?: string;
  allDay: boolean;
  fromTime: Moment | null;
  toTime: Moment | null;
  classes: any;
}

const CampaignPreview: React.FC<CampaignPreviewProps> = ({
  name,
  logo,
  amount,
  allDay,
  fromTime,
  toTime,
  classes,
}): ReturnType<React.FC<CampaignPreviewProps>> => {
  const { t } = useTranslation("createCampaign");

  return (
    <Paper className={classes.paper}>
      <div
        className={classes.imgContainer}
        style={{ backgroundColor: logo.background }}
      >
        <img
          className={classes.img}
          src={logo.images.default}
          alt="Venue Logo"
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.discountBadge}>
          <Typography variant="body2" className={classes.discountText}>
            {t("common:percent", {
              data: {
                value: amount ? parseInt(amount, 10) / 100 : 0.3,
              },
            })}
          </Typography>
        </div>
        <div className={classes.textContainer}>
          {!allDay ? (
            <Typography variant="body2" className={classes.supertitle}>
              {fromTime ? fromTime.format("HH:mm") : "13:00"} -
              {toTime ? toTime.format("HH:mm") : "15:00"}
            </Typography>
          ) : null}
          <Typography
            variant="body2"
            className={allDay ? classes.paddedName : classes.name}
          >
            {!name || name === "" ? t("namePlaceholder") : name}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

CampaignPreview.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.object,
  amount: PropTypes.string,
  allDay: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  fromTime: PropTypes.object as any,
  toTime: PropTypes.object as any,
};

CampaignPreview.defaultProps = {
  name: undefined,
  logo: null,
  amount: undefined,
  fromTime: null,
  toTime: null,
};

export default withStyles(styles)(CampaignPreview);
