import * as types from "../types";
import moment from "moment-timezone";

export const retrieveOrders = (venueId, start, end, isHobbyCook) => ({
  type: types.orders.RETRIEVE_ORDERS.REQUEST,
  venueId,
  start,
  end,
  isHobbyCook,
});

export const retrieveOrdersSuccess = (orders) => ({
  type: types.orders.RETRIEVE_ORDERS.SUCCESS,
  orders,
});

export const retrieveOrdersFailure = (error) => ({
  type: types.orders.RETRIEVE_ORDERS.FAILURE,
  error,
});

export const markOrderAsDone = (
  venueId,
  orderId,
  userId,
  usedMode,
  ttl,
  retries
) => ({
  type: types.orders.MARK_ORDER_AS_DONE.REQUEST,
  venueId,
  orderId,
  userId,
  usedMode,
  retries: retries || 0,
  doneAt: moment().unix(),
  ttl: ttl || moment().add(7, "days").unix(),
});

export const markOrderAsDoneSuccess = (orderId, usedMode, doneAt) => ({
  type: types.orders.MARK_ORDER_AS_DONE.SUCCESS,
  orderId,
  usedMode,
  doneAt,
  lastPushNotification: Date.now(),
});

export const reprintOrder = (venueId, order) => ({
  type: types.orders.REPRINT_ORDER.REQUEST,
  venueId,
  order,
});

export const reprintOrderSuccess = (orderId) => ({
  type: types.orders.REPRINT_ORDER.SUCCESS,
  orderId,
  lastPrinted: Date.now(),
});

export const reprintOrderFailure = (error) => ({
  type: types.orders.REPRINT_ORDER.FAILURE,
  error,
});

export const acknowledgeOrder = (venueId, orderId, userId) => ({
  type: types.orders.ACKNOWLEDGE_ORDER.REQUEST,
  venueId,
  orderId,
  userId,
});

export const acknowledgeOrderSuccess = (orderId, acknowledgedAt) => ({
  type: types.orders.ACKNOWLEDGE_ORDER.SUCCESS,
  orderId,
  acknowledgedAt,
});

export const acknowledgeOrderFailure = (error) => ({
  type: types.orders.ACKNOWLEDGE_ORDER.FAILURE,
  error,
});

export const updateOrders = (order) => ({
  type: types.orders.UPDATE_ORDERS,
  order,
});

export const purgeOrderRequest = (orderId) => ({
  type: types.orders.PURGE_ORDER_REQUEST,
  orderId,
});

export const purgeAllPendingOrderRequests = () => ({
  type: types.orders.PURGE_ALL_PENDING_ORDER_REQUESTS,
});

export const orderCheckIn = (orderId, tableId, table) => ({
  type: types.orders.ORDER.CHECKIN,
  orderId,
  tableId,
  table,
});

export const checkOrderedByItemId = (
  venueId,
  itemId,
  start,
  end,
  isHobbyCook
) => ({
  type: types.orders.ORDER.CHECK_ORDERED,
  venueId,
  itemId,
  start,
  end,
  isHobbyCook,
});

export const checkOrderedByItemIdSuccess = (isOrdered) => ({
  type: types.orders.ORDER.CHECK_ORDERED_SUCCESS,
  isOrdered,
});

export const checkOrderedByItemIdFailure = (error) => ({
  type: types.orders.ORDER.CHECK_ORDERED_FAILURE,
  error,
});

export const updateOrderTOGO = (orderId) => ({
  type: types.orders.UPDATE_ORDER_TOGO,
  orderId,
});
