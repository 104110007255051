import i18next from "i18next";
import moment from "moment";
import { SUPPORTED_LOCALES } from "../constants";

import de from "../strings/de";
import en from "../strings/en";

interface Money {
  value: number;
  currency: string;
}

function convertMoney({ value, currency }: Money): number {
  switch (currency) {
    case "EUR":
      return value / 100;
    default:
      return value;
  }
}

const fallbackLng = "de";
export function getUsableLocale(): string {
  return (
    SUPPORTED_LOCALES.find((supported) => supported === i18next.language) ||
    fallbackLng
  );
}

i18next.init({
  fallbackLng: "de",
  debug: true,
  interpolation: {
    format: (value: any, format: any) => {
      switch (format) {
        case "number":
          return new Intl.NumberFormat(getUsableLocale(), {
            maximumFractionDigits: 2,
          }).format(value.value);
        case "percent":
          return `${new Intl.NumberFormat(getUsableLocale(), {
            maximumFractionDigits: 2,
          }).format(value.value * 100)}%`;
        case "money":
          return new Intl.NumberFormat(getUsableLocale(), {
            style: "currency",
            currency: value.currency,
          }).format(convertMoney(value));
        case "time-llll":
          return moment.unix(value).locale(getUsableLocale()).format("llll");
        case "time-LT":
          return moment.unix(value).locale(getUsableLocale()).format("LT");
        case "calendar": {
          const result =
            moment.unix(value).locale(getUsableLocale()).calendar() || "";
          const firstChar = result[0] || "";
          return `${firstChar.toLowerCase()}${result.substr(
            1,
            result.length - 1
          )}`;
        }
        case "duration":
          return value.locale(getUsableLocale()).humanize(true);
        case "weekDay":
          return moment()
            .isoWeekday(value)
            .locale(getUsableLocale())
            .format("dddd");
        case "orderTime": {
          const time = moment();
          time.set({
            hour: parseInt(`${value[0]}${value[1]}`, 10),
            minute: parseInt(`${value[2]}${value[3]}`, 10),
            second: 0,
            millisecond: 0,
          });
          return time.locale(getUsableLocale()).format("LT");
        }
        default:
          return value;
      }
    },
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    wait: true,
  },
  resources: {
    de,
    en,
  },
});

export default i18next;
