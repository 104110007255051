import { getUsableLocale } from "./i18n";

export function moveItemInArray(arr, el, up) {
  const oldIndex = arr.indexOf(el);

  const newIndex = up ? oldIndex - 1 : oldIndex + 1;

  if (newIndex < 0 || newIndex >= arr.length) {
    return arr;
  }

  if (newIndex >= arr.length) {
    let k = newIndex - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function buildSelectOptionList(menu, strings) {
  const options = [];
  const mergedOptions = {};
  Object.keys(menu.sections).forEach((section) => {
    menu.sections[section].items.forEach((item) => {
      const optionId = menu.items[item].options;
      if (optionId) {
        if (mergedOptions[optionId]) {
          mergedOptions[
            optionId
          ] = `${mergedOptions[optionId]}, ${strings[item]}`;
        } else {
          mergedOptions[optionId] = `Option von: ${strings[item]}`;
        }
      }
    });
  });
  Object.keys(mergedOptions).forEach((optionId) =>
    options.push({
      id: optionId,
      label: mergedOptions[optionId],
    })
  );
  options.sort((a, b) => a.label.localeCompare(b.label));
  return options;
}

export function buildSelectAllOptionList(menu, strings) {
  const options = [];
  Object.keys(menu.options).forEach((optionId) => {
    if (strings[optionId]) {
      options.push({
        id: optionId,
        label: `${strings[optionId]} — ${optionId} — ${
          menu.options[optionId].price || 0
        }`,
      });
    }
  });
  options.sort((a, b) => a.label.localeCompare(b.label));
  return options;
}

export function currencyFormat(input) {
  const locale = getUsableLocale();
  if (locale !== "de") {
    return input;
  }
  return input.replace(/[,.]/g, (x) => {
    return x === "," ? "." : ",";
  });
}

export const isEmpty = (value) =>
  typeof value === "undefined" || value === null || value === false;
export const isNumeric = (value) =>
  !isEmpty(value) && !Number.isNaN(Number(value));

export const vibrate = (pattern) => {
  navigator.vibrate =
    navigator.vibrate ||
    navigator.webkitVibrate ||
    navigator.mozVibrate ||
    navigator.msVibrate;

  if (navigator.vibrate) {
    navigator.vibrate(pattern);
  }
};
