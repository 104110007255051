import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Typography, Modal, withStyles } from "@material-ui/core";
import { Close, CheckCircle, Error, Info } from "@material-ui/icons";

import { StatusEnum } from "../../constants";

import useStyles from "./muiStyles";

const ColorByStatus = {
  [StatusEnum.ERROR]: "#F2695D",
  [StatusEnum.IDLE]: "#2C2F3A",
  [StatusEnum.WARNING]: "#FFBB59",
  [StatusEnum.SUCCESS]: "#B9D47F",
};

const NotificationModal = ({
  classes,
  info,
  isOpen,
  hideIcon,
  closeNotificationModalAction,
  onCloseIcon,
  closeText,
  closeButtonStyle,
  hideButtonClose,
  title,
  t,
}) => (
  <Modal
    className={classes.containerModal}
    open={isOpen}
    onClose={closeNotificationModalAction}
  >
    <div className={classes.modal}>
      <div className={classes.closeIconContainer}>
        <Close onClick={onCloseIcon || closeNotificationModalAction} />
      </div>
      {title && (
        <div className={classes.titleContainer}>
          <Typography variant="body1">{title}</Typography>
        </div>
      )}
      {hideIcon ? (
        <div
          style={{
            justifyContent: "center",
            fontSize: "1em",
          }}
          className={classes.msg}
        >
          <Typography
            style={{ color: ColorByStatus[info.status] }}
            variant="body1"
          >
            {info.message}
          </Typography>
        </div>
      ) : (
        <div className={classes.msgContainer}>
          <div className={classes.statusContainer}>
            {info.status === StatusEnum.ERROR && (
              <Error
                className={classes.icon}
                style={{ fill: ColorByStatus[StatusEnum.ERROR] }}
              />
            )}
            {info.status === StatusEnum.SUCCESS && (
              <CheckCircle
                className={classes.icon}
                style={{ fill: ColorByStatus[StatusEnum.SUCCESS] }}
              />
            )}
            {info.status === StatusEnum.IDLE && (
              <Info
                className={classes.icon}
                style={{ fill: ColorByStatus[StatusEnum.IDLE] }}
              />
            )}
          </div>
          <div className={classes.msg} style={{ textAlign: "left" }}>
            <Typography
              style={{ color: ColorByStatus[info.status] }}
              variant="body1"
            >
              {info.message}
            </Typography>
          </div>
        </div>
      )}
      {!hideButtonClose && (
        <div className={classes.closeContainer}>
          <Typography variant="body1">
            <button
              type="button"
              onClick={closeNotificationModalAction}
              className={classes.close}
              style={closeButtonStyle}
            >
              {closeText || t("close")}
            </button>
          </Typography>
        </div>
      )}
    </div>
  </Modal>
);

NotificationModal.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.object.isRequired,
  info: PropTypes.object,
  closeButtonStyle: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  hideButtonClose: PropTypes.bool,
  closeText: PropTypes.string,
  hideIcon: PropTypes.bool,
  title: PropTypes.string,
  closeNotificationModalAction: PropTypes.func.isRequired,
  onCloseIcon: PropTypes.func,
};

NotificationModal.defaultProps = {
  hideIcon: false,
  hideButtonClose: false,
  closeText: null,
  onCloseIcon: null,
  closeButtonStyle: {},
  title: null,
  info: {
    message: "",
    status: StatusEnum.IDLE,
  },
};

export default withTranslation("notificationModal")(
  React.memo(withStyles(useStyles)(NotificationModal))
);
