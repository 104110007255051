import {
  select,
  all,
  takeLatest,
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import * as types from "../types";
import {
  createGetVenueCampaignsAPI,
  createUpdateVenueCampaignAPI,
  createCreateCampaignAPI,
} from "../../lib/venue";
import {
  retrieveCampaignsSuccess,
  retrieveCampaignsFailure,
  updateCampaignSuccess,
  updateCampaignFailure,
  createCampaignFailure,
  createCampaignSuccess,
} from "../actions/marketing";

function* retrieveCampaignsSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId } = action;

    const response = yield call(fetch, createGetVenueCampaignsAPI(venueId), {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    if (!response.ok) {
      throw new Error("Error retrieving marketing campaigns");
    }

    const data = yield call([response, response.json]);
    yield put(retrieveCampaignsSuccess(data));
  } catch (error) {
    yield put(retrieveCampaignsFailure(error));
  }
}

function* updateCampaignStatusSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, campaignId, campaignStatus, benefits } = action;

    const response = yield call(
      fetch,
      createUpdateVenueCampaignAPI(venueId, campaignId),
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          status: campaignStatus,
          benefits,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Error update marketing campaigns");
    }

    const { newStatus } = yield call([response, response.json]);

    yield put(updateCampaignSuccess(newStatus, campaignId));
  } catch (error) {
    yield put(updateCampaignFailure(error));
  }
}

function* createCampaignSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, name, allDay, timeSlot, amount, modifier, note } = action;

    const response = yield call(fetch, createCreateCampaignAPI(venueId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        name,
        allDay,
        timeSlot,
        amount,
        modifier,
        note,
      }),
    });

    if (!response.ok) {
      throw new Error("Error creating campaign");
    }

    const data = yield call([response, response.json]);
    yield put(createCampaignSuccess(data));
  } catch (error) {
    yield put(createCampaignFailure(error));
  }
}

export default function* marketingRootSaga() {
  yield all([
    takeLatest(
      types.marketing.RETRIEVE_CAMPAIGNS.REQUEST,
      retrieveCampaignsSaga
    ),
    takeLatest(
      types.marketing.UPDATE_CAMPAIGN_STATUS.REQUEST,
      updateCampaignStatusSaga
    ),
    takeEvery(types.marketing.CREATE_CAMPAIGN.REQUEST, createCampaignSaga),
  ]);
}
