import soundfile from "../assets/sounds/new_order.mp3";
import { INSIDE_WEBVIEW } from "../config";
import EventEmitter from "eventemitter3";

export default function addSoundListenerOnEvents(...args) {
  try {
    const sound = new Audio(soundfile);
    const eventEmitter = new EventEmitter();

    if (!INSIDE_WEBVIEW) {
      args.forEach((event) =>
        eventEmitter.on(event, () => sound && sound.play())
      );
    }
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.error("Audio API is not available", error);
    }
  }
}
