import {
  take,
  // takeEvery,
  // race,
  delay,
  put,
  fork,
  cancel,
  // throttle
} from "redux-saga/effects";
import * as types from "../types";
import { send } from "../actions/bridge";
import { TO_NATIVE_PLAY_SOUND } from "@orda/backend-shared-constants/tablet-bridge-events";
import { INSIDE_WEBVIEW } from "../../config";
import soundfile from "../../assets/sounds/new_order.mp3";

const REMINDER_SOUND_DELAY = 10000;

let sound;
if (!INSIDE_WEBVIEW) {
  sound = new Audio(soundfile);
}

function* soundSaga(action) {
  const { eventType } = action;
  while (true) {
    if (INSIDE_WEBVIEW) {
      yield put(send(TO_NATIVE_PLAY_SOUND, { eventType }));
    } else if (sound) {
      sound.play();
    }
    yield delay(REMINDER_SOUND_DELAY);
  }
}

export default function* soundRootSaga() {
  while (true) {
    const action = yield take(types.sound.REMINDER.START);

    const soundTask = yield fork(soundSaga, action);

    yield take(types.sound.REMINDER.STOP);

    yield cancel(soundTask);
  }
}
