import * as types from "../types";
import { CampaignStatus } from "@orda/shared-constants";
import { Modifier } from "@orda/shared-constants/benefits";

export const retrieveCampaignsRequest = (
  venueId: string
): {
  type: string;
  venueId: string;
} => ({
  type: types.marketing.RETRIEVE_CAMPAIGNS.REQUEST,
  venueId,
});

export const retrieveCampaignsSuccess = (
  data: any
): {
  type: string;
  data: any;
} => ({
  type: types.marketing.RETRIEVE_CAMPAIGNS.SUCCESS,
  data,
});

export const retrieveCampaignsFailure = (
  error: string
): {
  type: string;
  error: string;
} => ({
  type: types.marketing.RETRIEVE_CAMPAIGNS.FAILURE,
  error,
});

export const updateCampaignRequest = (
  venueId: string,
  campaignId: string,
  campaignStatus: CampaignStatus,
  benefits?: string[]
): {
  type: string;
  venueId: string;
  campaignId: string;
  campaignStatus: CampaignStatus;
  benefits?: string[];
} => ({
  type: types.marketing.UPDATE_CAMPAIGN_STATUS.REQUEST,
  venueId,
  campaignId,
  campaignStatus,
  benefits,
});

export const updateCampaignSuccess = (
  newStatus: boolean,
  campaignId: string
): {
  type: string;
  newStatus: boolean;
  campaignId: string;
} => ({
  type: types.marketing.UPDATE_CAMPAIGN_STATUS.SUCCESS,
  newStatus,
  campaignId,
});

export const updateCampaignFailure = (
  error: string
): {
  type: string;
  error: string;
} => ({
  type: types.marketing.UPDATE_CAMPAIGN_STATUS.FAILURE,
  error,
});

interface CreateCampaignRequestAction {
  type: typeof types.marketing.CREATE_CAMPAIGN.REQUEST;
  venueId: string;
  name: string | undefined;
  allDay: boolean;
  timeSlot: any;
  amount: number | undefined;
  modifier: Modifier;
  note: string | undefined;
}

export const createCampaignRequest = (
  venueId: string,
  allDay: boolean,
  modifier: Modifier,
  name?: string | undefined,
  timeSlot?: {
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
  },
  amount?: number,
  note?: string
): CreateCampaignRequestAction => ({
  type: types.marketing.CREATE_CAMPAIGN.REQUEST,
  venueId,
  name,
  allDay,
  timeSlot,
  amount,
  modifier,
  note,
});

interface CreateCampaignSuccessAction {
  type: typeof types.marketing.CREATE_CAMPAIGN.SUCCESS;
  campaign: any;
}

export const createCampaignSuccess = (
  campaign: any
): CreateCampaignSuccessAction => ({
  type: types.marketing.CREATE_CAMPAIGN.SUCCESS,
  campaign,
});

interface CreateCampaignFailureAction {
  type: typeof types.marketing.CREATE_CAMPAIGN.FAILURE;
  error: any;
}

export const createCampaignFailure = (
  error: any
): CreateCampaignFailureAction => ({
  type: types.marketing.CREATE_CAMPAIGN.FAILURE,
  error,
});

interface CreateCampaignResetAction {
  type: typeof types.marketing.CREATE_CAMPAIGN.RESET;
}

export const createCampaignReset = (): CreateCampaignResetAction => ({
  type: types.marketing.CREATE_CAMPAIGN.RESET,
});

export type MarketingActionTypes =
  | CreateCampaignRequestAction
  | CreateCampaignSuccessAction
  | CreateCampaignFailureAction
  | CreateCampaignResetAction;
