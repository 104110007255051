import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { Provider } from "react-redux";
import App from "../containers/App";
import { persistor, store } from "../redux/store";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { TimeProvider } from "react-time-sync";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { IntlProvider, addLocaleData } from "react-intl";
import de from "react-intl/locale-data/de";
import localeData from "../localeData";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import i18n from "../util/i18n";

// setup locale
addLocaleData([...de]);
moment.locale("de");

const theme = createMuiTheme({
  palette: {
    primary: {
      light: grey[400],
      main: grey[800],
      dark: grey[900],
      contrastText: "#fff",
    },
    secondary: {
      light: grey[200],
      main: grey[300],
      dark: grey[500],
      contrastText: "#000",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
      },
    },
  },
  typography: {
    body1: {
      fontSize: "1.250rem",
      "@media (max-width: 560px)": {
        fontSize: "0.925rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "0.875rem",
      },
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.8",
      "@media (max-width: 560px)": {
        fontSize: "0.750rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "0.700rem",
      },
    },
    useNextVariants: true,
    fontFamily: "Open Sans",
  },
});

const AppRoot = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider
            locale="de"
            utils={MomentUtils}
            moment={moment}
          >
            <IntlProvider locale="de" messages={localeData.de}>
              <TimeProvider>
                <App />
              </TimeProvider>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>
);

export default AppRoot;
