// eslint-disable-next-line prefer-destructuring
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const INSIDE_WEBVIEW =
  !!process.env.REACT_APP_INSIDE_WEBVIEW &&
  JSON.parse(process.env.REACT_APP_INSIDE_WEBVIEW);
export const VERSION = process.env.REACT_APP_VERSION;

const useDevEnvironment = process.env.NODE_ENV !== "production";

let AMPLITUDE_KEY = "0301bd476edf941223f4ca30fd8d8b70";
if (useDevEnvironment) {
  AMPLITUDE_KEY = "8005af98012d53e3c8332967b5eb7fe7";
}

export const CHECKIN_URL_PATTERN = useDevEnvironment
  ? /https:\/\/t-dev.orda-app.com\/(\w{6,})/
  : /https:\/\/t.orda-app.com\/(\w{6,})/;
export const AMPLITUDE_API_KEY = AMPLITUDE_KEY;
