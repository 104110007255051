/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  requests: {
    retrieveActivityLog: {
      processing: false,
      error: null,
    },
  },
  activityLog: null,
};

export default function activityLogReducer(state = initialState, action) {
  switch (action.type) {
    case types.activityLog.RETRIEVE_ACTIVITY_LOG.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveActivityLog.processing = true;
        newState.requests.retrieveActivityLog.error = null;
      });
    case types.activityLog.RETRIEVE_ACTIVITY_LOG.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveActivityLog.processing = false;
        newState.activityLog = action.activityLog;
      });
    case types.activityLog.RETRIEVE_ACTIVITY_LOG.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveActivityLog.processing = false;
        newState.requests.retrieveActivityLog.error = action.error;
      });
    default:
      return state;
  }
}
