import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { updateNavBarTitle } from "../../redux/actions/uiState";
import {
  withStyles,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import QRCodeScan from "./sub-components/QRCodeScan";
import { Delete } from "@material-ui/icons";
import {
  deleteVenueTable,
  getVenueTables,
  updateTableNumberReset,
} from "../../redux/actions/tables";
import { THEME } from "../../util/theme";
import Loading from "../Loading";

const styles: any = {
  container: {
    padding: "1rem",
  },
  tableQRControllButton: {
    margin: "10px 0",
    width: "fit-content",
    padding: 15,
    color: THEME.WHITE_TEXT_COLOR,
    fontSize: "1.2em",
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
      opacity: 0.8,
    },
  },
  searchField: {
    fontSize: "1.1em",
    fontWeight: 600,
    marginBottom: 10,
  },
  head: {
    backgroundColor: "#424242",
    color: "white",
    fontSize: "1em",
  },
  errorMessage: {
    fontSize: "1.1em",
    fontWeight: 600,
    color: THEME.ACCENT_RED,
    padding: "0.4rem",
  },
};

interface QRCodesProps {
  appBarTitle: string;
  classes: any;
}

const QRCodes: React.FC<QRCodesProps> = ({
  appBarTitle,
  classes,
}): ReturnType<React.FC<QRCodesProps>> => {
  const dispatch = useDispatch();
  const { t } = useTranslation("qrCodes");

  const currentVenueId = useSelector(
    (state: any) => state.uiState.currentVenueId
  );
  const {
    tables,
    deleteVenueTableRequest: { processing: deleteVenueTableProcessing },
    getVenueTablesRequest: {
      success: getVenueTablesSuccess,
      error: getVenueTablesError,
    },
  } = useSelector((state: any) => state.tables);

  const [searchText, setSearchText] = useState<string>("");
  const [addQRTableModalVisible, setAddQRTableModalVisible] =
    useState<boolean>(false);
  const [tablesList, setTablesList] = useState<any[]>([]);
  const [codeToDelete, setCodeToDelete] = useState<string>("");

  useEffect(() => {
    dispatch(updateNavBarTitle(appBarTitle));
  }, []);
  useEffect(() => {
    if (getVenueTablesSuccess) {
      setTablesList([...tables]);
    }
  }, [tables, getVenueTablesSuccess]);
  useEffect(() => {
    dispatch(getVenueTables(currentVenueId));
  }, []);

  const handleDeleteItem = (id: string): void => {
    setCodeToDelete(id);
    dispatch(deleteVenueTable(currentVenueId, id));
  };
  const filteredList = tablesList.filter(
    (item) =>
      item.tableNumber.includes(searchText) || item.id.includes(searchText)
  );

  return (
    <div className={classes.container}>
      <TextField
        label={t("searchQRTable")}
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className={classes.searchField}
      />
      <Button
        onClick={() => {
          dispatch(updateTableNumberReset());
          setAddQRTableModalVisible(true);
        }}
        className={classes.tableQRControllButton}
      >
        {t("scanQRCode")}
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                head: classes.head,
              }}
            >
              {t("code")}
            </TableCell>
            <TableCell
              classes={{
                head: classes.head,
              }}
            >
              {t("number")}
            </TableCell>
            <TableCell
              classes={{
                head: classes.head,
              }}
            >
              {t("actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!getVenueTablesError && (
            <Typography className={classes.errorMessage}>
              {t("errorGettingQRCodes")}
            </Typography>
          )}
          {filteredList.length > 0 &&
            filteredList.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.tableNumber}</TableCell>
                <TableCell>
                  {deleteVenueTableProcessing && codeToDelete === item.id ? (
                    <Loading customIconStyle={{ margin: 0 }} />
                  ) : (
                    <IconButton
                      key={item.id}
                      onClick={() => handleDeleteItem(item.id)}
                    >
                      <Delete style={{ color: THEME.ACCENT_RED }} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        {addQRTableModalVisible && (
          <QRCodeScan
            visible={addQRTableModalVisible}
            setVisible={setAddQRTableModalVisible}
          />
        )}
      </Table>
    </div>
  );
};

QRCodes.propTypes = {
  appBarTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QRCodes);
