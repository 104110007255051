import React from "react";
import { connectTime, MINUTES } from "react-time-sync";
import PropTypes from "prop-types";
import moment, { Moment } from "moment";
// @ts-ignore
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { DatePicker } from "material-ui-pickers";
import Loading from "../Loading";
import OrderListItem from "../../containers/OrderListItemWrapper";
import { TrafficStatus } from "@orda/shared-constants/venue-types";
import {
  withStyles,
  Grid,
  Fab,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Collapse,
  Typography,
  List,
  IconButton,
  Button,
  Switch,
} from "@material-ui/core";
import { logEvent } from "../../lib/analytics";
import {
  VENUE_CHANGE_TRAFFIC_STATUS,
  VENUE_AUTO_TRAFFIC_DISABLED,
  VENUE_AUTO_TRAFFIC_ENABLED,
} from "../../lib/analytics/events";
import { INSIDE_WEBVIEW } from "../../config";
import { blue, grey } from "@material-ui/core/colors";
import { Order, Venue } from "@orda/shared-types";
import { withTranslation } from "react-i18next";

const FUTURE_ORDER_THRESHOLD = 15 * 60; // 15 minutes
const IS_NEW_THRESHOLD = 90; // 1.5 minutes
const VENUE_TRAFFIC_TIME_THRESHHOLD = 2; // 2 hours

const venueTraffic = [
  {
    status: TrafficStatus.Normal,
    label: "Normal",
    style: {
      // backgroundColor: "rgba(0,255,0, 0.1)",
      border: "1px solid green",
    },
    selectedStyle: {
      backgroundColor: "green",
      height: 60,
    },
    fontStyle: {
      color: "green",
    },
  },
  {
    status: TrafficStatus.Busy,
    label: "Lang",
    caption: "+10 min",
    style: {
      border: "1px solid rgb(255,136,0)",
    },
    selectedStyle: {
      backgroundColor: "rgb(255,136,0)",
      height: 60,
    },
    fontStyle: {
      color: "rgb(255,136,0)",
    },
  },
  {
    status: TrafficStatus.VeryBusy,
    label: "Sehr Lang",
    caption: "+20 min",
    style: {
      border: "1px solid red",
    },
    selectedStyle: {
      backgroundColor: "red",
      height: 60,
    },
    fontStyle: {
      color: "red",
    },
  },
];

const styles: any = (defaultTheme: any): any => ({
  ordersHeaderText: {
    textAlign: "center",
    alignItems: "center",
    fontFamily: "Syncopate",
  },
  trafficButtonText: {
    fontSize: "0.8rem",
  },
  ordersHeader: {
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: 16,
    backgroundColor: "#EEE",
    margin: 0,
    boxShadow: "0 2px 5px -2px rgba(0,0,0,.2)",
    position: "relative",
    zIndex: 100,
  },
  cardNoOrdersContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  datePicker: {
    display: "none",
    height: 0,
    position: "fixed",
    maxHeight: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: defaultTheme.transitions.create("transform", {
      duration: defaultTheme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  orderContainer: {
    padding: "16px 32px 16px 32px",
    backgroundColor: "#DEDEDE",
  },
  collapsableWrapper: {
    backgroundColor: "#DEDEDE",
    zIndex: 999,
  },
  listWrapper: {
    paddingTop: 0,
  },
  venueTrafficButton: {
    height: 60,
    width: "90%",
    marginTop: "0.6rem",
  },
  trafficButtonTextCaption: {
    textAlign: "center",
    fontSize: "0.7rem",
  },
  toggleButtonWrapper: {
    flexDirection: "column",
  },
  venueTrafficInfo: {
    fontSize: "0.9rem",
    textAlign: "center",
    padding: 4,
    paddingBottom: 0,
  },
  switchBase: {
    height: "auto",
  },
  checked: {
    color: blue[50],
    "&$checked": {
      color: blue[500],
      "& + $bar": {
        backgroundColor: blue[500],
      },
    },
  },
  bar: {},
  disabled: {
    color: grey[50],
    "&$checked": {
      color: grey[500],
      "& + $bar": {
        backgroundColor: grey[500],
      },
    },
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiPickersModal: {
      dialogAction: {
        display: "none",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "black",
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "black",
      },
      current: {
        color: "black",
      },
    },
  },
} as any);

interface ExtendedOrder extends Order {
  fullTime: number;
  joinableUntil?: number;
}

function isFutureOrder(
  { fullTime }: { fullTime: number },
  currentTime: number
): boolean {
  return fullTime > currentTime + FUTURE_ORDER_THRESHOLD;
}

function isNewOrder(
  { executedAt }: ExtendedOrder,
  currentTime: number
): boolean {
  return currentTime - (executedAt || 0) < IS_NEW_THRESHOLD;
}

function getFullOrderTime({
  executedAt,
  orderTime,
  joinableUntil,
}: Pick<ExtendedOrder, "executedAt" | "orderTime" | "joinableUntil">): number {
  const orderMoment = moment.unix(executedAt || 0);
  if (orderTime) {
    orderMoment.set({
      hour: parseInt(`${orderTime[0]}${orderTime[1]}`, 10),
      minute: parseInt(`${orderTime[2]}${orderTime[3]}`, 10),
      second: 0,
      millisecond: 0,
    });
  }
  return Math.max(orderMoment.unix(), joinableUntil || 0);
}

function restructureOrder(order: Order): ExtendedOrder {
  return {
    ...order,
    fullTime: getFullOrderTime(order),
  };
}

function getStartTimeSpan(): { start: Moment; end: Moment } {
  const start: Moment = moment();
  start.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const end = moment(start);
  end.add(1, "d");
  return {
    start,
    end,
  };
}

function isWithinCurrentTimeSpan(
  { fullTime }: ExtendedOrder,
  { start, end }: { start: Moment; end: Moment }
): boolean {
  const orderMoment = moment.unix(fullTime);
  return orderMoment.isBetween(start, end);
}

interface Props extends React.Props<OrdersNew> {
  t: any;
  currentTime: number;
  orders: ExtendedOrder[];
  retrieveOrders: (
    currentVenueId: string,
    start: number,
    end: number,
    isHobbyCook: boolean
  ) => void;
  retrieveVenue: (venueId: string) => void;
  updateVenueTraffic: (venueId: string, status: number) => void;
  disableAutomaticVenueTraffic: (venueId: string) => void;
  enableAutomaticVenueTraffic: (venueId: string) => void;
  retrieveOrdersProcessing: boolean;
  appBarTitle: string;
  updateNavBarTitle: (title: string) => void;
  currentVenueId: string;
  classes: any;
  retrieveVenueProcessing: boolean;
  automaticVenueTraffic: boolean;
  venue: Venue;
  isHobbyCook: boolean;
}

interface State {
  timeSpan: { start: Moment; end: Moment };
  showFuture: boolean;
  openOrdersExpanded: boolean;
  doneOrdersExpanded: boolean;
  venueTrafficStatus: number;
}

class OrdersNew extends React.Component<Props, State> {
  private static propTypes = {
    t: PropTypes.func.isRequired,
    currentTime: PropTypes.number.isRequired,
    orders: PropTypes.array,
    retrieveOrders: PropTypes.func.isRequired,
    retrieveVenue: PropTypes.func.isRequired,
    updateVenueTraffic: PropTypes.func.isRequired,
    disableAutomaticVenueTraffic: PropTypes.func.isRequired,
    enableAutomaticVenueTraffic: PropTypes.func.isRequired,
    retrieveOrdersProcessing: PropTypes.bool.isRequired,
    appBarTitle: PropTypes.string.isRequired,
    updateNavBarTitle: PropTypes.func.isRequired,
    currentVenueId: PropTypes.string,
    classes: PropTypes.object.isRequired,
    retrieveVenueProcessing: PropTypes.bool.isRequired,
    venue: PropTypes.object,
    automaticVenueTraffic: PropTypes.bool.isRequired,
    isHobbyCook: PropTypes.bool,
  };

  private static defaultProps = {
    orders: null,
    currentVenueId: null,
    venue: null,
    isHobbyCook: false,
  };

  public constructor(props: Props) {
    super(props);

    const timeSpan = getStartTimeSpan();

    const today = this.isToday(timeSpan.start, props.currentTime);

    this.state = {
      timeSpan,
      showFuture: true,
      openOrdersExpanded: today,
      doneOrdersExpanded: !today,
      venueTrafficStatus: TrafficStatus.Normal,
    };
  }

  public componentDidMount(): void {
    const {
      t,
      retrieveOrders,
      currentVenueId,
      appBarTitle,
      updateNavBarTitle,
      currentTime,
      retrieveVenue,
      isHobbyCook,
    } = this.props;
    const { timeSpan } = this.state;
    retrieveVenue(currentVenueId);
    retrieveOrders(
      currentVenueId,
      timeSpan.start.unix(),
      timeSpan.end.unix(),
      isHobbyCook
    );
    updateNavBarTitle(
      `${t(appBarTitle)} ${this.formatDate(timeSpan, currentTime)}`
    );
  }

  public async componentWillReceiveProps(nextProps: Props): Promise<void> {
    const { currentTime } = this.props;
    const { venueTrafficStatus } = this.state;
    if (
      nextProps.venue &&
      nextProps.venue.traffic &&
      nextProps.venue.traffic.status !== venueTrafficStatus &&
      moment
        .unix(currentTime)
        .diff(moment.unix(nextProps.venue.traffic.lastUpdate), "hours") <=
        VENUE_TRAFFIC_TIME_THRESHHOLD
    ) {
      this.setState({ venueTrafficStatus: nextProps.venue.traffic.status });
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const {
      timeSpan: { start, end },
    } = this.state;
    const { retrieveOrders, currentVenueId, currentTime, isHobbyCook } =
      this.props;

    // Check whether a new day has begun and "yesterday"
    // is still displayed. In this case, the day will switch
    // automatically
    if (
      prevProps.currentTime !== currentTime &&
      !moment
        .unix(prevProps.currentTime)
        .isSame(moment.unix(currentTime), "day") &&
      moment.unix(prevProps.currentTime).isSame(start, "day")
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          timeSpan: getStartTimeSpan(),
          venueTrafficStatus: TrafficStatus.Normal,
        },
        () => {
          retrieveOrders(currentVenueId, start.unix(), end.unix(), isHobbyCook);
        }
      );
    }
  }

  // eslint-disable-next-line
  private datePicker!: any;

  private handleAutomaticTrafficMode = (): void => {
    const {
      automaticVenueTraffic,
      currentVenueId,
      disableAutomaticVenueTraffic,
      enableAutomaticVenueTraffic,
    } = this.props;
    if (automaticVenueTraffic) {
      logEvent(VENUE_AUTO_TRAFFIC_DISABLED, {
        type: "auto-toggle-button",
        source: INSIDE_WEBVIEW ? "pos" : "web",
      });
      disableAutomaticVenueTraffic(currentVenueId);
    } else {
      logEvent(VENUE_AUTO_TRAFFIC_ENABLED, {
        type: "auto-toggle-button",
        source: INSIDE_WEBVIEW ? "pos" : "web",
      });
      enableAutomaticVenueTraffic(currentVenueId);
    }
  };

  private handleDateChange = (date: number): void => {
    const {
      t,
      retrieveOrders,
      currentVenueId,
      currentTime,
      appBarTitle,
      updateNavBarTitle,
      isHobbyCook,
    } = this.props;
    const timeSpan = {
      start: moment(date),
      end: moment(date).add(1, "d"),
    };

    this.setState(
      {
        timeSpan,
      },
      () => {
        const today = this.isToday(moment(date), currentTime);
        this.setState({
          openOrdersExpanded: today,
          doneOrdersExpanded: !today,
        });
        retrieveOrders(
          currentVenueId,
          moment(date).unix(),
          moment(date).add(1, "d").unix(),
          isHobbyCook
        );
        updateNavBarTitle(
          `${t(appBarTitle)} ${this.formatDate(timeSpan, currentTime)}`
        );
      }
    );
  };

  private handleOpenOrdersExpand = (): void => {
    this.setState((state) => ({
      openOrdersExpanded: !state.openOrdersExpanded,
    }));
  };

  private handleDoneOrdersExpand = (): void => {
    this.setState((state) => ({
      doneOrdersExpanded: !state.doneOrdersExpanded,
    }));
  };

  private openDatePicker = (): void => {
    if (this.datePicker) {
      this.datePicker.open();
    } else {
      // eslint-disable-next-line no-console
      console.error("DatePicker is not initialized");
    }
  };

  private formatDate = (
    { start }: { start: Moment },
    currentTime: number
  ): string => {
    const now = moment.unix(currentTime);
    if (now.isSame(start, "day")) {
      return "Heute";
    }
    return start.format("L");
  };

  private handleVenueTrafficUpdate = (venueTrafficStatus: number): void => {
    const { currentVenueId, updateVenueTraffic, disableAutomaticVenueTraffic } =
      this.props;
    this.setState({ venueTrafficStatus });
    disableAutomaticVenueTraffic(currentVenueId);
    updateVenueTraffic(currentVenueId, venueTrafficStatus);
    logEvent(VENUE_AUTO_TRAFFIC_DISABLED, {
      type: "venue-traffic-status-button",
      status: venueTrafficStatus,
      source: INSIDE_WEBVIEW ? "pos" : "web",
    });
    logEvent(VENUE_CHANGE_TRAFFIC_STATUS, {
      venueTrafficStatus,
      source: INSIDE_WEBVIEW ? "pos" : "web",
    });
  };

  private isToday = (start: Moment, currentTime: number): boolean => {
    const now = moment.unix(currentTime);
    return start.isSame(now, "day");
  };

  public render(): JSX.Element {
    const {
      showFuture,
      timeSpan,
      openOrdersExpanded,
      doneOrdersExpanded,
      venueTrafficStatus,
    } = this.state;

    const {
      currentTime,
      orders,
      classes,
      retrieveOrdersProcessing,
      retrieveVenueProcessing,
      venue,
      automaticVenueTraffic,
      isHobbyCook,
    } = this.props;

    const today = this.isToday(timeSpan.start, currentTime);

    const openOrders =
      !retrieveOrdersProcessing &&
      orders &&
      !!orders.length &&
      orders
        .map(restructureOrder)
        .filter((order: ExtendedOrder) =>
          isWithinCurrentTimeSpan(order, timeSpan)
        )
        .filter(
          (order: ExtendedOrder) =>
            !order.doneAt && this.isToday(moment.unix(order.time), currentTime)
        )
        .filter(
          (order: ExtendedOrder) =>
            showFuture || !isFutureOrder(order, currentTime)
        )
        .sort((a: ExtendedOrder, b: ExtendedOrder) => a.fullTime - b.fullTime);

    const doneOrders =
      !retrieveOrdersProcessing &&
      !!orders &&
      !!orders.length &&
      orders
        .map(restructureOrder)
        .filter((order: ExtendedOrder) =>
          isWithinCurrentTimeSpan(order, timeSpan)
        )
        .filter(
          (order: ExtendedOrder) =>
            order.doneAt || !this.isToday(moment.unix(order.time), currentTime)
        )
        .filter(
          (order: ExtendedOrder) =>
            showFuture || !isFutureOrder(order, currentTime)
        )
        .sort((a: ExtendedOrder, b: ExtendedOrder) =>
          today ? (b.doneAt || 0) - (a.doneAt || 0) : b.time - a.time
        );

    return retrieveOrdersProcessing || retrieveVenueProcessing ? (
      <Loading />
    ) : (
      <>
        <Grid container justify="space-between" style={{ padding: "0.1rem" }}>
          {!isHobbyCook && (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.venueTrafficInfo}>
                Wie lange gerade die Wartezeit ist?
              </Typography>
            </Grid>
          )}
          {!isHobbyCook &&
            venueTraffic.map(
              ({ status, selectedStyle, style, label, fontStyle, caption }) => {
                const selected =
                  venue &&
                  venue.traffic &&
                  moment
                    .unix(currentTime)
                    .diff(moment.unix(venue.traffic.lastUpdate), "hours") <= 2
                    ? status === venueTrafficStatus
                    : status === TrafficStatus.Normal;
                let selectedFontStyle;
                if (selected) {
                  selectedFontStyle = {
                    color: "white",
                  };
                }
                return (
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      value={status}
                      key={status}
                      variant={selected ? "contained" : "outlined"}
                      className={classes.venueTrafficButton}
                      color="secondary"
                      classes={{ label: classes.toggleButtonWrapper }}
                      style={selected ? selectedStyle : style}
                      onClick={() => this.handleVenueTrafficUpdate(status)}
                    >
                      <Typography
                        variant="body1"
                        className={classes.trafficButtonText}
                        style={selected ? selectedFontStyle : fontStyle}
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.trafficButtonTextCaption}
                        style={selected ? selectedFontStyle : {}}
                      >
                        {caption}
                      </Typography>
                    </Button>
                  </Grid>
                );
              }
            )}
          {!isHobbyCook && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  paddingTop: 8,
                  paddingRight: 32,
                  paddingLeft: 32,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={
                    automaticVenueTraffic
                      ? { fontWeight: "bold", color: "blue" }
                      : {}
                  }
                >
                  Auto
                  <Switch
                    checked={automaticVenueTraffic}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      bar: classes.bar,
                      disabled: classes.disabled,
                    }}
                    onChange={this.handleAutomaticTrafficMode}
                  />
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="body1" style={{ marginRight: "1rem" }}>
                    Datum auswählen:
                    <Fab
                      onClick={this.openDatePicker}
                      size="small"
                      style={{ paddingBottom: 8, marginLeft: 12 }}
                    >
                      <IconButton
                        onClick={this.openDatePicker}
                        disableRipple
                        disableTouchRipple
                      >
                        <CalendarTodayIcon />
                      </IconButton>
                    </Fab>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "1rem" }}></Grid>
            </>
          )}
        </Grid>
        <MuiThemeProvider theme={theme}>
          <DatePicker
            onChange={this.handleDateChange}
            disableFuture
            autoOk
            className={classes.datePicker}
            value={timeSpan.start.toDate()}
            ref={(datePicker) => {
              this.datePicker = datePicker;
            }}
          />
        </MuiThemeProvider>

        {!isHobbyCook && (
          <div>
            {!!orders && !!orders.length && today && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                onClick={this.handleOpenOrdersExpand}
                spacing={16}
                className={classes.ordersHeader}
                justify="center"
                alignItems="center"
              >
                <Typography variant="h5" className={classes.ordersHeaderText}>
                  OFFENE BESTELLUNGEN
                  {!!openOrders &&
                    !!openOrders.length &&
                    `(${openOrders.length})`}
                </Typography>
                <Typography>
                  {!!openOrders && !!openOrders.length && (
                    <ExpandMoreIcon
                      fontSize="large"
                      className={classnames(classes.expand, {
                        [classes.expandOpen]: openOrdersExpanded,
                      })}
                    />
                  )}
                </Typography>
              </Grid>
            )}
            <List className={classes.listWrapper}>
              <Collapse
                in={openOrdersExpanded}
                className={classes.collapsableWrapper}
              >
                {openOrders &&
                  !!openOrders.length &&
                  openOrders.map((order: ExtendedOrder) => (
                    <Grid className={classes.orderContainer}>
                      <OrderListItem
                        key={order.orderId}
                        order={order}
                        future={isFutureOrder(order, currentTime)}
                        // @ts-ignore
                        isNew={isNewOrder(order, currentTime)}
                        markAsDoneEnabled={today}
                        isHobbyCook={isHobbyCook}
                      />
                    </Grid>
                  ))}
              </Collapse>
            </List>
            {!!orders && !!orders.length && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                onClick={this.handleDoneOrdersExpand}
                spacing={16}
                className={classes.ordersHeader}
                justify="center"
                alignItems="center"
              >
                <Typography variant="h5" className={classes.ordersHeaderText}>
                  FERTIGE BESTELLUNGEN{" "}
                  {!!doneOrders &&
                    !!doneOrders.length &&
                    `(${doneOrders.length})`}
                </Typography>
                <Typography>
                  {!!doneOrders && !!doneOrders.length && (
                    <ExpandMoreIcon
                      fontSize="large"
                      className={classnames(classes.expand, {
                        [classes.expandOpen]: doneOrdersExpanded,
                      })}
                    />
                  )}
                </Typography>
              </Grid>
            )}
            <List className={classes.listWrapper}>
              <Collapse
                in={doneOrdersExpanded}
                className={classes.collapsableWrapper}
              >
                {!!doneOrders &&
                  !!doneOrders.length &&
                  doneOrders.map((order: ExtendedOrder) => (
                    <Grid className={classes.orderContainer}>
                      <OrderListItem
                        key={order.orderId}
                        order={order}
                        future={isFutureOrder(order, currentTime)}
                        markAsDoneEnabled={today}
                      />
                    </Grid>
                  ))}
              </Collapse>
            </List>
          </div>
        )}
        {isHobbyCook &&
          orders.map((order: ExtendedOrder) => (
            <Grid className={classes.orderContainer}>
              <OrderListItem
                key={order.orderId}
                order={order}
                future={isFutureOrder(order, currentTime)}
                markAsDoneEnabled={today}
              />
            </Grid>
          ))}

        {!(orders && orders.length) && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ padding: "1rem" }}
          >
            <Grid item xs={12}>
              <Card className={classes.cardNoOrdersContainer}>
                <CardContent className={classes.cardNoOrders}>
                  <Typography className={classes.ordersHeaderText} variant="h5">
                    KEINE BESTELLUNGEN
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default withTranslation("routeLabels")(
  withStyles(styles)(connectTime({ interval: MINUTES })(OrdersNew)) as any
);
