import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  withStyles,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import { updateNavBarTitle } from "../redux/actions/uiState";
import { updatePrinterConfig } from "../redux/actions/status";
import PropTypes from "prop-types";
import Printer from "@material-ui/icons/Print";
import { send } from "../redux/actions/bridge";
import { ToNativeEvent } from "@orda/shared-constants/backoffice/tablet-bridge-events";

const styles: any = {
  container: {
    padding: "1rem",
  },
  header: {
    fontSize: 24,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  formControl: {
    minWidth: 190,
  },
  printerHeader: {
    fontSize: 20,
    fontFamily: "Open Sans",
  },
  printerContainer: {
    margin: 10,
  },
  testPrintButton: {
    fontFamily: "Open Sans",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    margin: "50px 60px",
  },
  spinner: {
    color: "#fff",
  },
  avatar: {
    backgroundColor: "#fff",
    border: "none",
  },
  onlinePrinter: {
    color: "#4CAF50",
  },
  offlinePrinter: {
    color: "#F44336",
  },
  noPrinter: {
    color: "#4a4a4a",
  },
  singlePrinterHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "auto",
  },
};

interface Printer {
  id: string;
  name?: string;
  address: string;
  status?: boolean;
}

interface SettingsProps {
  appBarTitle: string;
  classes: any;
}

const isPrinterOnline = (id: string, printerStatus: Printer[]): boolean => {
  return printerStatus.some(
    (printer: Printer) => printer.id === id && printer.status
  );
};

const Settings: React.FC<SettingsProps> = ({
  appBarTitle,
  classes,
}): ReturnType<React.FC<SettingsProps>> => {
  const dispatch = useDispatch();
  const { t } = useTranslation("settings");
  const {
    printers,
    availablePrinters,
    updatingPrinters,
    printerStatus,
    hasBluetoothAddress,
  } = useSelector((state: any) => state.status);
  const [printerUpdates, setPrinterUpdate] = useState<Printer[]>(printers);

  useEffect(() => {
    dispatch(updateNavBarTitle(appBarTitle));
  });

  const handleChange =
    (index: number): any =>
    (event: React.ChangeEvent<HTMLInputElement>): any => {
      const address = event.target.value;
      setPrinterUpdate(
        printerUpdates.map((printer, idx) => {
          if (idx === index) {
            return { ...printer, address };
          }
          return printer;
        })
      );
    };

  const handleSubmit = (): void => {
    dispatch(updatePrinterConfig(printerUpdates, printers));
  };

  return (
    <Grid container className={classes.container} spacing={16}>
      {printerUpdates && (
        <Grid container>
          <Typography className={classes.header}>{t("printer")}</Typography>
          {availablePrinters.length === 0 && (
            <Grid container item xs={12}>
              <Typography>{t("noPhysicalPrinter")}</Typography>
            </Grid>
          )}
          {printerUpdates.map((printer: Printer, index: number) => (
            <Grid
              container
              className={classes.printerContainer}
              item
              xs={12}
              key={printer.id}
            >
              <Grid item xs={12}>
                <Typography className={classes.singlePrinterHeader}>
                  {printer.name || printer.id}{" "}
                  {printer.address &&
                    printer.address !== "" &&
                    printer.address !== "none" &&
                    isPrinterOnline(printer.id, printerStatus) && (
                      <Avatar className={classes.avatar}>
                        <Printer className={classes.onlinePrinter} />{" "}
                      </Avatar>
                    )}
                  {printer.address &&
                    printer.address !== "" &&
                    !isPrinterOnline(printer.id, printerStatus) && (
                      <Avatar className={classes.avatar}>
                        <Printer className={classes.offlinePrinter} />{" "}
                      </Avatar>
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {availablePrinters && (
                  <div>
                    <Select
                      onChange={handleChange(index)}
                      inputProps={{
                        name: "address",
                        id: "outlined-bluetooth",
                      }}
                      value={printer.address === "" ? "none" : printer.address}
                    >
                      {(availablePrinters.length === 0 ||
                        hasBluetoothAddress) && (
                        <MenuItem value={printer.address}>
                          {printer.address}
                        </MenuItem>
                      )}
                      {availablePrinters.length > 0 && !hasBluetoothAddress && (
                        <MenuItem value="none">
                          {t("noPrinterSelected")}
                        </MenuItem>
                      )}
                      {availablePrinters.length > 0 &&
                        !hasBluetoothAddress &&
                        availablePrinters.map((printerAddress: string) => (
                          <MenuItem value={printerAddress}>
                            {printerAddress}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() =>
                    dispatch(send(ToNativeEvent.PrintTest, { printer }))
                  }
                >
                  {t("testPrint")}
                </Button>
              </Grid>
            </Grid>
          ))}
          {!hasBluetoothAddress && (
            <Grid container item className={classes.buttonContainer}>
              <Button
                disabled={updatingPrinters}
                variant="contained"
                className={classes.button}
                size="large"
                color="primary"
                onClick={handleSubmit}
              >
                {updatingPrinters ? (
                  <CircularProgress className={classes.spinner} />
                ) : (
                  t("saveButton")
                )}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

Settings.propTypes = {
  appBarTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
