/* eslint no-param-reassign: 0 */
import * as types from "../types";

export const initialState = {
  ready: false,
};

export default function bridgeReducer(state = initialState, action) {
  switch (action.type) {
    case types.bridge.READY:
      return {
        ready: true,
      };
    default:
      return state;
  }
}
