import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Paper, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const styles: any = {
  buttonWrapper: {
    margin: "0 15px 30px",
    width: "calc(50% - 30px)",
    float: "left",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    padding: 20,
  },
  icon: {
    margin: "0 auto",
    textAlign: "center",
  },
  label: {
    textAlign: "center",
  },
};

interface HomeScreenButtonProps {
  url: string;
  icon: any;
  label?: string;
  classes: any;
}

const HomeScreenButton: React.FC<HomeScreenButtonProps> = ({
  url,
  icon,
  label,
  classes,
}): ReturnType<React.FC<HomeScreenButtonProps>> => {
  const { t } = useTranslation("routeLabels");

  return label ? (
    <div className={classes.buttonWrapper}>
      <Link to={url} href={url}>
        <Paper className={classes.paper}>
          <div className={classes.contentWrapper}>
            <div className={classes.icon}>
              {React.cloneElement(icon, {
                style: { height: 60, width: 60 },
              })}
            </div>
            <div className={classes.label}>{t(label)}</div>
          </div>
        </Paper>
      </Link>
    </div>
  ) : null;
};

HomeScreenButton.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
};

HomeScreenButton.defaultProps = {
  label: undefined,
};

export default withStyles(styles)(HomeScreenButton);
