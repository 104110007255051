import "./styles/index.css";
import "./styles/spinner.css";
import "moment/locale/de";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { initJobs } from "./util/backgroundJobs";
import { unregister } from "./serviceWorker";
import AppRoot from "./components/AppRoot";
import { initSentry } from "./lib/sentry";

ReactDOM.render(<AppRoot />, document.getElementById("root"));

unregister();

initJobs();
initSentry();
