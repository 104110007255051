import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

interface NavMenuItemProps {
  icon: any;
  closeHandler: any;
  label: string;
}

const NavMenuItem: React.FC<NavMenuItemProps> = ({
  icon,
  closeHandler,
  label,
}): ReturnType<React.FC<NavMenuItemProps>> => {
  const { t } = useTranslation("routeLabels");

  return (
    <ListItem button onClick={closeHandler}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{t(label)}</ListItemText>
    </ListItem>
  );
};

NavMenuItem.propTypes = {
  icon: PropTypes.element.isRequired,
  closeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default NavMenuItem;
