import PropTypes from "prop-types";
import React, { useState, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { VENUE_MODE_TABLE_SERVICE_QR } from "@orda/shared-constants-js/venue-types";
import { OrderLocation } from "@orda/shared-constants";
import { Timed } from "react-time-sync";
import { formattedTime } from "../../../lib/time-formatting";
import { calculateSumForVenue } from "@orda/shared-functions-js/lib/orders";
import OrderItemExpandedContent from "./OrderItemExpandedContent";
import {
  orderListItemMuiStyles,
  generateCardStyleByColors,
  getStyleColorsByOrderTime,
} from "./muiStyles";

const PUSH_NOTIFICATION_THROTTLE = 20 * 1000;

const formatOrderLocation = (orderLocation) => {
  switch (orderLocation) {
    case OrderLocation.EatIn:
      return "HIER-ESSEN";
    case OrderLocation.ToGo:
      return "TO-GO";
    case OrderLocation.Delivery:
      return "Delivery";
    default:
      return "TO-GO";
  }
};

const OrderListItem = ({
  order,
  currentVenueId,
  pushDisabled,
  displayTime,
  classes,
  getOrderTimeCounter,
  timed,
  currentMinute,
  markOrderAsDone,
  t,
}) => {
  const [expanded, expandCard] = useState(false);

  const handleOrderFinish = useCallback(() => {
    markOrderAsDone(
      currentVenueId,
      order.orderId,
      order.userId,
      order.usedMode
    );
  }, [
    markOrderAsDone,
    currentVenueId,
    order.orderId,
    order.userId,
    order.usedMode,
  ]);

  const { pushBlockUntil, numberOfItems, tableInfo, time } = useMemo(() => {
    let tableInfoValue = null;
    if (order.usedMode === VENUE_MODE_TABLE_SERVICE_QR) {
      if (order.table) {
        tableInfoValue = order.table;
      } else if (order.tableId) {
        tableInfoValue = `${order.tableId} (nicht codiert)`;
      } else {
        tableInfoValue = `noch nicht eingecheckt`;
      }
    }
    return {
      time: order.orderTime
        ? moment()
            .hours(parseInt(order.orderTime.slice(0, 2), 10))
            .minutes(parseInt(order.orderTime.slice(2), 10))
        : moment.unix(order.executedAt),
      tableInfo: tableInfoValue,
      numberOfItems: order.items.filter((item) => item.padding === 0).length,
      pushBlockUntil:
        order.lastPushNotification &&
        order.lastPushNotification + PUSH_NOTIFICATION_THROTTLE,
    };
  }, [
    order.lastPrinted,
    order.lastPushNotification,
    order.orderTime,
    order.executedAt,
    order.items,
    order.usedMode,
    order.table,
    order.tableId,
  ]);

  const { outlineColor, backgroundColor } = useMemo(
    () =>
      getStyleColorsByOrderTime(
        order.doneAt,
        timed
          ? getOrderTimeCounter(currentMinute).orderTimerCounter
          : displayTime,
        order.hobbyCook
      ),
    [order.doneAt, timed, currentMinute, displayTime, order.hobbyCook]
  );

  const { venueSum } = useMemo(
    () =>
      calculateSumForVenue({
        items: order.items,
        orderLocation: order.orderLocation,
        orderBenefits: order.orderBenefits,
        baseVat: order.vat,
        sumBeforeEverything: order.sumBeforeEverything,
        sumBenefit: order.sumBenefit,
        sumBenefits: order.sumBenefits,
        sumBenefitSum: order.sumBenefitSum,
        tipSum: order.tipSum,
      }),
    [
      order.item,
      order.orderLocation,
      order.orderBenefits,
      order.vat,
      order.sumBeforeEverything,
      order.sumBenefit,
      order.sumBenefits,
      order.sumBenefitSum,
      order.tipSum,
    ]
  );

  const orderPickupTime = useMemo(() => {
    if (!order.hobbyCook) {
      return order.orderTime ? time.format("HH:mm") : "ASAP";
    }
    if (!order.orderTime && order.pickupFromTime && order.pickupToTime) {
      return `${order.pickupFromTime} - ${order.pickupToTime}`;
    }
    // because time.add mutates the current time, so we make a clone of it first
    const timeClone = moment(time);
    return `${timeClone.format("HH:mm")} - ${timeClone
      .add(15, "minutes")
      .format("HH:mm")}`;
  }, [time]);

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => expandCard(!expanded)}
        disableRipple
        disableTouchRipple
        className={classes.clickableCard}
        style={generateCardStyleByColors(outlineColor, backgroundColor)}
      >
        <Grid
          container
          xs={12}
          sm={12}
          spacing={16}
          className={classes.content}
        >
          <Grid item container xs={6}>
            <CardContent className={classes.cardContentDetails}>
              {order.hobbyCook ? (
                <>
                  {order.orderForDate && (
                    <Typography noWrap variant="body2">
                      <b>Bestellt für: </b>
                      {moment.unix(order.orderForDate).format("DD.MM.YYYY")}
                    </Typography>
                  )}
                  {order.hobbyCook &&
                    order.pickupFromTime &&
                    order.pickupToTime && (
                      <Typography noWrap variant="body2">
                        <b>Abholung: </b>
                        {orderPickupTime}
                      </Typography>
                    )}
                </>
              ) : (
                <>
                  <Typography variant="body2" noWrap>
                    {formatOrderLocation(order.orderLocation)}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={
                      displayTime < 0 && !order.doneAt
                        ? {
                            color: "#B9D47F",
                          }
                        : {}
                    }
                  >
                    {order.orderTime ? time.format("HH:mm") : "ASAP"}
                  </Typography>
                  {timed ? (
                    <Timed>
                      {({ currentTime }) => (
                        <Typography
                          variant="body2"
                          className={classes.timerCounter}
                          style={{ color: outlineColor }}
                        >
                          {formattedTime(
                            getOrderTimeCounter(currentTime).orderTimerCounter
                          )}
                        </Typography>
                      )}
                    </Timed>
                  ) : (
                    <Typography
                      variant="body2"
                      className={classes.timerCounter}
                      style={{ color: outlineColor }}
                    >
                      {formattedTime(displayTime)}
                    </Typography>
                  )}
                </>
              )}
              <Typography noWrap variant="body2">
                <b>Gast: </b>
                {order.userName}
              </Typography>
              {order.orderLocation === OrderLocation.Delivery && (
                <Typography variant="body2">
                  <b>Adresse: </b> {order.deliveryAddress}
                </Typography>
              )}
              {!!order.phoneNumber && (
                <Typography variant="body2">
                  <b>Telefonnummer: </b> {order.phoneNumber}
                </Typography>
              )}
              <Typography noWrap variant="body2">
                <b>{t("items", { count: numberOfItems })}: </b>
                {numberOfItems}
              </Typography>
              {!order.hobbyCook &&
                tableInfo &&
                order.orderLocation === OrderLocation.EatIn && (
                  <Typography variant="body2">{`Tisch: ${tableInfo}`}</Typography>
                )}
            </CardContent>
          </Grid>
          <Grid item justify="flex-end" alignItems="center" container xs={6}>
            <CardContent className={classes.cardContentDetails}>
              <Typography className={classes.orderNumber} variant="h2">
                {order.orderNumber}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
        <OrderItemExpandedContent
          doneAt={order.doneAt}
          time={order.time}
          items={order.items}
          feeSum={order.feeSum}
          tipSum={order.tipSum}
          hobbyCook={order.hobbyCook}
          notes={order.notes}
          expanded={expanded}
          orderLocation={order.orderLocation}
          expandCard={expandCard}
          venueSum={venueSum}
          pushBlockUntil={pushBlockUntil}
          outlineColor={outlineColor}
          pushDisabled={pushDisabled}
          handleOrderFinish={handleOrderFinish}
          numberOfItems={numberOfItems}
        />
        <Grid
          container
          item
          xs={12}
          sm={12}
          spacing={16}
          className={classes.expandButton}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={2} className={classes.expandableItem}>
            <Typography className={classes.expandIcon}>
              <ExpandMoreIcon
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

OrderListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  pushDisabled: PropTypes.bool.isRequired,
  displayTime: PropTypes.number.isRequired,
  currentMinute: PropTypes.number.isRequired,
  markOrderAsDone: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string,
  getOrderTimeCounter: PropTypes.func.isRequired,
  timed: PropTypes.bool,
  t: PropTypes.object.isRequired,
};

OrderListItem.defaultProps = {
  currentVenueId: null,
  timed: false,
};

export default withStyles(orderListItemMuiStyles, { withTheme: true })(
  withTranslation("order")(OrderListItem)
);
