/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

interface MarketingState {
  requests: {
    retrieveCampaigns: {
      processing: boolean;
      error: any;
    };
    updateCampaign: {
      processing: boolean;
      error: any;
    };
    createCampaign: {
      processing: boolean;
      error: any;
      success: boolean;
    };
  };
  data: any[];
}

export const initialState: MarketingState = {
  requests: {
    retrieveCampaigns: {
      processing: false,
      error: null,
    },
    updateCampaign: {
      processing: false,
      error: null,
    },
    createCampaign: {
      processing: false,
      error: null,
      success: false,
    },
  },
  data: [],
};

export default function marketingReducer(
  state = initialState,
  action: any
): any {
  switch (action.type) {
    case types.marketing.RETRIEVE_CAMPAIGNS.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveCampaigns.processing = true;
        newState.requests.retrieveCampaigns.error = null;
      });
    case types.marketing.RETRIEVE_CAMPAIGNS.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveCampaigns.processing = false;
        newState.data = action.data;
      });
    case types.marketing.RETRIEVE_CAMPAIGNS.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveCampaigns.processing = false;
        newState.requests.retrieveCampaigns.error = action.error;
      });
    case types.marketing.UPDATE_CAMPAIGN_STATUS.REQUEST:
      return produce(state, (newState) => {
        newState.requests.updateCampaign.processing = true;
        newState.requests.updateCampaign.error = null;
      });
    case types.marketing.UPDATE_CAMPAIGN_STATUS.SUCCESS:
      return produce(state, (newState) => {
        const updatedCampaignIdx = (newState.data as any).findIndex(
          ({ id }: { id: string }) => id === action.campaignId
        );
        (newState.data as any)[updatedCampaignIdx].status = action.newStatus;
      });
    case types.marketing.UPDATE_CAMPAIGN_STATUS.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateCampaign.processing = false;
        newState.requests.updateCampaign.error = action.error;
      });
    case types.marketing.CREATE_CAMPAIGN.RESET:
      return produce(state, (newState) => {
        newState.requests.createCampaign.processing = false;
        newState.requests.createCampaign.success = false;
        newState.requests.retrieveCampaigns.error = null;
      });
    case types.marketing.CREATE_CAMPAIGN.REQUEST:
      return produce(state, (newState) => {
        newState.requests.createCampaign.processing = true;
        newState.requests.retrieveCampaigns.error = null;
      });
    case types.marketing.CREATE_CAMPAIGN.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.createCampaign.processing = false;
        newState.requests.createCampaign.success = true;
        // currently not pushing campaign into data array since it will be re-fetched
        // newState.data.push(action.campaign);
      });
    case types.marketing.CREATE_CAMPAIGN.FAILURE:
      return produce(state, (newState) => {
        newState.requests.createCampaign.processing = false;
        newState.requests.createCampaign.error = action.error;
      });
    default:
      return state;
  }
}
