import { connect } from "react-redux";
import Sales from "../components/Sales";
import { retrieveSales } from "../redux/actions/sales";
import { updateNavBarTitle } from "../redux/actions/uiState";

const mapStateToProps = (state) => {
  const {
    sales: {
      requests: {
        retrieveSales: { processing },
      },
      data,
    },
    uiState: { currentVenueId },
  } = state;

  return {
    retrieveSalesProcessing: processing,
    data,
    currentVenueId,
  };
};

const mapDispatchToProps = (dispatch, { appBarTitle }) => ({
  retrieveSales: (venueId, start, end) =>
    dispatch(retrieveSales(venueId, start, end)),
  updateNavBarTitle: () => dispatch(updateNavBarTitle(appBarTitle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
