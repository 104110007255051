import * as sentry from "@sentry/browser";
import { INSIDE_WEBVIEW } from "../config";

const DEFAULT_RELEASE_TAG = "unknown-release";

export function initSentry(): void {
  const stage =
    process.env.NODE_ENV === "production" ? "production" : "development";
  const env = INSIDE_WEBVIEW ? "pos" : "web";
  const environment = `${env}-${stage}`;

  sentry.init({
    dsn: "https://f3d21a3d1ea0492fa018d26c8f1ed459@sentry.io/1895075",
    release: process.env.REACT_APP_SENTRY_RELEASE || DEFAULT_RELEASE_TAG,
    environment,
  });
}
