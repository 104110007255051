import * as types from "../types";

export const setTableNumber = (tableNumber) => ({
  type: types.tables.SET_TABLE_NUMBER,
  tableNumber,
});

export const updateTableNumber = (venueId, qrPayload, tableNumber = "") => ({
  type: types.tables.UPDATE_TABLE_NUMBER.REQUEST,
  venueId,
  qrPayload,
  tableNumber,
});
export const updateTableNumberSuccess = (venueId) => ({
  type: types.tables.UPDATE_TABLE_NUMBER.SUCCESS,
  venueId,
});
export const updateTableNumberFailure = (venueId, error) => ({
  type: types.tables.UPDATE_TABLE_NUMBER.FAILURE,
  venueId,
  error,
});

export const updateTableNumberReset = (venueId, error) => ({
  type: types.tables.UPDATE_TABLE_NUMBER.RESET,
  venueId,
  error,
});

export const getVenueTables = (venueId) => ({
  type: types.tables.GET_VENUE_TABLES.REQUEST,
  venueId,
});
export const getVenueTablesSuccess = (venueId, tables) => ({
  type: types.tables.GET_VENUE_TABLES.SUCCESS,
  venueId,
  tables,
});
export const getVenueTablesFailure = (venueId, error) => ({
  type: types.tables.GET_VENUE_TABLES.FAILURE,
  venueId,
  error,
});

export const deleteVenueTable = (venueId, tableId) => ({
  type: types.tables.DELETE_VENUE_TABLE.REQUEST,
  venueId,
  tableId,
});
export const deleteVenueTableSuccess = (venueId, tableId) => ({
  type: types.tables.DELETE_VENUE_TABLE.SUCCESS,
  venueId,
  tableId,
});
export const deleteVenueTableFailure = (venueId, error) => ({
  type: types.tables.DELETE_VENUE_TABLE.FAILURE,
  venueId,
  error,
});
