import React, { Component } from "react";
import PropTypes from "prop-types";
import { MINUTES, connectTime } from "react-time-sync";
import { withRouter } from "react-router-dom";

class OrderReroute extends Component {
  constructor(props) {
    super(props);

    this.lastActvity = new Date();

    this.state = {
      routed: false,
    };

    document.addEventListener("mousemove", this.resetInactivityCheck);
    document.addEventListener("mousedown", this.resetInactivityCheck);
    document.addEventListener("touchstart", this.resetInactivityCheck);
    document.addEventListener("click", this.resetInactivityCheck);
    document.addEventListener("scroll", this.resetInactivityCheck);
    document.addEventListener("keypress", this.resetInactivityCheck);
  }

  componentWillMount() {
    this.setState({ routed: true });
  }

  componentWillReceiveProps(nextProps) {
    const { routed } = this.state;
    const { location, history } = this.props;

    if (
      !routed &&
      location === nextProps.location &&
      location.pathname !== "/orders" &&
      new Date() - this.lastActvity > 600000
    ) {
      history.push("/orders");
    }

    this.setState({ routed: false });
  }

  resetInactivityCheck() {
    this.lastActvity = new Date();
  }

  render() {
    return <div />;
  }
}

OrderReroute.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connectTime({ interval: MINUTES, unit: 2 })(
  withRouter(OrderReroute)
);
