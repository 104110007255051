import firebase from "../../lib/firebase";
import { eventChannel } from "redux-saga";
import {
  all,
  put,
  take,
  call,
  fork,
  takeEvery,
  select,
  delay,
  race,
} from "redux-saga/effects";
import {
  loginSuccess,
  loginFailure,
  syncFirebaseUser,
  logoutFailure,
  logoutSuccess,
  retrieveUserVenuesSuccess,
  retrieveUserVenuesFailure,
  retrieveUserVenues,
} from "../actions/auth";
import * as types from "../types";
import { createUserVenuesAPI } from "../../lib/venue";
import { updateCurrentVenue } from "../actions/uiState";
import { send } from "../actions/bridge";
import {
  TO_NATIVE_CREDENTIALS,
  TO_NATIVE_LOGIN,
  TO_NATIVE_LOGOUT,
} from "@orda/backend-shared-constants/tablet-bridge-events";
import { INSIDE_WEBVIEW } from "../../config";
import { setUserId } from "../../lib/analytics";
import { purgeAllPendingOrderRequests } from "../actions/orders";

const NATIVE_LOGIN_TIMEOUT = 20000;

function authChannel() {
  return eventChannel((emit) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => emit({ user }),
      (error) => emit({ error })
    );

    return unsubscribe;
  });
}

function* retrieveUserVenuesSaga() {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const response = yield call(fetch, createUserVenuesAPI(user.uid), {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    const venues = yield call([response, response.json]);

    const lastVenueId = yield call(
      [localStorage, localStorage.getItem],
      "lastVenueId"
    );

    if (!lastVenueId || Object.keys(venues).indexOf(lastVenueId) < 0) {
      yield put(updateCurrentVenue(Object.keys(venues)[0]));
    } else {
      yield put(updateCurrentVenue(lastVenueId));
    }

    yield put(retrieveUserVenuesSuccess(venues));
  } catch (error) {
    yield put(retrieveUserVenuesFailure(error));
  }
}

function* syncUserSaga() {
  const channel = yield call(authChannel);

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      yield call(setUserId, user.uid);
      yield put(syncFirebaseUser(user));
      yield put(retrieveUserVenues());
    } else {
      yield put(syncFirebaseUser(null));
    }
  }
}

export function* logoutSaga() {
  try {
    if (INSIDE_WEBVIEW) {
      yield put(send(TO_NATIVE_LOGOUT));
    }

    const auth = firebase.auth();
    yield call([auth, auth.signOut]);
    yield put(logoutSuccess());
    yield put(purgeAllPendingOrderRequests());
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

export function* loginSaga(action) {
  const { email, password } = action;
  try {
    if (INSIDE_WEBVIEW) {
      yield put(send(TO_NATIVE_CREDENTIALS, { email, password }));
      yield put(send(TO_NATIVE_LOGIN));
      const { timeout } = yield race({
        unblock: take(types.auth.UNBLOCK),
        timeout: delay(NATIVE_LOGIN_TIMEOUT),
      });

      if (timeout) {
        throw new Error("[AUTH] native login failed");
      }
    }
    const auth = firebase.auth();
    yield call([auth, auth.signInWithEmailAndPassword], email, password);
    yield take(types.auth.SYNC_FIREBASE_USER);
    yield put(loginSuccess());
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export default function* authRootSaga() {
  yield fork(syncUserSaga);
  yield all([
    takeEvery(types.auth.LOGIN.REQUEST, loginSaga),
    takeEvery(types.auth.LOGOUT.REQUEST, logoutSaga),
    takeEvery(types.auth.RETRIEVE_USER_VENUES.REQUEST, retrieveUserVenuesSaga),
  ]);
}
