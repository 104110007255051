import * as types from "../types";

export const retrieveActivityLog = (venueId) => ({
  type: types.activityLog.RETRIEVE_ACTIVITY_LOG.REQUEST,
  venueId,
});

export const retrieveActivityLogSuccess = (activityLog) => ({
  type: types.activityLog.RETRIEVE_ACTIVITY_LOG.SUCCESS,
  activityLog,
});

export const retrieveActivityLogFailure = (error) => ({
  type: types.activityLog.RETRIEVE_ACTIVITY_LOG.FAILURE,
  error,
});
