import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";

import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { grey600 } from "@material-ui/core/colors";

import { INSIDE_WEBVIEW } from "../../config";

export default class Error extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    updateNavBarTitle: PropTypes.func.isRequired,
    restartApplication: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    const { updateNavBarTitle } = this.props;
    updateNavBarTitle();
  }

  render() {
    const { error, restartApplication } = this.props;

    return (
      <div className="container" style={{ marginTop: 30 }}>
        <div style={{ textAlign: "center" }}>
          <ErrorOutline color={grey600} style={{ height: 150, width: 150 }} />
          <h1>Error</h1>
          <p style={{ fontSize: "1em", color: grey600, lineHeight: "1.5em" }}>
            <FormattedMessage
              id="error.message"
              defaultMessage="There was an error processing your request. Please contact support if the problem persists."
            />
            {error && error.message}
          </p>
          {INSIDE_WEBVIEW && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={restartApplication}
            >
              Neu starten
            </Button>
          )}
        </div>
      </div>
    );
  }
}
