import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const OptionButtonIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M50,6.33A43.67,43.67,0,1,0,93.67,50,43.72,43.72,0,0,0,50,6.33ZM63.78,50A13.78,13.78,0,1,1,50,36.22,13.78,13.78,0,0,1,63.78,50Z" />
  </SvgIcon>
);

export default OptionButtonIcon;
