import * as React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import { OptionTreeEntry } from "./sub-components/OptionTreeEntry";
import "./OptionTree.css";

interface OptionTreeProps {
  items: any;
  strings: any;
  options: any;
  optionId: any;
  optionsSelect: any;
  venueId: any;
}
const expandOption = (
  optionId: any,
  index: any,
  parentId: any,
  parentType: any,
  strings: any,
  options: any,
  items: any,
  parentLink: any,
  level: any,
  optionsSelect: any,
  venueId: any,
  option: any
): any => {
  const tree = [
    <OptionTreeEntry
      key={optionId}
      level={level}
      // index={index}
      type="OPTION"
      id={optionId}
      entry={options[optionId]}
      label={strings[optionId]}
      parentId={parentId}
      option={option}
      venueId={venueId}
    />,
  ];
  let subTree;
  if (options[optionId].options) {
    subTree = (
      <List key={`${optionId}-suboptions`} disablePadding>
        {options[optionId].options.map(
          (subOptionId: any, subOptionIndex: any) =>
            expandOption(
              subOptionId,
              subOptionIndex,
              optionId,
              "OPTION",
              strings,
              options,
              items,
              `${parentLink}/options/${optionId}`,
              level + 1,
              optionsSelect,
              venueId,
              option
            )
        )}
      </List>
    );
  } else if (options[optionId].items) {
    subTree = (
      <List key={`${optionId}-subItems`} disablePadding>
        {options[optionId].items.map((itemId: any) => (
          <OptionTreeEntry
            key={itemId}
            type="ITEM"
            id={itemId}
            entry={items[itemId]}
            label={strings[itemId]}
            parentId={optionId}
            level={level + 1}
            option={options[optionId]}
            venueId={venueId}
          />
        ))}
      </List>
    );
  } else {
    // shouldn't really happen
  }

  tree.push(subTree as any);
  return tree;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function OptionTree(props: OptionTreeProps) {
  const { options, optionId, strings, items, optionsSelect, venueId } = props;

  return (
    <div className="option-tree-wrapper">
      <div className="option-tree">
        <List disablePadding>
          {options[optionId].options !== undefined
            ? options[optionId].options.map((subOption: any, index: any) =>
                expandOption(
                  subOption,
                  index,
                  optionId,
                  "OPTION",
                  strings,
                  options,
                  items,
                  "",
                  1,
                  optionsSelect,
                  venueId,
                  options[optionId]
                )
              )
            : options[optionId].items.map((itemId: string | number) => (
                <OptionTreeEntry
                  key={`${itemId}`}
                  type="ITEM"
                  id={itemId}
                  entry={items[itemId]}
                  label={strings[itemId]}
                  parentId={optionId}
                  level={undefined}
                  option={options[optionId]}
                  venueId={venueId}
                />
              ))}
        </List>
      </div>
    </div>
  );
}

OptionTree.propTypes = {
  optionId: PropTypes.string.isRequired,
  strings: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  items: PropTypes.any.isRequired,
  optionsSelect: PropTypes.any.isRequired,
};
