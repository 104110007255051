export const isAuthorized = (currentVenue, permissions) => {
  if (!permissions) {
    return true;
  }
  if (currentVenue && currentVenue.permissions) {
    if (
      currentVenue.permissions.length === 1 &&
      currentVenue.permissions[0] === "*"
    ) {
      return true;
    }
    return permissions.every((permission) =>
      currentVenue.permissions.includes(permission)
    );
  }
  return false;
};
