export default {
  LOGIN: {
    REQUEST: "LOGIN.REQUEST",
    SUCCESS: "LOGIN.SUCCESS",
    FAILURE: "LOGIN.FAILURE",
  },
  LOGOUT: {
    REQUEST: "LOGOUT.REQUEST",
    SUCCESS: "LOGOUT.SUCCESS",
    FAILURE: "LOGOUT.FAILURE",
  },
  RETRIEVE_USER_VENUES: {
    REQUEST: "RETRIEVE_USER_VENUES.REQUEST",
    SUCCESS: "RETRIEVE_USER_VENUES.SUCCESS",
    FAILURE: "RETRIEVE_USER_VENUES.FAILURE",
  },
  SYNC_FIREBASE_USER: "SYNC_FIREBASE_USER",
  UNBLOCK: "UNBLOCK",
};
