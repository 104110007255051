import PrivateRoute from "../components/PrivateRoute";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  const {
    uiState: { currentVenueId },
    auth: {
      requests: {
        login: { processing: processingLogin },
        logout: { processing: processingLogout },
        retrieveUserVenues: { processing: processingUserVenues },
      },
      user,
      venues,
      syncFinished,
    },
  } = state;
  return {
    currentVenueId,
    user,
    venues,
    processingLogin,
    processingLogout,
    processingUserVenues,
    syncFinished,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
