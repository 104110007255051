import { connect } from "react-redux";
import { checkOrderedByItemId } from "../../../redux/actions/orders";
import UpdateDishDialog from "./UpdateDishDialog";

const mapStateToProps = (state) => ({
  isOrdered: state.orders.checkOrder.isOrdered,
});

const mapDispatchToProps = {
  checkOrderedByItemId,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDishDialog);
