import * as types from "../types";

export const enqueueOrderReminder = (order) => ({
  type: types.orderReminders.ORDER_REMINDER.ENQUEUE,
  order,
});

export const dequeueOrderReminder = (orderId) => ({
  type: types.orderReminders.ORDER_REMINDER.DEQUEUE,
  orderId,
});
