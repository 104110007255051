import * as types from "../types";

export const ready = () => ({
  type: types.bridge.READY,
});

export const send = (type, payload) => ({
  type: types.bridge.SEND,
  data: {
    type,
    payload,
  },
});

export const init = () => ({
  type: types.bridge.INIT,
});
