import { INSIDE_WEBVIEW } from "../config";

function schedulePageRefresh(hour, minute) {
  const now = new Date();
  let millsTillTime =
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute || 0,
      0,
      0
    ) - now;
  if (millsTillTime < 0) {
    millsTillTime += 86400000; // it's after hour:minute, try hour:minute tomorrow.
  }
  setTimeout(() => {
    window.location.reload();
  }, millsTillTime);
}

function scheduleScrollUp(minutes) {
  let isActive = true;
  let timer;
  function resetScrollTimer() {
    if (!isActive) {
      isActive = true;
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (!isActive) return;
      isActive = false;
      window.scrollTo(0, 0);
    }, minutes * 60 * 1000);
  }

  document.onmousemove = resetScrollTimer;
  document.onmousedown = resetScrollTimer;
  document.ontouchstart = resetScrollTimer;
  document.onclick = resetScrollTimer;
  document.onscroll = resetScrollTimer;
  document.onkeypress = resetScrollTimer;
}

export function initJobs() {
  if (!INSIDE_WEBVIEW) {
    schedulePageRefresh(3, 0);
    scheduleScrollUp(15);
  }
}
