import * as types from "../types";

export const startReminder = (eventType) => ({
  type: types.sound.REMINDER.START,
  eventType,
});

export const stopReminder = () => ({
  type: types.sound.REMINDER.STOP,
});
