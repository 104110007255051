import { connect } from "react-redux";
import Error from "../components/Error";
import { updateNavBarTitle } from "../redux/actions/uiState";
import { TO_NATIVE_RESTART_APPLICATION } from "@orda/backend-shared-constants/tablet-bridge-events";
import { send } from "../redux/actions/bridge";

const mapDispatchToProps = (dispatch) => ({
  updateNavBarTitle: () => dispatch(updateNavBarTitle(null)),
  restartApplication: () => dispatch(send(TO_NATIVE_RESTART_APPLICATION)),
});

export default connect(null, mapDispatchToProps)(Error);
