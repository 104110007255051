import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Save from "@material-ui/icons/Save";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import "../UpdateDishDialog.css";
import {
  orderModusMap,
  // SECTIONS_TO_NOT_RENDER,
  tagsMap,
  vatMap,
} from "../../../../constants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "../../../Tabs/TabPanel";
import { useDispatch } from "react-redux";
import { addExistingOptionToItem } from "../../../../redux/actions/venue";
import { OptionTree } from "../../../OptionTree/OptionTree";
import {
  FormControlLabel,
  Grid,
  OutlinedInput,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { currencyFormat, isNumeric } from "../../../../util/helpers";
import shortid from "shortid";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
  },
  flexableWrap: {
    flexWrap: "nowrap",
    gap: "10px",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width: 960px)"]: {
      gap: "0px",
      flexWrap: "wrap",
    },
  },
};
interface Props {
  itemId: string;
  itemData: any;
  handleSubmitUpdateDish: Function;
  strings: any[];
  sectionOrder: any[];
  openingHours: any[];
  items: any[];
  optionsTree: any[];
  options: any;
  venueId: string;
  classes: any;
}

function findTagName(tagId: string): string {
  const tagObject = tagsMap.find((tagObj) => tagObj.tagId === tagId);
  if (tagObject) {
    return tagObject.tagName;
  }
  return "";
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateDishPropertiesFields: React.FC<Props> = ({
  classes,
  itemId,
  itemData,
  handleSubmitUpdateDish,
  // sectionOrder,
  strings,
  options,
  venueId,
  openingHours,
  items,
  optionsTree,
}): ReturnType<React.FC<Props>> => {
  const { t } = useTranslation("updateDish");
  const { descriptionId } = itemData;

  const [nameDE, setNameDE] = useState(itemData.name);
  const [descriptionDE, setDescriptionDE] = useState(
    itemData.descriptionDE || ""
  );
  const [nameEn, setNameEN] = useState(itemData.nameEn || "");
  const [descriptionEN, setDescriptionEN] = useState(
    itemData.descriptionEN || ""
  );
  const [tags, setTags] = useState<string[]>(itemData.tags || []);
  const [vat, setVat] = useState<any>(itemData.vat || "");
  const [vatToGo, setVatToGo] = useState<any>(itemData.vatToGo || "");
  const [price, setPrice] = useState(
    `${itemData.price ? currencyFormat(`${itemData.price / 100}`) : 0}`
  );
  const [errors, setErrors] = useState({
    price: undefined,
  });
  // const [sectionId, setSectionId] = useState<string>(itemData.sectionId || "");
  const [optionId, setOptionId] = useState<string>(itemData.optionId || "");
  const [tabValue, setTabValue] = useState(0);
  const [hours, setHours] = useState<any>(itemData.hours || "");
  const [orderModus, setOrderModus] = useState<string>(itemData.orderModus);
  const [addon, setAddon] = useState<boolean>(itemData.addon);
  const dispatch = useDispatch();
  const handleChange = (event: any, newValue: number): any => {
    setTabValue(newValue);
  };

  const handleTagChange = useCallback(
    (event: any): void => {
      const { value } = event.target;
      setTags(value);
    },
    [setTags]
  );

  const handleOnSubmitForm = useCallback(
    (event: any): void => {
      event.preventDefault();
      if (errors.price) {
        return;
      }
      const formattedPrice = +(+price.replace(/[,]/g, ".") * 100).toFixed(2);
      let descriptionIdToUpdate =
        descriptionEN || descriptionDE ? descriptionId : null;
      if (!descriptionIdToUpdate && (descriptionEN || descriptionDE)) {
        descriptionIdToUpdate = shortid.generate();
      }

      const payload = {
        propsToUpdate: {
          price: formattedPrice,
          tags,
          hours,
          vat: vat ? +vat : undefined,
          vatToGo: vatToGo ? +vatToGo : undefined,
          notEatIn: orderModus.indexOf("TO-STAY") > -1 ? undefined : true,
          notToGo: orderModus.indexOf("TO-GO") > -1 ? undefined : true,
          addon,
        },
        stringsToUpdate: {
          en: {
            new: {},
            delete: [],
            update: {
              [itemId]: `${nameEn}`,
            },
          },
          de: {
            new: {},
            delete: [],
            update: {
              [itemId]: `${nameDE}`,
            },
          },
        },
      };

      if (descriptionIdToUpdate) {
        // @ts-ignore
        payload.propsToUpdate.description = descriptionIdToUpdate;
        if (descriptionDE) {
          payload.stringsToUpdate.de.update[descriptionIdToUpdate] =
            descriptionDE;
        } else if (itemData.descriptionDE) {
          // @ts-ignore
          payload.stringsToUpdate.de.delete.push(descriptionIdToUpdate);
        }
        if (descriptionEN) {
          payload.stringsToUpdate.en.update[descriptionIdToUpdate] =
            descriptionEN;
        } else if (itemData.descriptionEN) {
          // @ts-ignore
          payload.stringsToUpdate.en.delete.push(descriptionIdToUpdate);
        }
      } else {
        // @ts-ignore
        payload.propsToUpdate.description = null;
      }
      handleSubmitUpdateDish(itemId, payload);
    },
    [
      handleSubmitUpdateDish,
      price,
      tags,
      descriptionId,
      descriptionDE,
      nameDE,
      descriptionEN,
      nameEn,
      vat,
      vatToGo,
      // sectionId,
      hours,
      orderModus,
      addon,
    ]
  );

  const handleOnSubmitFormOption = useCallback(
    (event: any): void => {
      event.preventDefault();
      dispatch(addExistingOptionToItem(venueId, itemId, optionId));
    },
    [addExistingOptionToItem, optionId]
  );

  const setErrorsOnChange = useCallback(
    (priceValue): any => {
      const validationErrors: any = {};
      try {
        if (
          parseFloat(priceValue.replace(/[,]/g, ".")) < 0 ||
          !isNumeric(priceValue.replace(/[,]/g, "."))
        ) {
          validationErrors.price = t("invalidPrice");
        }
      } catch {
        validationErrors.price = t("invalidPrice");
      }
      setErrors(validationErrors);
    },
    [setErrors]
  );

  useEffect(() => {
    setErrorsOnChange(price);
  }, [price]);

  const tabProps = (index: number): any => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  return (
    <DialogContent>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        style={{ marginBottom: 10 }}
      >
        <Tab label={t("updateItemTab")} {...tabProps(0)} />
        {options && options.length > 0 && (
          <Tab label={t("updateOptionTab")} {...tabProps(1)} />
        )}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <form id="update-menu-item-dialog-form" onSubmit={handleOnSubmitForm}>
          <Grid container>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={nameDE}
                  label={t("nameDePlaceholder")}
                  required
                  onChange={(event) => setNameDE(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10, marginRight: 5 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={nameEn}
                  label={t("nameEnPlaceholder")}
                  onChange={(event) => setNameEN(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <TextField
                  value={descriptionDE}
                  fullWidth
                  label={t("descriptionDePlaceholder")}
                  onChange={(event) => setDescriptionDE(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10, marginRight: 5 }}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={descriptionEN}
                  fullWidth
                  label={t("descriptionEnPlaceholder")}
                  onChange={(event) => setDescriptionEN(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <FormControl
                  style={{ marginBottom: 10, marginRight: 5 }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="vat-mutiple-chip-label">
                    {t("vatPlaceholder")}
                  </InputLabel>
                  <Select
                    id="vat-mutiple-chip"
                    value={vat}
                    onChange={(event) => setVat(event.target.value)}
                    input={
                      <OutlinedInput
                        id="vat-mutiple-chip-label"
                        labelWidth={t("vatPlaceholder").length * 8}
                      />
                    }
                    renderValue={() => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {vat}
                      </div>
                    )}
                    required
                    MenuProps={MenuProps}
                  >
                    {vatMap.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ marginBottom: 10, marginRight: 5 }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="vat-togo-mutiple-chip-label">
                    {t("vatToGoPlaceholder")}
                  </InputLabel>
                  <Select
                    id="vat-togo-mutiple-chip"
                    value={vatToGo}
                    onChange={(event) => setVatToGo(event.target.value)}
                    input={
                      <OutlinedInput
                        id="vat-togo-mutiple-chip-label"
                        labelWidth={t("vatToGoPlaceholder").length * 8}
                      />
                    }
                    renderValue={() => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {vatToGo}
                      </div>
                    )}
                    required
                    MenuProps={MenuProps}
                  >
                    {vatMap.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={price}
                  label={t("pricePlaceholder")}
                  required
                  error={errors.price}
                  onChange={(event) => setPrice(event.target.value)}
                  inputProps={{ inputMode: "decimal" }}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="hours-mutiple-chip-label">
                {t("hoursPlaceholder")}
              </InputLabel>
              <Select
                id="hours-mutiple-chip"
                fullWidth
                value={hours}
                onChange={(event) => setHours(event.target.value)}
                input={
                  <OutlinedInput
                    id="hours-mutiple-chip-label"
                    labelWidth={t("hoursPlaceholder").length * 8}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {hours}
                  </div>
                )}
                required
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                <MenuItem key="" value=""></MenuItem>
                {Object.keys(openingHours)
                  .filter((h) => h !== "timeZone")
                  .map((h) => {
                    return (
                      <MenuItem key={h} value={h}>
                        {h}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="order-mode-mutiple-chip-label">
                {t("orderModusPlaceholder")}
              </InputLabel>
              <Select
                id="order-mode-mutiple-chip"
                fullWidth
                value={orderModus}
                onChange={(event) => setOrderModus(event.target.value)}
                input={
                  <OutlinedInput
                    id="order-mode-mutiple-chip-label"
                    labelWidth={t("orderModusPlaceholder").length * 8}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                      orderModusMap.find(
                        (obj) => obj.orderModusId === orderModus
                      )?.orderModusName
                    }
                  </div>
                )}
                required
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                {orderModusMap.map(({ orderModusName, orderModusId }) => (
                  <MenuItem key={orderModusId} value={orderModusId}>
                    {orderModusName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="tags-mutiple-chip-label">
                {t("tagsPlaceholder")}
              </InputLabel>
              <Select
                id="tags-mutiple-chip"
                multiple
                fullWidth
                value={tags}
                onChange={handleTagChange}
                input={
                  <OutlinedInput
                    id="tags-mutiple-chip-label"
                    labelWidth={t("tagsPlaceholder").length * 10}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {tags.map((tagId) => (
                      <Chip
                        key={tagId}
                        label={findTagName(tagId)}
                        style={{ margin: 2 }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                {tagsMap.map(({ tagName, tagId }) => (
                  <MenuItem
                    key={tagId}
                    value={tagId}
                    style={{
                      fontWeight:
                        tags.indexOf(tagId) === -1 ? "normal" : "bold",
                    }}
                  >
                    {tagName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox color="primary" checked={addon} />}
              label={t("addonPlaceholder")}
              onChange={() => setAddon(!addon)}
            />
          </Grid>
          <DialogActions>
            <Button
              fullWidth
              size="large"
              variant="contained"
              style={{
                backgroundColor: "#B9D47F",
              }}
              type="submit"
              form="update-menu-item-dialog-form"
            >
              {t("submitUpdate")}
            </Button>
          </DialogActions>
        </form>
      </TabPanel>
      {options && options.length > 0 && (
        <TabPanel value={tabValue} index={1}>
          <form
            id="update-menu-option-dialog-form"
            onSubmit={handleOnSubmitFormOption}
          >
            <DialogTitle>{t("addExistingOption")}</DialogTitle>
            <DialogContent>
              <FormControl style={{ width: "85%" }}>
                <InputLabel id="select-option-mutiple-chip-label">
                  {t("selectOptionPlaceholder")}
                </InputLabel>
                <Select
                  id="select-option-mutiple-chip"
                  value={optionId}
                  onChange={(event) => setOptionId(event.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={() => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {options.find((obj: any) => obj.id === optionId)?.label}
                    </div>
                  )}
                  required
                  MenuProps={MenuProps}
                >
                  {options.map(({ id, label }: { id: any; label: any }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <IconButton
                style={{
                  backgroundColor: "#B9D47F",
                  margin: 10,
                }}
                type="submit"
                form="update-menu-option-dialog-form"
              >
                <Save style={{ color: "white" }} />
                {/* {t("submitUpdate")} */}
              </IconButton>
            </DialogContent>
            {/* <DialogActions>
            <Button
              fullWidth
              size="large"
              variant="contained"
              style={{
                backgroundColor: "#B9D47F",
              }}
              type="submit"
              form="update-menu-option-dialog-form"
            >
              {t("submitUpdate")}
            </Button>
          </DialogActions> */}
          </form>
          {optionId && optionsTree && (
            <OptionTree
              optionId={optionId}
              options={optionsTree}
              optionsSelect={options}
              strings={strings}
              items={items}
              venueId={venueId}
            />
          )}
        </TabPanel>
      )}
    </DialogContent>
  );
};

UpdateDishPropertiesFields.propTypes = {
  handleSubmitUpdateDish: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  itemData: PropTypes.any.isRequired,
  strings: PropTypes.array.isRequired,
  sectionOrder: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  venueId: PropTypes.string.isRequired,
  openingHours: PropTypes.any.isRequired,
  items: PropTypes.any.isRequired,
  optionsTree: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles as any)(UpdateDishPropertiesFields);
