import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PulseLoader from "../PulseLoader";
import PropTypes from "prop-types";
import Toggle from "../CustomToggle";
import ListItem from "./sub-components/ListItem";
import SectionHeader from "./sub-components/SectionHeader";
import ConfirmGlobalDisableDialog from "../ConfirmGlobalDisableDialog/ConfirmGlobalDisableDialog";
import firebase from "../../lib/firebase";
import { INSIDE_WEBVIEW } from "../../config";
import Loading from "../Loading";
import { HOURS, connectTime } from "react-time-sync";
import AddDishDialog from "./add-dish-dialog/AddDishDialog";
import "./Availability.css";
import { SECTIONS_TO_NOT_RENDER } from "../../constants";
import Accordion from "../Accordion";
import { THEME } from "../../util/theme";
import { Add } from "@material-ui/icons";
import SectionNameDialog from "../../containers/SectionNameDialog";

const CONFIRM_GLOBAL_DISABLE_DIALOG_ENABLED = INSIDE_WEBVIEW;

const globalStatusStyle = {
  borderBottom: "1px solid #bdbdbd",
  fontSize: "0.8em",
  color: "#212121",
  minHeight: 48,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 24,
};

const toggleContainerStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
};

const toggleLabelStyle = {
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
};

const generateOptionsList = (
  optionId,
  parentLevel,
  parentOption,
  venue,
  toggleItemAvailability,
  toggleOptionAvailability,
  processingToggleAvailability,
  processingTargetId,
  updateItem,
  deleteItem
) => {
  const strings = venue.strings[venue.defaultLocale];
  const option = venue.menu.options[optionId];
  if (option.items) {
    return (
      <ul>
        {option.items.map((itemId) => (
          <ListItem
            updateItem={updateItem}
            deleteItem={deleteItem}
            toggleable
            type="item"
            level={parentLevel + 1}
            expandable={false}
            key={itemId}
            id={itemId}
            venue={venue}
            name={strings[itemId]}
            nameEn={venue.strings.en[itemId]}
            parentOption={parentOption}
            toggleItemAvailability={toggleItemAvailability}
            toggleOptionAvailability={toggleOptionAvailability}
            processingToggleAvailability={processingToggleAvailability}
            processingTargetId={processingTargetId}
          />
        ))}
      </ul>
    );
  }

  let optionsList = null;
  if (option.options) {
    optionsList = option.options.map((subOptionId) => {
      const subOption = venue.menu.options[subOptionId];
      return (
        <ListItem
          type="option"
          expandable={!venue.menu.options[subOptionId].unavailable}
          level={parentLevel + 1}
          toggleable={!subOption.readOnly && subOption.type === "AND"}
          updateItem={updateItem}
          deleteItem={deleteItem}
          key={subOptionId}
          name={strings[subOptionId]}
          nameEn={venue.strings.en[subOptionId]}
          id={subOptionId}
          venue={venue}
          parentOption={parentOption}
          toggleItemAvailability={toggleItemAvailability}
          toggleOptionAvailability={toggleOptionAvailability}
          processingToggleAvailability={processingToggleAvailability}
          processingTargetId={processingTargetId}
        >
          {generateOptionsList(
            subOptionId,
            parentLevel + 1,
            subOptionId,
            venue,
            toggleItemAvailability,
            toggleOptionAvailability,
            processingToggleAvailability,
            processingTargetId,
            updateItem,
            deleteItem
          )}
        </ListItem>
      );
    });
  }

  return <ul>{optionsList}</ul>;
};

class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmGlobalDisableDialogOpen: false,
      passwordError: false,
      processingPassword: false,
      addDishDialogOpen: false,
      sectionDialog: { open: false, dialogType: "NEW" },
      defaultSection:
        props.venue &&
        props.venue.menu.sectionOrder.filter(
          (sectionId) => !SECTIONS_TO_NOT_RENDER.includes(sectionId)
        ).length > 0
          ? props.venue.menu.sectionOrder.filter(
              (sectionId) => !SECTIONS_TO_NOT_RENDER.includes(sectionId)
            )[0]
          : "dishes-0",
    };
    this.openAddDishDialog = this.openAddDishDialog.bind(this);
    this.handleSubmitAddDish = this.handleSubmitAddDish.bind(this);
  }

  componentDidMount() {
    const { retrieveVenue, updateNavBarTitle, currentVenueId } = this.props;
    updateNavBarTitle();
    retrieveVenue(currentVenueId);
  }

  componentWillReceiveProps(nextProps) {
    const { currentTime, retrieveVenue, currentVenueId } = this.props;
    if (nextProps.currentTime !== currentTime) {
      retrieveVenue(currentVenueId);
    }
  }

  onAvailableChange = (itemId, checked) => {
    const {
      toggleItemAvailability,
      venue: { id },
    } = this.props;
    toggleItemAvailability(id, itemId, checked);
  };

  onGlobalStatusToggle = (checked) => {
    const {
      toggleVenueAvailability,
      venue: { id },
    } = this.props;
    toggleVenueAvailability(id, checked);
  };

  handleGlobalDisableDialogConfirm = async (password) => {
    const {
      toggleVenueAvailability,
      venue: { id },
    } = this.props;
    try {
      this.setState({
        processingPassword: true,
      });
      await firebase
        .auth()
        .currentUser.reauthenticateAndRetrieveDataWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser.email,
            password
          )
        );
      await toggleVenueAvailability(id, false);
      this.setState({
        confirmGlobalDisableDialogOpen: false,
        passwordError: false,
        processingPassword: false,
      });
    } catch (error) {
      this.setState({
        passwordError: true,
        processingPassword: false,
      });
    }
  };

  handleGlobalDisableDialogClose = () => {
    this.setState({
      confirmGlobalDisableDialogOpen: false,
      passwordError: false,
    });
  };

  openAddDishDialog(sectionId) {
    this.setState({ defaultSection: sectionId });
    this.setState({ addDishDialogOpen: true });
  }

  openSectionDialog(sectionId, dialogType) {
    this.setState({ defaultSection: sectionId });
    this.setState({
      sectionDialog: { open: true, dialogType },
    });
  }

  handleSubmitAddDish(payload) {
    const {
      createItem,
      venue: { id },
    } = this.props;
    createItem(id, payload);
    this.setState({ addDishDialogOpen: false });
  }

  render() {
    const {
      venue,
      retrieveVenueProcessing,
      processingToggleAvailability,
      toggleVenueAvailability,
      toggleSectionAvailability,
      processingTargetId,
      toggleItemAvailability,
      toggleOptionAvailability,
      updateItem,
      deleteItem,
      t,
      toggleDeliveryOrdersStatus,
      processingToggleDeliveryOrders,
    } = this.props;

    const {
      confirmGlobalDisableDialogOpen,
      passwordError,
      processingPassword,
      addDishDialogOpen,
      sectionDialog,
      defaultSection,
    } = this.state;
    if (!venue) {
      return <div />;
    }

    if (retrieveVenueProcessing) {
      return <Loading />;
    }

    const strings = venue.strings[venue.defaultLocale];

    const availabilityContainerStyle = { height: "calc(100vh - 116px)" };

    const enableDeliveryToggle =
      venue &&
      venue.deliveryEnabled !== undefined &&
      venue.deliveryEnabled !== null;

    return (
      <>
        <div
          style={availabilityContainerStyle}
          className="availability-container"
        >
          <Paper>
            <div style={globalStatusStyle}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 600,
                  }}
                >
                  {t("ordersAvailability")}
                </div>
                {processingToggleAvailability ? (
                  <PulseLoader />
                ) : (
                  <Toggle
                    toggled={!venue.maintenance}
                    disabled={processingToggleAvailability}
                    onToggle={async ({ target: { checked } }) => {
                      if (!checked && CONFIRM_GLOBAL_DISABLE_DIALOG_ENABLED) {
                        this.setState({
                          confirmGlobalDisableDialogOpen: true,
                        });
                      } else {
                        await toggleVenueAvailability(venue.id, checked);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </Paper>
          {enableDeliveryToggle && (
            <Paper>
              <div style={globalStatusStyle}>
                <div style={toggleContainerStyle}>
                  <div style={toggleLabelStyle}>Lieferungen akzeptieren</div>
                  {processingToggleDeliveryOrders ? (
                    <PulseLoader />
                  ) : (
                    <Toggle
                      toggled={venue.deliveryEnabled}
                      disabled={processingToggleDeliveryOrders}
                      onToggle={async ({ target: { checked } }) => {
                        await toggleDeliveryOrdersStatus(venue.id, checked);
                      }}
                    />
                  )}
                </div>
              </div>
            </Paper>
          )}
          <Button
            fullWidth
            onClick={() => this.openSectionDialog("", "NEW")}
            style={{
              backgroundColor: THEME.BORDER_GRAY,
              margin: "10px 0",
              fontWeight: "bold",
            }}
            variant="contained"
          >
            <Add />
            {t("addSection")}
          </Button>
          <div>
            {venue &&
              venue.menu.sectionOrder
                .filter(
                  (sectionId) => !SECTIONS_TO_NOT_RENDER.includes(sectionId)
                )
                .map((sectionId) => {
                  const section = venue.menu.sections[sectionId];
                  return (
                    <div style={{ margin: "10px 0" }} key={sectionId}>
                      <Accordion
                        title={
                          <SectionHeader
                            id={sectionId}
                            name={strings[sectionId]}
                            venue={venue}
                            toggleSectionAvailability={
                              toggleSectionAvailability
                            }
                            updateSection={() =>
                              this.openSectionDialog(sectionId, "EDIT")
                            }
                            processingTargetId={processingTargetId}
                            processingToggleAvailability={
                              processingToggleAvailability
                            }
                          />
                        }
                        content={
                          <div
                            className="list-wrapper"
                            style={{
                              backgroundColor: THEME.CARD_BACKGROUND_COLOR,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: THEME.MAX_SCREEN_WIDTH,
                                gap: 20,
                                margin: 10,
                              }}
                            >
                              <Button
                                fullWidth
                                onClick={() =>
                                  this.openAddDishDialog(sectionId)
                                }
                                style={{
                                  backgroundColor: THEME.PRIMARY_BUTTON_ICON,
                                  fontWeight: "bold",
                                }}
                                variant="contained"
                              >
                                <Add />
                                {t("addItem")}
                              </Button>
                              {/* <Button
                                fullWidth
                                onClick={this.openAddDishDialog}
                                style={{
                                  backgroundColor: THEME.PRIMARY_BUTTON_ICON,
                                  margin: "10xp 20px",
                                  fontWeight: "bold",
                                }}
                                variant="contained"
                              >
                                <Add />
                                {t("addExistingItem")}
                              </Button> */}
                            </div>
                            <ul>
                              {section.items.map((itemId) => {
                                const { options } = venue.menu.items[itemId];
                                return (
                                  <ListItem
                                    updateItem={updateItem}
                                    deleteItem={deleteItem}
                                    sectionId={sectionId}
                                    type="item"
                                    toggleable
                                    level={0}
                                    expandable={
                                      !!options &&
                                      !venue.menu.items[itemId].unavailable
                                    }
                                    key={itemId}
                                    name={strings[itemId]}
                                    nameEn={venue.strings.en[itemId]}
                                    id={itemId}
                                    parentOption={null}
                                    venue={venue}
                                    toggleItemAvailability={
                                      toggleItemAvailability
                                    }
                                    toggleOptionAvailability={
                                      toggleOptionAvailability
                                    }
                                    processingToggleAvailability={
                                      processingToggleAvailability
                                    }
                                    processingTargetId={processingTargetId}
                                  >
                                    {options
                                      ? generateOptionsList(
                                          options,
                                          0,
                                          null,
                                          venue,
                                          toggleItemAvailability,
                                          toggleOptionAvailability,
                                          processingToggleAvailability,
                                          processingTargetId,
                                          updateItem,
                                          deleteItem
                                        )
                                      : null}
                                  </ListItem>
                                );
                              })}
                            </ul>
                          </div>
                        }
                      />
                    </div>
                  );
                })}
          </div>
          <ConfirmGlobalDisableDialog
            open={confirmGlobalDisableDialogOpen}
            passwordError={passwordError}
            confirmHandler={this.handleGlobalDisableDialogConfirm}
            closeHandler={this.handleGlobalDisableDialogClose}
            processingPassword={processingPassword}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            maxWidth: THEME.MAX_SCREEN_WIDTH,
            width: "100%",
            bottom: 0,
            zIndex: 100,
          }}
        >
          <Button
            onClick={this.openAddDishDialog}
            style={{
              backgroundColor: THEME.PRIMARY_BUTTON_ICON,
              margin: "10xp 20px",
              fontWeight: "bold",
            }}
            variant="contained"
          >
            {t("addDish")}
          </Button>
        </div> */}
        <AddDishDialog
          sectionId={defaultSection}
          strings={strings}
          sectionOrder={venue.menu.sectionOrder}
          open={addDishDialogOpen}
          handleSubmitAddDish={this.handleSubmitAddDish}
          handleCloseDialog={() => this.setState({ addDishDialogOpen: false })}
          openingHours={(venue && venue.openingHours) || []}
        />
        <ConfirmGlobalDisableDialog
          open={confirmGlobalDisableDialogOpen}
          passwordError={passwordError}
          confirmHandler={this.handleGlobalDisableDialogConfirm}
          closeHandler={this.handleGlobalDisableDialogClose}
          processingPassword={processingPassword}
        />
        <SectionNameDialog
          sectionId={defaultSection}
          open={sectionDialog.open}
          dialogType={sectionDialog.dialogType}
          handleClose={() =>
            this.setState({ sectionDialog: { ...sectionDialog, open: false } })
          }
        />
      </>
    );
  }
}

Availability.propTypes = {
  currentTime: PropTypes.number.isRequired,
  retrieveVenue: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
  venue: PropTypes.object,
  retrieveVenueProcessing: PropTypes.bool.isRequired,
  toggleSectionAvailability: PropTypes.func.isRequired,
  toggleItemAvailability: PropTypes.func.isRequired,
  toggleOptionAvailability: PropTypes.func.isRequired,
  toggleVenueAvailability: PropTypes.func.isRequired,
  toggleDeliveryOrdersStatus: PropTypes.func.isRequired,
  processingToggleDeliveryOrders: PropTypes.bool.isRequired,
  processingToggleAvailability: PropTypes.bool.isRequired,
  processingTargetId: PropTypes.string,
  updateNavBarTitle: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string,
  createItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

Availability.defaultProps = {
  venue: null,
  currentVenueId: null,
  processingTargetId: null,
};

export default withTranslation("venueAvailability")(
  connectTime({ interval: HOURS })(Availability)
);
