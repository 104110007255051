export default {
  SET_NAVBAR_HIDDEN: "SET_NAVBAR_HIDDEN",
  UPDATE_CURRENT_VENUE_ID: "UPDATE_CURRENT_VENUE_ID",
  UPDATE_NAVBAR_TITLE: "UPDATE_NAVBAR_TITLE",
  INCREMENT_BUILD_NUMBER_TAP_COUNT: "INCREMENT_BUILD_NUMBER_TAP_COUNT",
  RESET_BUILD_NUMBER_TAP_COUNT: "RESET_BUILD_NUMBER_TAP_COUNT",
  DIALOGS: {
    STATUS: {
      TOGGLE: "DIALOGS.STATUS.TOGGLE",
    },
    CHECKIN: {
      DISMISS: "DIALOGS.CHECKIN.DISMISS",
    },
    BATTERY_WARNING: {
      SHOW: "DIALOGS.BATTERY_WARNING.SHOW",
      DISMISS: "DIALOGS.BATTERY_WARNING.DISMISS",
    },
  },
  PRINTERS_INITIALIZED: "PRINTERS_INITIALIZED",
};
