import * as types from "../types";

export const closeAllNotifications = () => ({
  type: types.notifications.CLOSE_ALL,
});

export const setNotification = (status, message, toast) => ({
  type: types.notifications.SET,
  status,
  message,
  toast,
});

export const closeNotificationById = (id) => ({
  type: types.notifications.CLOSE_BY_ID,
  id,
});
