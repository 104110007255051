export default {
  RETRIEVE_VENUE: {
    REQUEST: "RETRIEVE_VENUE.REQUEST",
    SUCCESS: "RETRIEVE_VENUE.SUCCESS",
    FAILURE: "RETRIEVE_VENUE.FAILURE",
  },
  TOGGLE_VENUE_AVAILABILITY: {
    REQUEST: "TOGGLE_VENUE_AVAILABILITY.REQUEST",
    SUCCESS: "TOGGLE_VENUE_AVAILABILITY.SUCCESS",
    FAILURE: "TOGGLE_VENUE_AVAILABILITY.FAILURE",
  },
  TOGGLE_SECTION_AVAILABILITY: {
    REQUEST: "TOGGLE_SECTION_AVAILABILITY.REQUEST",
    SUCCESS: "TOGGLE_SECTION_AVAILABILITY.SUCCESS",
    FAILURE: "TOGGLE_SECTION_AVAILABILITY.FAILURE",
  },
  TOGGLE_ITEM_AVAILABILITY: {
    REQUEST: "TOGGLE_ITEM_AVAILABILITY.REQUEST",
    SUCCESS: "TOGGLE_ITEM_AVAILABILITY.SUCCESS",
    FAILURE: "TOGGLE_ITEM_AVAILABILITY.FAILURE",
  },
  TOGGLE_OPTION_AVAILABILITY: {
    REQUEST: "TOGGLE_OPTION_AVAILABILITY.REQUEST",
    SUCCESS: "TOGGLE_OPTION_AVAILABILITY.SUCCESS",
    FAILURE: "TOGGLE_OPTION_AVAILABILITY.FAILURE",
  },
  UDPATE_VENUE_TRAFFIC: {
    REQUEST: "UDPATE_VENUE_TRAFFIC.REQUEST",
    SUCCESS: "UDPATE_VENUE_TRAFFIC.SUCCESS",
    FAILURE: "UDPATE_VENUE_TRAFFIC.FAILURE",
  },
  AUTOMATIC_VENUE_TRAFFIC: {
    ENABLE: "AUTOMATIC_VENUE_TRAFFIC.ENABLE",
    DISABLE: "AUTOMATIC_VENUE_TRAFFIC.DISABLE",
  },
  VENUE_CREATE_ITEM: {
    REQUEST: "VENUE_CREATE_DISH.REQUEST",
    SUCCESS: "VENUE_CREATE_DISH.SUCCESS",
    FAILURE: "VENUE_CREATE_DISH.FAILURE",
  },
  ADD_EXISTING_OPTION_TO_ITEM: {
    REQUEST: "ADD_EXISTING_OPTION_TO_ITEM.REQUEST",
    SUCCESS: "ADD_EXISTING_OPTION_TO_ITEM.SUCCESS",
    FAILURE: "ADD_EXISTING_OPTION_TO_ITEM.FAILURE",
  },
  ADD_EXISTING_OPTION_TO_OPTION: {
    REQUEST: "ADD_EXISTING_OPTION_TO_OPTION.REQUEST",
    SUCCESS: "ADD_EXISTING_OPTION_TO_OPTION.SUCCESS",
    FAILURE: "ADD_EXISTING_OPTION_TO_OPTION.FAILURE",
  },
  VENUE_UPDATE_ITEM: {
    REQUEST: "VENUE_UPDATE_DISH.REQUEST",
    SUCCESS: "VENUE_UPDATE_DISH.SUCCESS",
    FAILURE: "VENUE_UPDATE_DISH.FAILURE",
    UPDATE_FILES: "VENUE_UPDATE_DISH.UPDATE_FILES",
  },
  VENUE_DELETE_ITEM: {
    REQUEST: "VENUE_DELETE_DISH.REQUEST",
    SUCCESS: "VENUE_DELETE_DISH.SUCCESS",
    FAILURE: "VENUE_DELETE_DISH.FAILURE",
  },
  TOGGLE_DELIVERY_ORDERS_STATUS: {
    REQUEST: "TOGGLE_DELIVERY_ORDERS_STATUS.REQUEST",
    SUCCESS: "TOGGLE_DELIVERY_ORDERS_STATUS.SUCCESS",
    FAILURE: "TOGGLE_DELIVERY_ORDERS_STATUS.FAILURE",
  },
  VENUE_CREATE_SECTION: {
    REQUEST: "VENUE_CREATE_SECTION.REQUEST",
    SUCCESS: "VENUE_CREATE_SECTION.SUCCESS",
    FAILURE: "VENUE_CREATE_SECTION.FAILURE",
  },
  VENUE_UPDATE_SECTION: {
    REQUEST: "VENUE_UPDATE_SECTION.REQUEST",
    SUCCESS: "VENUE_UPDATE_SECTION.SUCCESS",
    FAILURE: "VENUE_UPDATE_SECTION.FAILURE",
  },
  VENUE_UPDATE_SECTION_ORDER: {
    REQUEST: "VENUE_UPDATE_SECTION_ORDER.REQUEST",
    SUCCESS: "VENUE_UPDATE_SECTION_ORDER.SUCCESS",
    FAILURE: "VENUE_UPDATE_SECTION_ORDER.FAILURE",
  },
};
