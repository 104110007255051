import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Delete } from "@material-ui/icons";
import { THEME } from "../../../util/theme";
import DeletePrompt from "../../DeletePrompt";

const SectionNameDialog = ({
  open,
  dialogType,
  sectionId,
  strings,
  sectionOrder,
  currentVenueId,
  handleClose,
  handleCreateSection,
  handleRenameSection,
  updateSectionOrder,
}) => {
  const [names, setNames] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleTextChange = (event) => {
    setNames({
      ...names,
      [event.target.attributes.id.value]: event.target.value,
    });
  };

  const handleSave = () => {
    if (dialogType === "NEW") {
      handleCreateSection(currentVenueId, names);
    } else if (dialogType === "EDIT") {
      handleRenameSection(currentVenueId, sectionId, names);
    }
    handleClose();
  };

  const handleDelete = () => {
    const newSectionOrder = [...sectionOrder];
    const sectionIndex = newSectionOrder.indexOf(sectionId);
    if (sectionIndex !== -1) {
      newSectionOrder.splice(sectionIndex, 1);
    }
    updateSectionOrder(currentVenueId, newSectionOrder);
    setOpenConfirmDialog(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogType === "NEW" ? "Neue Warengruppe" : "Warengruppe bearbeiten"}
      </DialogTitle>
      <DialogContent>
        {Object.keys(strings)
          .filter((lang) => lang !== "venue")
          .map((lang) => (
            <TextField
              fullWidth
              id={lang}
              key={`section-${lang}`}
              label={`Name (${lang})`}
              defaultValue={
                dialogType === "NEW" ? "" : strings[lang][sectionId]
              }
              value={names.lang}
              onChange={handleTextChange}
            />
          ))}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: dialogType === "NEW" ? "flex-end" : "space-between",
          alignItems: "center",
        }}
      >
        {dialogType === "EDIT" && (
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            color="primary"
            autoFocus
          >
            <Delete style={{ color: THEME.DELETE_RED_COLOR }} />
            Löschen
          </Button>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button onClick={handleClose} color="primary" autoFocus>
            Abbrechen
          </Button>
          <Button onClick={handleSave} color="primary">
            {dialogType === "NEW" ? "Erstellen" : "Speichern"}
          </Button>
        </div>
      </DialogActions>
      {dialogType === "EDIT" && (
        <DeletePrompt
          open={openConfirmDialog}
          cancel={() => setOpenConfirmDialog(false)}
          confirm={handleDelete}
          title={<div style={{ textAlign: "center" }}>WARENGRUPPE LÖSCHEN</div>}
          content={
            <Typography
              variant="body1"
              style={{ paddingTop: 0, textAlign: "center" }}
            >
              Bist Du Dir sicher, dass Du die Warengruppe löschen möchtest?
              Dieser Schritt kann nicht rückgängig gemacht werden.
            </Typography>
          }
        />
      )}
    </Dialog>
  );
};

SectionNameDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateSection: PropTypes.func.isRequired,
  handleRenameSection: PropTypes.func.isRequired,
  updateSectionOrder: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
  sectionOrder: PropTypes.any.isRequired,
  sectionId: PropTypes.string.isRequired,
  dialogType: PropTypes.string.isRequired,
  currentVenueId: PropTypes.string,
};
SectionNameDialog.defaultProps = {
  currentVenueId: null,
};

export default SectionNameDialog;
