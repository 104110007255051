import { select, takeLatest, call, put } from "redux-saga/effects";
import * as types from "../types";
import { createVenueSalesAPI } from "../../lib/venue";
import { retrieveSalesSuccess, retrieveSalesFailure } from "../actions/sales";

function* retrieveSalesSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, start, end } = action;

    const response = yield call(
      fetch,
      createVenueSalesAPI(venueId, {
        start,
        end,
      }),
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
        }),
      }
    );

    const data = yield call([response, response.json]);
    yield put(retrieveSalesSuccess(data));
  } catch (error) {
    yield put(retrieveSalesFailure(error));
  }
}

export default function* salesRootSaga() {
  yield takeLatest(types.sales.RETRIEVE_SALES.REQUEST, retrieveSalesSaga);
}
