import React, { useState } from "react";
import PropTypes from "prop-types";

import { StatusEnum } from "../../constants";
import NotificationModal from "../NotificationModal";

import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

import "./CustomFileInput.css";

const inlineStyles = {
  infoIconContainer: {
    width: "90px",
    display: "flex",
    justifyContent: "end",
    position: "relative",
    height: 0,
  },
};

const infoIconSize = "15px";

const CustomFileInput = ({
  id,
  name,
  label,
  iconSrc,
  color,
  labelStyles,
  inputProps,
  iconProps,
  iconHeight,
  infoMsg,
}) => {
  const [isInfoModalOpen, setOpenInfoModal] = useState(false);
  return (
    <>
      {infoMsg && (
        <NotificationModal
          isOpen={isInfoModalOpen}
          info={{
            status: StatusEnum.IDLE,
            message: infoMsg,
          }}
          title="Videos"
          hideButtonClose
          hideIcon
          onCloseIcon={() => {
            setOpenInfoModal(false);
          }}
        />
      )}
      <label
        className="custom-file-input-label"
        style={{
          color,
          ...labelStyles,
        }}
        htmlFor={id}
      >
        {infoMsg && (
          <>
            <div style={inlineStyles.infoIconContainer}>
              <IconButton
                style={{ width: infoIconSize }}
                onClick={() => {
                  setOpenInfoModal(true);
                }}
              >
                <InfoIcon style={{ width: infoIconSize }} />
              </IconButton>
            </div>
          </>
        )}
        <input
          type="file"
          id={id}
          className="custom-file-input"
          name={name}
          {...inputProps}
        />
        {iconSrc && (
          <img
            alt="icon-upload"
            src={iconSrc}
            style={{
              height: iconHeight,
            }}
            {...iconProps}
          />
        )}
        {label}
      </label>
    </>
  );
};

CustomFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iconHeight: PropTypes.number,
  iconProps: PropTypes.object,
  inputProps: PropTypes.object,
  labelStyles: PropTypes.object,
  color: PropTypes.string,
  iconSrc: PropTypes.string,
  label: PropTypes.string,
  infoMsg: PropTypes.string,
};

CustomFileInput.defaultProps = {
  iconHeight: 30,
  iconProps: {},
  inputProps: {},
  labelStyles: {},
  color: "black",
  iconSrc: null,
  infoMsg: null,
  label: "",
};

export default CustomFileInput;
