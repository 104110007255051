import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {
  NEW_ORDER,
  ORDER_REMINDER,
  CHECKIN,
  BATTERY_WARNING,
} from "@orda/backend-shared-constants/tablet-bridge-events";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
  VENUE_LOCATION_DELIVERY,
} from "@orda/shared-constants/order-locations";
import {
  Avatar,
  Typography,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import RunIcon from "@material-ui/icons/DirectionsRun";
import FoodIcon from "@material-ui/icons/LocalDining";
import Delivery from "../assets/icons/delivery.svg";
import moment from "moment";
import { FormattedNumber } from "react-intl";
import { amber } from "@material-ui/core/colors";
import CheckInIcon from "@material-ui/icons/HowToReg";
import BatteryAlert from "@material-ui/icons/BatteryAlert";
import { calculateSumForVenue } from "@orda/shared-functions-js/lib/orders";

const useStyles = () => ({
  containerModal: {
    minWidth: "95%",
    minHeight: "40%",
    borderRadius: "16px",
    backgroundColor: "white",
    border: "3px solid #2C2F3A",
  },
});

const getDialogTitle = (type, content, isHobbyCook) => {
  const formatIconOrderType = (order) => {
    if (order.orderLocation === VENUE_LOCATION_EAT_IN) {
      return <FoodIcon />;
    }
    if (order.orderLocation === VENUE_LOCATION_DELIVERY) {
      return <img alt="delivery" style={{ width: "30px" }} src={Delivery} />;
    }
    if (order.orderLocation === VENUE_LOCATION_TO_GO) {
      return <RunIcon />;
    }
    return "Error";
  };
  const formatStringOrderType = (order) => {
    if (order.orderLocation === VENUE_LOCATION_EAT_IN) {
      return "zum Hier-Essen";
    }
    if (order.orderLocation === VENUE_LOCATION_DELIVERY) {
      return "zum Liefern";
    }
    if (order.orderLocation === VENUE_LOCATION_TO_GO) {
      return "zum Mitnehmen";
    }
    return "Error";
  };

  const formatColorOrderType = (order) => {
    if (order.orderLocation === VENUE_LOCATION_EAT_IN) {
      return "#27ae60";
    }
    if (order.orderLocation === VENUE_LOCATION_DELIVERY) {
      return "#F2695D";
    }
    if (order.orderLocation === VENUE_LOCATION_TO_GO) {
      return "#2980b9";
    }
    return "Error";
  };

  switch (type) {
    case NEW_ORDER: {
      const { order } = content;
      const time = order.orderTime
        ? moment()
            .hours(parseInt(order.orderTime.slice(0, 2), 10))
            .minutes(parseInt(order.orderTime.slice(2), 10))
            .format("HH:mm")
        : "Jetzt";
      return (
        <div
          style={{
            display: "flex",
            justifyContent: isHobbyCook ? "center" : "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "center",
              justifyContent: isHobbyCook ? "center" : "space-between",
              fontWeight: "bold",
            }}
          >
            {!isHobbyCook && (
              <Avatar
                style={{
                  backgroundColor: formatColorOrderType(order),
                  color: "#fff",
                  marginRight: 15,
                }}
              >
                {formatIconOrderType(order)}
              </Avatar>
            )}
            Neue Bestellung {formatStringOrderType(order)}
          </div>
          {!isHobbyCook && (
            <Typography
              variant="h6"
              style={{
                padding: 8,
                paddingTop: 4,
                paddingBottom: 4,
                color: "#fff",
                backgroundColor: formatColorOrderType(order),
              }}
            >
              {time}
            </Typography>
          )}
        </div>
      );
    }
    case ORDER_REMINDER: {
      const { order } = content;
      const time =
        order &&
        (order.orderTime
          ? moment()
              .hours(parseInt(order.orderTime.slice(0, 2), 10))
              .minutes(parseInt(order.orderTime.slice(2), 10))
          : moment.unix(order.executedAt));

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Avatar
              style={{
                backgroundColor: formatColorOrderType(order),
                color: "#fff",
                marginRight: 15,
              }}
            >
              {formatIconOrderType(order)}
            </Avatar>
            Bestellung {formatStringOrderType(order)}
          </div>
          <Typography
            variant="title"
            style={{
              padding: 10,
              color: "#fff",
              backgroundColor: formatColorOrderType(order),
            }}
          >
            {time.format("HH:mm")}
          </Typography>
        </div>
      );
    }
    case CHECKIN: {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Avatar
              style={{
                backgroundColor: amber[600],
                color: "#fff",
                marginRight: 15,
              }}
            >
              <CheckInIcon />
            </Avatar>
            <strong>Check-In</strong>
          </div>
        </div>
      );
    }
    case BATTERY_WARNING: {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "red",
                color: "#fff",
                marginRight: 15,
              }}
            >
              <BatteryAlert />
            </Avatar>
            <strong>Kabel anschließen</strong>
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

const getDialogContent = (type, content, isHobbyCook) => {
  switch (type) {
    case NEW_ORDER: {
      const { order } = content;
      const { venueSum } = calculateSumForVenue({
        items: order.items,
        orderLocation: order.orderLocation,
        orderBenefits: order.orderBenefits,
        baseVat: order.baseVat,
        sumBeforeEverything: order.sumBeforeEverything,
        sumBenefit: order.sumBenefit,
        sumBenefits: order.sumBenefits,
        sumBenefitSum: order.sumBenefitSum,
        tipSum: order.tipSum,
      });

      const orderTotal = venueSum - order.feeSum;
      const time = order.orderTime
        ? moment()
            .hours(parseInt(order.orderTime.slice(0, 2), 10))
            .minutes(parseInt(order.orderTime.slice(2), 10))
        : moment.unix(order.executedAt);
      if (isHobbyCook) {
        return (
          <Grid
            container
            xs={12}
            sm={12}
            spacing={16}
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item container xs={6}>
              <CardContent
                style={{
                  padding: 8,
                  "&:last-child": { paddingBottom: 0, paddingLeft: 0 },
                }}
              >
                {order.orderForDate && (
                  <Typography variant="body2">
                    <b>Bestellt für: </b>
                    {moment.unix(order.orderForDate).format("DD.MM.YYYY")}
                  </Typography>
                )}
                {order.pickupFromTime && order.pickupToTime && (
                  <Typography variant="body2">
                    <b>Abholung: </b>
                    <span
                      style={{
                        "white-space": "nowrap",
                      }}
                    >
                      {!order.orderTime &&
                        order.pickupFromTime &&
                        order.pickupToTime &&
                        `${order.pickupFromTime} - ${order.pickupToTime}`}
                      {order.orderTime &&
                        `${time.format("HH:mm")} - ${time
                          .add(15, "minutes")
                          .format("HH:mm")}`}
                    </span>
                  </Typography>
                )}
                <Typography variant="body2">
                  <b>Gast: </b>
                  {order.userName}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item justify="flex-end" alignItems="center" container xs={6}>
              <CardContent
                style={{
                  padding: 8,
                  "&:last-child": { paddingBottom: 0, paddingLeft: 0 },
                }}
              >
                <Typography
                  style={{
                    marginRight: "1vw",
                    fontWeight: 600,
                    "@media (max-width: 560px)": {
                      fontSize: "3.2rem",
                    },
                  }}
                  variant="h2"
                >
                  {order.orderNumber}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        );
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" style={{ color: "#333" }}>
              {order.orderNumber}
            </Typography>
          </div>
          <div style={{ padding: "0 10px", textAlign: "center" }}>
            <Typography variant="h5">{`Gast: ${order.userName}`}</Typography>
          </div>
          <div>
            <Typography variant="h4" style={{ color: "#333" }}>
              <FormattedNumber
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
                value={orderTotal / 100}
              />
            </Typography>
          </div>
        </div>
      );
    }
    case ORDER_REMINDER: {
      const { order } = content;
      const { venueSum } = calculateSumForVenue({
        items: order.items,
        orderLocation: order.orderLocation,
        orderBenefits: order.orderBenefits,
        baseVat: order.baseVat,
        sumBeforeEverything: order.sumBeforeEverything,
        sumBenefit: order.sumBenefit,
        sumBenefits: order.sumBenefits,
        sumBenefitSum: order.sumBenefitSum,
        tipSum: order.tipSum,
      });

      const orderTotal = venueSum - order.feeSum;

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="display2" style={{ color: "#333" }}>
              {order.orderNumber}
            </Typography>
          </div>
          <div style={{ padding: "0 10px", textAlign: "center" }}>
            <Typography variant="headline">
              {`Gast: ${order.userName}`}
            </Typography>
          </div>
          <div>
            <Typography variant="display1" style={{ color: "#333" }}>
              <FormattedNumber
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
                value={orderTotal / 100}
              />
            </Typography>
          </div>
        </div>
      );
    }
    case CHECKIN: {
      const { order } = content;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: "10px 0" }}>
            <Typography variant="h4" style={{ color: "#333" }}>
              {order.orderNumber}
            </Typography>
          </div>
          <div style={{ padding: "10px 0" }}>
            <Typography variant="h4" style={{ color: "#333" }}>
              {`Tisch: ${order.table || `${order.tableId} (nicht codiert)`}`}
            </Typography>
          </div>
          <div style={{ padding: "10px 0" }}>
            <Typography variant="h5">{`Gast: ${order.userName}`}</Typography>
          </div>
        </div>
      );
    }
    case BATTERY_WARNING: {
      return (
        <div>
          <Typography variant="h5">
            Bitte schließe das Stromkabel wieder an das Tablet an
          </Typography>
        </div>
      );
    }
    default:
      return null;
  }
};

const Dialog = ({
  type,
  content,
  action,
  startReminder,
  stopReminder,
  contentContainerRef,
  processing,
  isHobbyCook,
  classes,
}) => {
  useEffect(() => {
    startReminder(type);
    return () => {
      stopReminder();
    };
  }, [type]);

  useEffect(() => {
    if (
      contentContainerRef &&
      contentContainerRef.current &&
      contentContainerRef.current.scrollTo
    ) {
      contentContainerRef.current.scrollTo(0, 0);
    }
  }, [contentContainerRef ? contentContainerRef.current : null]);

  return (
    <MDialog
      classes={{
        paper: classes.containerModal,
      }}
      open
    >
      <DialogTitle>{getDialogTitle(type, content, isHobbyCook)}</DialogTitle>
      <DialogContent>
        {getDialogContent(type, content, isHobbyCook)}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          style={{ width: "50%" }}
          size="large"
          variant="contained"
          color="primary"
          onClick={action}
          disabled={processing}
        >
          {processing ? (
            <CircularProgress size={20} style={{ color: "#fff" }} />
          ) : (
            "Bestätigen"
          )}
        </Button>
      </DialogActions>
    </MDialog>
  );
};

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  startReminder: PropTypes.func.isRequired,
  stopReminder: PropTypes.func.isRequired,
  contentContainerRef: PropTypes.object,
  processing: PropTypes.bool,
  isHobbyCook: PropTypes.bool,
};

Dialog.defaultProps = {
  contentContainerRef: null,
  processing: false,
  isHobbyCook: false,
};

export default withStyles(useStyles)(Dialog);
