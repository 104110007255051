export default {
  de: {
    VENUE: {
      ADD_ITEM_SUCCESS: "Produkt wurde erfolgreich hinzugefügt",
      ADD_ITEM_ERROR: "Es ist leider ein Fehler aufgetreten",
      UPDATE_ITEM_SUCCESS: "Product wurde erfolgreich bearbeitet",
      UPDATE_ITEM_ERROR: "Beim Bearbeiten ist leider ein Fehler aufgetreten",
      ADD_SECTION_SUCCESS: "Abschnitt wurde erfolgreich hinzugefügt",
      ADD_SECTION_ERROR: "Es ist leider ein Fehler aufgetreten",
      UPDATE_SECTION_SUCCESS: "Abschnitt wurde erfolgreich aktualisiert",
      UPDATE_SECTION_ERROR: "Beim Bearbeiten ist leider ein Fehler aufgetreten",
      UPDATE_SECTION_ORDER_SUCCESS: "Abschnitt wurde erfolgreich gelöscht",
      UPDATE_SECTION_ORDER_ERROR:
        "Beim Löschen ist leider ein Fehler aufgetreten",
      DELETE_ITEM_SUCCESS: "Product wurde erfolgreich gelöscht",
      DELETE_ITEM_ERROR: "Beim Löschen ist leider ein Fehler aufgetreten",
      SUCCESS_ADD_EXISTING_OPTION_TO_ITEM:
        "Option wurde erfolgreich hinzugefügt",
      ERROR_ADD_EXISTING_OPTION_TO_ITEM: "Es ist leider ein Fehler aufgetreten",
      SUCCESS_ADD_EXISTING_OPTION_TO_OPTION:
        "Option wurde erfolgreich hinzugefügt",
      ERROR_ADD_EXISTING_OPTION_TO_OPTION:
        "Es ist leider ein Fehler aufgetreten",
    },
    IMAGE_VIDEO: {
      UPLOAD_ERROR: "Beim Hochladen ist leider ein Fehler aufgetreten",
      UPLOAD_SUCCESS: "Die Bilder und Daten wurden erfolgreich aktualisiert",
    },
  },
};
