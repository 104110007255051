/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import {
  VENUE_MODE_OTC,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants-js/venue-types";
import { REHYDRATE } from "redux-persist";

export const initialState = {
  requests: {
    retrieveOrders: {
      processing: false,
      error: null,
    },
    markOrderAsDone: [],
    reprintOrder: {
      processing: false,
      error: null,
    },
    acknowledgeOrder: {
      processing: false,
      error: null,
    },
  },
  orders: null,
  checkIns: [],
  checkOrder: {
    isOrdered: null,
    error: null,
  },
};

function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case types.orders.RETRIEVE_ORDERS.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveOrders.processing = true;
        newState.requests.retrieveOrders.error = null;
      });
    case types.orders.RETRIEVE_ORDERS.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveOrders.processing = false;
        newState.orders = action.orders;
      });
    case types.orders.RETRIEVE_ORDERS.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveOrders.processing = false;
        newState.requests.retrieveOrders.error = action.error;
      });
    case types.orders.MARK_ORDER_AS_DONE.REQUEST:
      return produce(state, (newState) => {
        const order = newState.requests.markOrderAsDone.find(
          (orderToBeMarkedAsDone) =>
            orderToBeMarkedAsDone.orderId === action.orderId
        );
        if (!order) {
          const { orderId, venueId, doneAt, userId, usedMode, retries, ttl } =
            action;
          newState.requests.markOrderAsDone.push({
            orderId,
            venueId,
            userId,
            usedMode,
            doneAt,
            ttl,
            retries,
          });
        } else {
          order.retries = action.retries;
        }
      });
    case types.orders.MARK_ORDER_AS_DONE.SUCCESS:
      return produce(state, (newState) => {
        const doneOrder = newState.orders.find(
          (order) => order.orderId === action.orderId
        );
        doneOrder.doneAt = action.doneAt;
        if (action.usedMode === VENUE_MODE_OTC) {
          doneOrder.lastPushNotification = action.lastPushNotification;
        }
        newState.requests.markOrderAsDone =
          newState.requests.markOrderAsDone.filter(
            (order) => order.orderId !== action.orderId
          );
      });
    case types.orders.REPRINT_ORDER.REQUEST:
      return produce(state, (newState) => {
        newState.requests.reprintOrder.processing = true;
        newState.requests.reprintOrder.error = null;
      });
    case types.orders.REPRINT_ORDER.SUCCESS:
      return produce(state, (newState) => {
        const printedOrder = newState.orders.find(
          (order) => order.orderId === action.orderId
        );
        printedOrder.lastPrinted = action.lastPrinted;
        newState.requests.reprintOrder.processing = false;
      });
    case types.orders.REPRINT_ORDER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.reprintOrder.processing = false;
        newState.requests.reprintOrder.error = action.error;
      });
    case types.orders.ACKNOWLEDGE_ORDER.REQUEST:
      return produce(state, (newState) => {
        newState.requests.acknowledgeOrder.processing = true;
        newState.requests.acknowledgeOrder.error = null;
      });
    case types.orders.ACKNOWLEDGE_ORDER.SUCCESS:
      return produce(state, (newState) => {
        const acknowledgedOrder = newState.orders.find(
          (order) => order.orderId === action.orderId
        );
        acknowledgedOrder.acknowledgedAt = action.acknowledgedAt;
        newState.requests.acknowledgeOrder.processing = false;
      });
    case types.orders.ACKNOWLEDGE_ORDER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.acknowledgeOrder.processing = false;
        newState.requests.acknowledgeOrder.error = action.error;
      });
    case types.orders.UPDATE_ORDERS:
      return produce(state, (newState) => {
        if (!newState.orders) {
          newState.orders = [];
        }
        newState.orders.push(action.order);
        newState.requests.acknowledgeOrder.processing = false;
      });
    case types.orders.UPDATE_ORDER_TOGO:
      return produce(state, (newState) => {
        const updatedOrder = newState.orders.find(
          (order) => order.orderId === action.orderId
        );
        updatedOrder.orderLocation = VENUE_LOCATION_TO_GO;
      });
    case types.orders.PURGE_ORDER_REQUEST:
      return produce(state, (newState) => {
        newState.requests.markOrderAsDone =
          newState.requests.markOrderAsDone.filter(
            (orderReq) => orderReq.orderId !== action.orderId
          );
      });
    case types.orders.ORDER.CHECKIN:
      return produce(state, (newState) => {
        // save order check-in information
        const order = newState.orders.find((o) => o.orderId === action.orderId);
        order.table = action.table;
        order.tableId = action.tableId;
        // add order to the checkIns array
        newState.checkIns.push(action.orderId);
      });
    case types.uiState.DIALOGS.CHECKIN.DISMISS:
      return produce(state, (newState) => {
        const checkInOrderIdx = newState.checkIns.findIndex(
          (orderId) => orderId === action.orderId
        );
        newState.checkIns.splice(checkInOrderIdx, 1);
      });
    case types.orders.PURGE_ALL_PENDING_ORDER_REQUESTS:
      return produce(state, (newState) => {
        newState.requests.markOrderAsDone = [];
      });
    case types.orders.ORDER.CHECK_ORDERED_SUCCESS:
      return produce(state, (newState) => {
        newState.checkOrder.isOrdered = action.isOrdered;
      });
    case types.orders.ORDER.CHECK_ORDERED_FAILURE:
      return produce(state, (newState) => {
        newState.checkOrder.error = action.error;
      });
    case REHYDRATE:
      return produce(state, (newState) => {
        const savedState =
          action.payload &&
          action.payload.orders &&
          action.payload.orders.requests &&
          action.payload.orders.requests.markOrderAsDone;
        if (savedState) {
          newState.requests.markOrderAsDone = savedState;
        }
      });

    default:
      return state;
  }
}

export default ordersReducer;
