import { combineReducers } from "redux";
import auth, { initialState as authState } from "./auth";
import sales, { initialState as salesState } from "./sales";
import venue, { initialState as venueStore } from "./venue";
import activityLog, { initialState as activityLogStore } from "./activityLog";
import orders, { initialState as ordersStore } from "./orders";
import notifications from "./notifications";
import uiState, { initialState as uiStateStore } from "./uiState";
import orderReminders, {
  initialState as orderRemindersStore,
} from "./orderReminders";
import bridge, { initialState as bridgeState } from "./bridge";
import status, { initialState as statusState } from "./status";
import marketing, { initialState as marketingState } from "./marketing";
import tables, { initialState as tablesState } from "./tables";

export const initialState = {
  auth: authState,
  sales: salesState,
  venue: venueStore,
  activityLog: activityLogStore,
  orderReminders: orderRemindersStore,
  orders: ordersStore,
  uiState: uiStateStore,
  bridge: bridgeState,
  status: statusState,
  marketing: marketingState,
  tables: tablesState,
};

const rootReducer = combineReducers({
  auth,
  sales,
  venue,
  activityLog,
  orderReminders,
  orders,
  uiState,
  bridge,
  status,
  marketing,
  notifications,
  tables,
});

export default rootReducer;
