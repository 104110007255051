import React from "react";
import Availability from "./containers/Availability";
import Sales from "./containers/Sales";
import ActivityLog from "./containers/ActivityLog";
import Login from "./containers/Login";
import Unauthorised from "./containers/Unauthorised";
import Error from "./containers/Error";
import OrdersNew from "./containers/OrdersNew";
import Home from "./components/Home";
import Face from "@material-ui/icons/Face";
import RestaurantMenu from "@material-ui/icons/RestaurantMenu";
import HomeIcon from "@material-ui/icons/Home";
import List from "@material-ui/icons/List";
import Receipt from "@material-ui/icons/Receipt";
import History from "@material-ui/icons/History";
import Assessment from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import { Permission } from "@orda/shared-constants/backoffice/permissions";
import Campaigns from "./components/Campaigns";
import CreateCampaign from "./components/CreateCampaign";
import Settings from "./components/Settings";
import { CropFree } from "@material-ui/icons";
import Tables from "./components/Tables/QRCodes";

interface RouteConfig {
  public: boolean;
  icon?: any;
  url: string;
  label?: string;
  component: any;
  permissions: string[];
  posOnly?: boolean;
}

interface Routes {
  [key: string]: RouteConfig;
}

const routes: Routes = {
  home: {
    public: false,
    icon: <HomeIcon />,
    url: "/",
    label: "home",
    component: Home,
    permissions: [],
  },
  // availability: {
  //   public: false,
  //   icon: <RestaurantMenu />,
  //   label: "availability",
  //   url: "/availability",
  //   component: Availability,
  //   permissions: [Permission.EditMenuAvailability],
  // },
  menu: {
    public: false,
    icon: <RestaurantMenu />,
    label: "menu",
    url: "/menu",
    component: Availability,
    permissions: [Permission.EditMenuAvailability],
  },
  orders: {
    public: false,
    icon: <List />,
    component: OrdersNew,
    label: "orders",
    url: "/orders",
    permissions: [],
  },
  sales: {
    public: false,
    icon: <Receipt />,
    label: "sales",
    url: "/sales",
    component: Sales,
    permissions: [Permission.AccessSales],
  },
  activityLog: {
    public: false,
    icon: <History />,
    label: "activityLog",
    url: "/activity-log",
    component: ActivityLog,
    permissions: [Permission.AccessActivityLog],
  },
  login: {
    public: true,
    icon: <Face />,
    label: "login",
    url: "/login",
    component: Login,
    permissions: [],
  },
  unauthorized: {
    public: true,
    icon: null,
    label: "",
    url: "/unauthorised",
    component: Unauthorised,
    permissions: [],
  },
  error: {
    public: true,
    icon: null,
    label: "",
    url: "/error",
    component: Error,
    permissions: [],
  },
  campaigns: {
    public: false,
    icon: <Assessment />,
    label: "campaigns",
    url: "/campaigns",
    component: Campaigns,
    permissions: [Permission.AccessCampaigns],
  },
  createCampaign: {
    public: false,
    icon: null,
    label: "campaigns",
    url: "/create-campaign",
    component: CreateCampaign,
    permissions: [Permission.AccessCampaigns],
  },
  settings: {
    public: false,
    icon: <SettingsIcon />,
    label: "settings",
    url: "/settings",
    component: Settings,
    permissions: [Permission.AccessOrders],
    posOnly: true,
  },
  qrCodes: {
    public: false,
    icon: <CropFree />,
    label: "QR-Codes",
    url: "/qr-codes",
    component: Tables,
    permissions: [Permission.EditMenu],
  },
};

export default routes;
