import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Typography,
  Button,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import { Modifier } from "@orda/shared-constants/benefits";
import { useTranslation } from "react-i18next";
import { TimePicker } from "material-ui-pickers";
import { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import CampaignPreview from "./CampaignPreview";
import { retrieveVenue } from "../redux/actions/venue";
import Loading from "./Loading";
import { createCampaignRequest } from "../redux/actions/marketing";
import { Redirect } from "react-router-dom";
import routes from "../routes";
import { logEvent } from "../lib/analytics";
import {
  MARKETING_CREATE_CAMPAIGN,
  SCREEN_VIEW,
} from "../lib/analytics/events";

const styles = {
  container: {
    padding: "1rem",
  },
  timePickerTextField: {
    width: 100,
  },
  bottomMargin: {
    marginBottom: 10,
  },
  noteTextField: {
    marginBottom: 20,
  },
  allDayCheckboxContainer: {
    display: "flex",
  },
  timeSpanContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    padding: "20px 50px",
  },
  previewContainer: {
    display: "flex",
    justifyContent: "center",
  },
  spinner: {
    color: "#fff",
  },
};

interface CreateCampaignState {
  name?: string;
  allDay: boolean;
  fromTime: Moment | null;
  toTime: Moment | null;
  amount?: string;
  modifier: Modifier;
  note?: string;
}

interface CreateCampaignProps {
  classes: any;
}

const CreateCampaign: React.FC<CreateCampaignProps> = ({
  classes,
}): ReturnType<React.FC<CreateCampaignProps>> => {
  const [campaignState, setCampaignState] = useState<CreateCampaignState>({
    name: "",
    allDay: true,
    modifier: Modifier.Multiply,
    fromTime: null,
    toTime: null,
    amount: "",
    note: "",
  });
  const { t } = useTranslation("createCampaign");
  const dispatch = useDispatch();
  const currentVenueId = useSelector(
    (state: any) => state.uiState.currentVenueId
  );
  const retrievingVenue = useSelector(
    (state: any) => state.venue.requests.retrieveVenue.processing
  );
  const venue = useSelector((state: any) => state.venue.venue);
  const { processing, success, error } = useSelector(
    (state: any) => state.marketing.requests.createCampaign
  );

  useEffect(() => {
    dispatch(retrieveVenue(currentVenueId));
    logEvent(SCREEN_VIEW, {
      id: "CREATE_CAMPAIGN",
    });
  }, []);

  const handleChange =
    (name: keyof CreateCampaignState): any =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      switch (name) {
        case "allDay":
          setCampaignState({
            ...campaignState,
            [name]: event.target.checked,
            fromTime: null,
            toTime: null,
          });
          break;
        default:
          setCampaignState({ ...campaignState, [name]: event.target.value });
          break;
      }
    };

  const handleDateChange =
    (name: keyof CreateCampaignState): any =>
    (date: any | null) => {
      setCampaignState({ ...campaignState, [name]: date, allDay: false });
    };

  const handleSubmit = (): void => {
    let timeSlot;
    if (campaignState.fromTime && campaignState.toTime) {
      timeSlot = {
        startHour: campaignState.fromTime.hour(),
        startMinute: campaignState.fromTime.minute(),
        endHour: campaignState.toTime.hour(),
        endMinute: campaignState.toTime.minute(),
      };
    }

    // create campaign request
    logEvent(MARKETING_CREATE_CAMPAIGN);

    dispatch(
      createCampaignRequest(
        currentVenueId,
        campaignState.allDay,
        campaignState.modifier,
        campaignState.name,
        timeSlot,
        campaignState.amount
          ? parseInt(campaignState.amount, 10) / 100
          : undefined,
        campaignState.note
      )
    );
  };

  if (retrievingVenue || !venue) {
    return <Loading />;
  }

  if (success && !error) {
    return <Redirect to={routes.campaigns.url} />;
  }

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="dense"
            className={classes.bottomMargin}
            value={campaignState.name}
            variant="outlined"
            name="name"
            label={t("nameLabel")}
            placeholder={t("namePlaceholder")}
            onChange={handleChange("name")}
          />
          <Grid container className={classes.bottomMargin}>
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.allDayCheckboxContainer}
            >
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={campaignState.allDay} />
                }
                label={t("allDayLabel")}
                onChange={handleChange("allDay")}
              />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.timeSpanContainer}>
              <TimePicker
                DialogProps={{
                  fullScreen: true,
                }}
                margin="dense"
                variant="outlined"
                className={classes.timePickerTextField}
                onChange={handleDateChange("fromTime")}
                value={campaignState.fromTime}
                ampm={false}
                label={t("fromTimeLabel")}
                placeholder={t("fromTimePlaceholder")}
                cancelLabel={t("timePickerCancelLabel")}
                okLabel={t("timePickerOkLabel")}
              />
              <Typography variant="body2">—</Typography>
              <TimePicker
                DialogProps={{
                  fullScreen: true,
                }}
                margin="dense"
                variant="outlined"
                className={classes.timePickerTextField}
                onChange={handleDateChange("toTime")}
                value={campaignState.toTime}
                ampm={false}
                label={t("toTimeLabel")}
                placeholder={t("toTimePlaceholder")}
                cancelLabel={t("timePickerCancelLabel")}
                okLabel={t("timePickerOkLabel")}
              />
            </Grid>
          </Grid>
          <TextField
            name="amount"
            variant="outlined"
            margin="dense"
            label={t("amountLabel")}
            className={classes.bottomMargin}
            value={campaignState.amount}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            placeholder={t("amountPlaceholder")}
            onChange={handleChange("amount")}
          />
        </Grid>
        <Grid item xs={6} className={classes.previewContainer}>
          <CampaignPreview
            name={campaignState.name}
            amount={campaignState.amount}
            // @ts-ignore
            modifier={campaignState.modifier}
            logo={venue.theme.logo}
            allDay={campaignState.allDay}
            fromTime={campaignState.fromTime}
            toTime={campaignState.toTime}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            margin="dense"
            variant="outlined"
            label={t("noteLabel")}
            placeholder={t("notePlaceholder")}
            className={classes.noteTextField}
            type="number"
            rowsMax={5}
            rows={5}
            InputLabelProps={{
              shrink: true,
            }}
            value={campaignState.note}
            onChange={handleChange("note")}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography color="textSecondary" variant="body2">
            {t("explanation")}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.buttonContainer}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
            disabled={processing}
          >
            {processing ? (
              <CircularProgress className={classes.spinner} />
            ) : (
              t("createButtonLabel")
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

CreateCampaign.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateCampaign);
