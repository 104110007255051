import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import QrScanner from "qr-scanner";
import {
  setTableNumber,
  updateTableNumber,
} from "../../../redux/actions/tables";
import Loading from "../../Loading";
import NumberSelector from "./NumberSelector";
import { THEME } from "../../../util/theme";
import classNames from "classnames";
import _ from "lodash";
import { CHECKIN_URL_PATTERN } from "../../../config";
import { vibrate } from "../../../util/helpers";

const styles: any = {
  container: {
    padding: "1rem",
  },
  video: {
    height: "30vh",
    width: "100%",
  },
  controlPanel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    fontSize: "1em",
    fontWeight: 600,
    textAlign: "center",
  },
  errorMessage: {
    color: THEME.ACCENT_RED,
  },
  successMessage: {
    color: THEME.MAIN_GREEN,
  },
};

interface QRCodeScanProps {
  classes: any;
  visible: boolean;
  setVisible: any;
}

const QRCodeScan: React.FC<QRCodeScanProps> = ({
  classes,
  visible,
  setVisible,
}): ReturnType<React.FC<QRCodeScanProps>> => {
  const { t } = useTranslation("qrCodeScan");
  const dispatch = useDispatch();

  const currentVenueId = useSelector(
    (state: any) => state.uiState.currentVenueId
  );
  const {
    tables,
    updateTableNumberRequest: {
      success: updateTableNumberSuccess,
      error: updateTableNumberError,
      processing: updateTableNumberProcessing,
    },
    currentTableNumber,
  } = useSelector((state: any) => state.tables);

  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);

  const [qrScanReady, setQrScanReady] = useState<boolean>(true);
  const [scannedResult, setScannedResult] = useState<any>({
    data: "",
    parsedData: "",
    error: null,
  });

  const controlPaneContents = useMemo(() => {
    if (!qrScanReady || updateTableNumberProcessing) {
      return <Loading />;
    }
    return <NumberSelector />;
  }, [updateTableNumberProcessing]);
  const errorMessage = useMemo(() => {
    if (!qrScanReady) {
      return t("cameraAccessError");
    }
    if (scannedResult.error) {
      return t(scannedResult.error);
    }
    if (!currentTableNumber && scannedResult.parsedData) {
      return t("numberNotValid");
    }
    if (updateTableNumberError) {
      return t("qrCodeSaveError");
    }
    return "";
  }, [qrScanReady, scannedResult, updateTableNumberError, currentTableNumber]);

  const finishScan = useCallback(() => {
    setVisible(!visible);
    scanner?.current?.stop();
  }, [setVisible, scanner?.current]);
  useEffect(() => {
    if (updateTableNumberSuccess) {
      finishScan();
    }
  }, [updateTableNumberSuccess]);

  const parseScannedResult = (result: string): string | null => {
    const match = result.match(CHECKIN_URL_PATTERN);
    const code = match && match[1];
    return code;
  };
  const onScanSuccess = (result: QrScanner.ScanResult): void => {
    const code = parseScannedResult(result?.data);
    const table = _.find(tables, { id: code });
    if (table) {
      dispatch(setTableNumber(+table.tableNumber));
    }
    setScannedResult((prev: any) => {
      if (prev?.parsedData !== code) {
        vibrate(200);
      }
      return { data: result?.data, parsedData: code, error: null };
    });
  };
  const mountQRScanner = useCallback(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        // environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        highlightCodeOutline: true,
        highlightScanRegion: true,
      });

      const startScanner = async (): Promise<void> => {
        try {
          await scanner?.current?.start();
          setQrScanReady(true);
        } catch (error) {
          setQrScanReady(false);
        }
      };
      startScanner();
    }
  }, []);
  const handleConfirmScan = useCallback(() => {
    const table = _.find(tables, { tableNumber: `${currentTableNumber}` });
    if (table && table.id !== scannedResult.parsedData) {
      dispatch(setTableNumber(+table.tableNumber));
      setScannedResult((previous: any) => ({
        ...previous,
        error: "numberAlreadyExist",
      }));
    } else {
      dispatch(updateTableNumber(currentVenueId, scannedResult.data));
    }
  }, [currentTableNumber, scannedResult]);

  return (
    <Dialog
      open={visible}
      onClose={finishScan}
      fullWidth
      onRendered={() => mountQRScanner()}
    >
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        {/* eslint-disable jsx-a11y/media-has-caption */}
        <video ref={videoEl} className={classes.video}></video>
        {scannedResult && scannedResult.parsedData && (
          <Typography
            className={classNames(classes.message, classes.successMessage)}
          >
            {t("qrCodeScanResult", {
              data: scannedResult.parsedData,
            })}
          </Typography>
        )}
        <Typography
          className={classNames(classes.message, classes.errorMessage)}
        >
          {errorMessage}
        </Typography>
        {updateTableNumberSuccess && (
          <Typography
            className={classNames(classes.message, classes.successMessage)}
          >
            {t("qrCodeScanSuccess")}
          </Typography>
        )}
        <div className={classes.controlPanel}>{controlPaneContents}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setVisible(!visible)} color="primary">
          {t("cancel")}
        </Button>
        <Button
          onClick={() => handleConfirmScan()}
          color="primary"
          disabled={!scannedResult.data || !currentTableNumber}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

QRCodeScan.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.any.isRequired,
};

export default withStyles(styles)(QRCodeScan);
