import React from "react";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { green, grey, red } from "@material-ui/core/colors";

const styles = {
  switchBase: {
    color: red[500],
    "& + $bar": {
      backgroundColor: red[300],
    },
  },
  bar: {},
  checked: {
    color: green[50],
    "&$checked": {
      color: green[500],
      "& + $bar": {
        backgroundColor: green[500],
      },
    },
  },
  disabled: {
    color: grey[50],
    "&$checked": {
      color: grey[500],
      "& + $bar": {
        backgroundColor: grey[500],
      },
    },
  },
};

const CustomToggle = ({ classes, toggled, disabled, onToggle }) => (
  <Switch
    checked={toggled}
    disabled={disabled}
    classes={{
      switchBase: classes.switchBase,
      checked: classes.checked,
      bar: classes.bar,
      disabled: classes.disabled,
    }}
    onChange={onToggle}
  />
);

CustomToggle.defaultProps = {
  disabled: false,
};

CustomToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  toggled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomToggle);
