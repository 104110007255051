import React from "react";
import PropTypes from "prop-types";

const TimelineEntry = ({ message, time, icon }) => (
  <div className="timeline-entry">
    <div className="timeline-img">{icon}</div>
    <div className="timeline-content">
      <p>{message}</p>
      <span className="timeline-time">{time}</span>
    </div>
  </div>
);

TimelineEntry.propTypes = {
  message: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

export default TimelineEntry;
