export default {
  SET_TABLE_NUMBER: "SET_TABLE_NUMBER",
  UPDATE_TABLE_NUMBER: {
    REQUEST: "UPDATE_TABLE_NUMBER.REQUEST",
    SUCCESS: "UPDATE_TABLE_NUMBER.SUCCESS",
    FAILURE: "UPDATE_TABLE_NUMBER.FAILURE",
    RESET: "UPDATE_TABLE_NUMBER.RESET",
  },
  GET_VENUE_TABLES: {
    REQUEST: "GET_VENUE_TABLES.REQUEST",
    SUCCESS: "GET_VENUE_TABLES.SUCCESS",
    FAILURE: "GET_VENUE_TABLES.FAILURE",
  },
  DELETE_VENUE_TABLE: {
    REQUEST: "DELETE_VENUE_TABLE.REQUEST",
    SUCCESS: "DELETE_VENUE_TABLE.SUCCESS",
    FAILURE: "DELETE_VENUE_TABLE.FAILURE",
  },
};
