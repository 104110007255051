import * as types from "../types";

export const login = (email, password) => ({
  type: types.auth.LOGIN.REQUEST,
  email,
  password,
});

export const loginSuccess = () => ({
  type: types.auth.LOGIN.SUCCESS,
});

export const loginFailure = (error) => ({
  type: types.auth.LOGIN.FAILURE,
  error,
});

export const logout = () => ({
  type: types.auth.LOGOUT.REQUEST,
});

export const logoutSuccess = () => {
  localStorage.removeItem("lastVenueId");
  return {
    type: types.auth.LOGOUT.SUCCESS,
  };
};

export const logoutFailure = (error) => ({
  type: types.auth.LOGOUT.FAILURE,
  error,
});

export const syncFirebaseUser = (user) => ({
  type: types.auth.SYNC_FIREBASE_USER,
  user,
});

export const retrieveUserVenues = () => ({
  type: types.auth.RETRIEVE_USER_VENUES.REQUEST,
});

export const retrieveUserVenuesSuccess = (venues) => ({
  type: types.auth.RETRIEVE_USER_VENUES.SUCCESS,
  venues,
});

export const retrieveUserVenuesFailure = (error) => ({
  type: types.auth.RETRIEVE_USER_VENUES.FAILURE,
  error,
});

export const unblock = () => ({
  type: types.auth.UNBLOCK,
});
