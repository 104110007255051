import {
  select,
  takeLatest,
  call,
  put,
  all,
  delay,
  fork,
  take,
  cancel,
} from "redux-saga/effects";
import * as types from "../types";
import {
  createVenueDataAPI,
  createUpdateSectionAvailabilityAPI,
  createUpdateItemAvailabilityAPI,
  createUpdateOptionAvailabilityAPI,
  createVenueGlobalStatusAPI,
  createVenueTrafficStatusAPI,
  createMenuItemAPI,
  addItemToSectionAPI,
  uploadItemFileAPI,
  updateMenuItemAPI,
  deleteMenuItemAPI,
  createDeliveryOrdersToggleAPI,
  updateItemOptionAPI,
  updateOptionAPI,
  updateMenuSectionAPI,
  createMenuSectionAPI,
  updateMenuSectionOrderAPI,
} from "../../lib/venue";
import {
  retrieveVenueSuccess,
  retrieveVenueFailure,
  toggleSectionAvailabilitySuccess,
  toggleSectionAvailabilityFailure,
  toggleOptionAvailabilitySuccess,
  toggleOptionAvailabilityFailure,
  toggleItemAvailabilitySuccess,
  toggleItemAvailabilityFailure,
  toggleVenueAvailabilitySuccess,
  toggleVenueAvailabilityFailure,
  updateVenueTrafficSuccess,
  updateVenueTrafficFailure,
  updateVenueTraffic,
  enableAutomaticVenueTraffic,
  createItemFailure,
  createItemSuccess,
  updateItemFailure,
  updateItemSuccess,
  deleteItemSuccess,
  deleteItemFailure,
  toggleDeliveryOrdersStatusSuccess,
  toggleDeliveryOrdersStatusFailure,
  addExistingOptionToItemFailure,
  addExistingOptionToItemSuccess,
  addExistingOptionToOptionSuccess,
  addExistingOptionToOptionFailure,
  updateSectionSuccess,
  updateSectionFailure,
  createSectionFailure,
  createSectionSuccess,
  updateSectionOrderSuccess,
  updateSectionOrderFailure,
} from "../actions/venue";
import { setNotification } from "../actions/notifications";
import VENUE_TRAFFIC_STATUS from "@orda/shared-constants-js/venue-traffic-status";
import moment from "moment";
import { StatusEnum } from "../../constants";
import notificationStrings from "../../strings/notificationStrings";
import shortid from "shortid";

const VENUE_TRAFFIC_TIME_THRESHHOLD = 120;

function* retrieveVenueSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId } = action;

    const response = yield call(fetch, createVenueDataAPI(venueId), {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    const data = yield call([response, response.json]);
    yield put(retrieveVenueSuccess(data));
  } catch (error) {
    yield put(retrieveVenueFailure(error));
  }
}

function* toggleVenueAvailabilitySaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, status } = action;

    yield call(fetch, createVenueGlobalStatusAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        status: !status,
      }),
    });
    yield put(toggleVenueAvailabilitySuccess(venueId, status));
  } catch (error) {
    yield put(toggleVenueAvailabilityFailure(action.venueId, error));
  }
}

function* updateVenueTrafficSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, status } = action;

    const response = yield call(fetch, createVenueTrafficStatusAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        status,
      }),
    });
    const { lastUpdate } = yield call([response, response.json]);

    yield put(updateVenueTrafficSuccess(venueId, status, lastUpdate));
  } catch (error) {
    yield put(updateVenueTrafficFailure(action.venueId, error));
  }
}

function* toggleSectionAvailabilitySaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, sectionId, available } = action;

    yield call(fetch, createUpdateSectionAvailabilityAPI(venueId, sectionId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        available,
      }),
    });
    yield put(toggleSectionAvailabilitySuccess(venueId, sectionId, available));
  } catch (error) {
    yield put(toggleSectionAvailabilityFailure(action.venueId, error));
  }
}

function* toggleOptionAvailabilitySaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, optionId, available } = action;

    yield call(fetch, createUpdateOptionAvailabilityAPI(venueId, optionId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        available,
      }),
    });
    yield put(toggleOptionAvailabilitySuccess(venueId, optionId, available));
  } catch (error) {
    yield put(toggleOptionAvailabilityFailure(action.venueId, error));
  }
}

function* toggleItemAvailabilitySaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, itemId, available } = action;

    yield call(fetch, createUpdateItemAvailabilityAPI(venueId, itemId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        available,
      }),
    });
    yield put(toggleItemAvailabilitySuccess(venueId, itemId, available));
  } catch (error) {
    yield put(toggleItemAvailabilityFailure(action.venueId, error));
  }
}

function* automaticVenueTrafficSaga(action) {
  const { venueId } = action;

  while (true) {
    const time = moment();

    const initialTime = moment()
      .startOf("day")
      .add("11", "hours")
      .add("45", "minutes");
    const endTime = moment()
      .startOf("day")
      .add("13", "hours")
      .add("00", "minutes");

    const venueTraffic = yield select((state) => state.venue.venue.traffic);

    if (
      time.isBetween(initialTime, endTime) &&
      venueTraffic.status !== VENUE_TRAFFIC_STATUS.BUSY
    ) {
      yield put(updateVenueTraffic(venueId, VENUE_TRAFFIC_STATUS.BUSY));
    } else if (
      !time.isBetween(initialTime, endTime) &&
      venueTraffic.status !== VENUE_TRAFFIC_STATUS.NORMAL
    ) {
      yield put(updateVenueTraffic(venueId, VENUE_TRAFFIC_STATUS.NORMAL));
    }

    yield delay(60000);
  }
}

function* restartAutomaticVenueTraffic(action) {
  const automaticVenueTraffic = yield select(
    (state) => state.venue.automaticVenueTraffic
  );
  if (automaticVenueTraffic) {
    yield put(enableAutomaticVenueTraffic(action.venue.id));
  }
}

function* venueTrafficSagaHandler() {
  while (true) {
    // start auto update saga
    const autoVenueTraffic = yield takeLatest(
      types.venue.AUTOMATIC_VENUE_TRAFFIC.ENABLE,
      automaticVenueTrafficSaga
    );

    // wait for a call to disable
    yield take(types.venue.AUTOMATIC_VENUE_TRAFFIC.DISABLE);

    // check the last time traffic was updated. if older than 2h, set back to green
    const venueTraffic = yield select((state) => state.venue.venue.traffic);

    if (
      moment
        .unix()
        .diff(
          moment.unix(venueTraffic ? venueTraffic.lastUpdate : 0),
          "hours"
        ) >= VENUE_TRAFFIC_TIME_THRESHHOLD
    ) {
      const currentVenueId = yield select((state) => state.venue.venue.id);
      yield put(
        updateVenueTraffic(currentVenueId, VENUE_TRAFFIC_STATUS.NORMAL)
      );
    }
    // cancel automatic saga
    yield cancel(autoVenueTraffic);
  }
}

function* updateMenuItemFilesSaga(action) {
  const { venueId, itemId, itemImage, itemVideos } = action;

  const user = yield select((state) => state.auth.user);

  const authToken = yield call([user, user.getIdToken]);

  try {
    const calls = [];

    if (itemImage && itemImage.file) {
      const imageData = new FormData();
      imageData.append("type", "image");
      imageData.append("name", itemImage.file.name);
      imageData.append("file", itemImage.file);
      calls.push(
        call(fetch, uploadItemFileAPI(venueId, itemId), {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${authToken}`,
          }),
          surpressContentType: true,
          bubbleError: true,
          body: imageData,
        })
      );
    }

    if (itemVideos && itemVideos.length) {
      const videosData = new FormData();
      videosData.append("type", "video");
      itemVideos.forEach((itemVideo, index) => {
        if (itemVideo.url) {
          videosData.append(`urlFiles[${index}]`, itemVideo.url);
        }
        if (itemVideo.file) {
          videosData.append(`files[${index}]`, itemVideo.file);
        } else {
          videosData.append(`files[${index}]`, new File([""], "empty_file"));
        }
      });
      calls.push(
        call(fetch, uploadItemFileAPI(venueId, itemId), {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${authToken}`,
          }),
          surpressContentType: true,
          bubbleError: true,
          body: videosData,
        })
      );
    }

    yield all(calls);
  } catch (error) {
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.IMAGE_VIDEO.UPLOAD_ERROR,
        true
      )
    );
  }
}

function* updateMenuItemFilesAndRefreshVenuesList(action) {
  const { venueId } = action;

  // Firstly we upload the image/videos (this call may take considerable time)
  yield call(updateMenuItemFilesSaga, action);

  // Secondly we reload the list of venues after adding/updating their files
  yield call(retrieveVenueSaga, { venueId });

  // Then we show a success notification message for files upload
  yield put(
    setNotification(
      StatusEnum.SUCCESS,
      notificationStrings.de.IMAGE_VIDEO.UPLOAD_SUCCESS,
      true
    )
  );
}

function* createMenuItemSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, payload } = action;
    const { itemId, sectionId, itemImage, itemVideos } = payload;

    yield call(fetch, createMenuItemAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    });
    yield call(fetch, addItemToSectionAPI(venueId, sectionId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ itemId }),
    });

    yield put(createItemSuccess(venueId, {}));

    // We reload the list of venues after adding one
    yield call(retrieveVenueSaga, { venueId });

    // Lastly we upload the image/videos (this call may take considerable time)
    yield call(updateMenuItemFilesSaga, {
      venueId,
      itemId,
      itemImage,
      itemVideos,
    });
  } catch (error) {
    yield put(createItemFailure(action.venueId, error));
  }
}

function* createMenuSectionSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, names: sectionNames } = action;
    const sectionId = shortid.generate();
    yield call(fetch, createMenuSectionAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ sectionId, sectionNames }),
    });

    yield put(createSectionSuccess(sectionId, sectionNames));

    // After Success we show a success notification message for section creation
    yield put(
      setNotification(
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.ADD_SECTION_SUCCESS,
        true
      )
    );

    // We reload the list of venues after adding one
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(createSectionFailure(action.venueId, error));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.ADD_SECTION_ERROR
      )
    );
  }
}

function* updateMenuItemSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, itemId, payload } = action;

    yield call(fetch, updateMenuItemAPI(venueId, itemId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    });

    yield put(updateItemSuccess(venueId, {}));

    // After Success we show a success notification message for item update
    yield put(
      setNotification(
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.UPDATE_ITEM_SUCCESS,
        true
      )
    );

    // We reload the list of items after updating one item
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(updateItemFailure(action.venueId, error));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.UPDATE_ITEM_ERROR
      )
    );
  }
}

function* updateMenuSectionSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, sectionId, names: sectionNames } = action;
    yield call(fetch, updateMenuSectionAPI(venueId, sectionId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ sectionNames }),
    });

    yield put(updateSectionSuccess(venueId, sectionId));

    // After Success we show a success notification message for section update
    yield put(
      setNotification(
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.UPDATE_SECTION_SUCCESS,
        true
      )
    );

    // We reload the list of items after updating one section
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(updateSectionFailure(action.sectionId, error));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.UPDATE_SECTION_ERROR
      )
    );
  }
}

function* updateMenuSectionOrderSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, sectionOrder } = action;
    yield call(fetch, updateMenuSectionOrderAPI(venueId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ sectionOrder }),
    });

    yield put(updateSectionOrderSuccess(venueId, sectionOrder));

    // After Success we show a success notification message for section update
    yield put(
      setNotification(
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.UPDATE_SECTION_ORDER_SUCCESS,
        true
      )
    );

    // We reload the list of items after updating one section
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(updateSectionOrderFailure(action.venueId, error));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.UPDATE_SECTION_ORDER_ERROR
      )
    );
  }
}

function* deleteMenuItemSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, itemId, items, sectionId } = action;
    yield call(fetch, deleteMenuItemAPI(venueId, itemId), {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ sectionId, items }),
    });
    yield put(deleteItemSuccess(venueId, itemId, sectionId));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.DELETE_ITEM_SUCCESS,
        true
      )
    );
  } catch (error) {
    yield put(deleteItemFailure(action.venueId, error));
    yield put(
      setNotification(
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.DELETE_ITEM_ERROR
      )
    );
  }
}

function* toggleDeliveryOrdersStatusSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, status } = action;

    yield call(fetch, createDeliveryOrdersToggleAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        status,
      }),
    });
    yield put(toggleDeliveryOrdersStatusSuccess(venueId, status));
  } catch (error) {
    yield put(toggleDeliveryOrdersStatusFailure(action.venueId, error));
  }
}

function* updateItemOptionSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, itemId, optionId } = action;

    yield call(fetch, updateItemOptionAPI(venueId, itemId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ optionId }),
    });

    yield put(addExistingOptionToItemSuccess(venueId, {}));

    // We reload the list of venues after adding one
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(addExistingOptionToItemFailure(action.venueId, error));
  }
}

function* updateOptionSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, optionId, option, newOptionId } = action;
    const propsToUpdate = {};
    propsToUpdate.options = [...option.options, newOptionId];

    yield call(fetch, updateOptionAPI(venueId, optionId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ propsToUpdate, stringsToUpdate: {} }),
    });

    yield put(addExistingOptionToOptionSuccess(venueId, {}));

    // We reload the list of venues after adding one
    yield call(retrieveVenueSaga, { venueId });
  } catch (error) {
    yield put(addExistingOptionToOptionFailure(action.venueId, error));
  }
}

export default function* venueRootSaga() {
  yield all([
    takeLatest(types.venue.RETRIEVE_VENUE.REQUEST, retrieveVenueSaga),
    takeLatest(
      types.venue.TOGGLE_SECTION_AVAILABILITY.REQUEST,
      toggleSectionAvailabilitySaga
    ),
    takeLatest(
      types.venue.TOGGLE_ITEM_AVAILABILITY.REQUEST,
      toggleItemAvailabilitySaga
    ),
    takeLatest(
      types.venue.UDPATE_VENUE_TRAFFIC.REQUEST,
      updateVenueTrafficSaga
    ),
    takeLatest(
      types.venue.TOGGLE_OPTION_AVAILABILITY.REQUEST,
      toggleOptionAvailabilitySaga
    ),
    takeLatest(
      types.venue.TOGGLE_VENUE_AVAILABILITY.REQUEST,
      toggleVenueAvailabilitySaga
    ),
    takeLatest(
      types.venue.RETRIEVE_VENUE.SUCCESS,
      restartAutomaticVenueTraffic
    ),
    takeLatest(
      types.venue.VENUE_UPDATE_ITEM.UPDATE_FILES,
      updateMenuItemFilesAndRefreshVenuesList
    ),
    takeLatest(
      types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.REQUEST,
      toggleDeliveryOrdersStatusSaga
    ),
    takeLatest(types.venue.VENUE_CREATE_ITEM.REQUEST, createMenuItemSaga),
    takeLatest(types.venue.VENUE_CREATE_SECTION.REQUEST, createMenuSectionSaga),
    takeLatest(
      types.venue.ADD_EXISTING_OPTION_TO_ITEM.REQUEST,
      updateItemOptionSaga
    ),
    takeLatest(
      types.venue.ADD_EXISTING_OPTION_TO_OPTION.REQUEST,
      updateOptionSaga
    ),
    takeLatest(types.venue.VENUE_UPDATE_ITEM.REQUEST, updateMenuItemSaga),
    takeLatest(types.venue.VENUE_UPDATE_SECTION.REQUEST, updateMenuSectionSaga),
    takeLatest(
      types.venue.VENUE_UPDATE_SECTION_ORDER.REQUEST,
      updateMenuSectionOrderSaga
    ),
    takeLatest(types.venue.VENUE_DELETE_ITEM.REQUEST, deleteMenuItemSaga),
    fork(venueTrafficSagaHandler),
  ]);
}
