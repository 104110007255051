/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  requests: {
    retrieveSales: {
      processing: false,
      error: null,
    },
  },
  data: null,
};

export default function salesReducer(state = initialState, action) {
  switch (action.type) {
    case types.sales.RETRIEVE_SALES.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveSales.processing = true;
        newState.requests.retrieveSales.error = null;
      });
    case types.sales.RETRIEVE_SALES.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveSales.processing = false;
        newState.data = action.data;
      });
    case types.sales.RETRIEVE_SALES.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveSales.processing = false;
        newState.requests.retrieveSales.error = action.error;
      });
    default:
      return state;
  }
}
