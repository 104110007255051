/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import { TabletStatus } from "@orda/shared-constants/backoffice/tablet-bridge-events";

export const initialState = {
  loading: true,
  data: null,
  printers: null,
  availablePrinters: null,
  printerStatus: [],
  updatingPrinters: false,
  hasBluetoothAddress: false,
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case types.status.INIT:
      return produce(state, (newState) => {
        newState.data = {
          ...newState.data,
        };
        Object.keys(action.data).forEach((key) => {
          if (key === TabletStatus.Printer) {
            newState.printerStatus.push(action.data[key]);
          } else {
            newState.data[key] = action.data[key];
          }
        });
      });
    case types.status.LOADING_FINISHED:
      return produce(state, (newState) => {
        newState.loading = false;
      });
    case types.status.APPEND:
      return produce(state, (newState) => {
        if (action.key === TabletStatus.Printer) {
          const isPrinterInserted = newState.printerStatus.includes(
            (printer) => printer.id === action.value.id
          );
          if (!isPrinterInserted) {
            newState.printerStatus.push(action.value);
          }
        } else {
          if (!newState.data) {
            newState.data = {};
          }
          newState.data[action.key] = action.value;
        }
      });
    case types.status.UPDATE:
      return produce(state, (newState) => {
        if (newState.data && action.key in newState.data) {
          newState.data[action.key].status = action.value;
        }

        if (action.printers) {
          newState.printers = action.printers;
        }

        if (action.availablePrinters) {
          newState.availablePrinters = action.availablePrinters;
        }

        if (action.printerId) {
          const printerStatus = newState.printerStatus.find(
            (printer) => printer.id === action.printerId
          );
          if (printerStatus) {
            printerStatus.status = action.value;
          }
        }
      });
    case types.status.UPDATE_PRINTER_CONFIG:
      return produce(state, (newState) => {
        newState.updatingPrinters = true;
      });
    case types.status.UPDATE_PRINTER_CONFIG_SUCCESS:
      return produce(state, (newState) => {
        newState.updatingPrinters = false;
      });
    case types.status.STORE_PRINTER_CONFIG:
      return produce(state, (newState) => {
        newState.printers = action.printerUpdates;
      });
    case types.status.SET_DATABASE_MANAGED_PRINTERS:
      return produce(state, (newState) => {
        newState.hasBluetoothAddress = true;
      });
    default:
      return state;
  }
}
