/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  requests: {
    login: {
      processing: false,
      error: null,
    },
    logout: {
      processing: false,
      error: null,
    },
    retrieveUserVenues: {
      processing: false,
      error: null,
    },
  },
  blocked: true,
  user: null,
  syncFinished: false,
  venues: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.auth.LOGIN.REQUEST:
      return produce(state, (newState) => {
        newState.requests.login.processing = true;
        newState.requests.login.error = null;
      });
    case types.auth.LOGIN.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.login.processing = false;
      });
    case types.auth.LOGIN.FAILURE:
      return produce(state, (newState) => {
        newState.requests.login.processing = false;
        newState.requests.login.error = action.error;
      });
    case types.auth.LOGOUT.REQUEST:
      return produce(state, (newState) => {
        newState.requests.logout.processing = true;
        newState.requests.logout.error = null;
      });
    case types.auth.LOGOUT.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.logout.processing = false;
      });
    case types.auth.LOGOUT.FAILURE:
      return produce(state, (newState) => {
        newState.requests.logout.processing = false;
        newState.requests.logout.error = action.error;
      });
    case types.auth.SYNC_FIREBASE_USER:
      return produce(state, (newState) => {
        newState.syncFinished = true;
        newState.user = action.user;
      });
    case types.auth.RETRIEVE_USER_VENUES.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveUserVenues.processing = true;
        newState.requests.retrieveUserVenues.error = null;
      });
    case types.auth.RETRIEVE_USER_VENUES.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveUserVenues.processing = false;
        newState.venues = action.venues;
      });
    case types.auth.RETRIEVE_USER_VENUES.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveUserVenues.processing = false;
        newState.requests.retrieveUserVenues.error = action.error;
      });
    case types.auth.UNBLOCK:
      return produce(state, (newState) => {
        newState.blocked = false;
      });
    default:
      return state;
  }
}
