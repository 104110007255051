import React from "react";
import ReactLoading from "react-loading";

const PulseLoader = () => (
  <ReactLoading
    type="bubbles"
    color="#a1a1a1"
    width={32}
    height={32}
    delay={100}
  />
);

export default PulseLoader;
