import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { withTranslation } from "react-i18next";
import { Countdown } from "react-time-sync";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { OrderLocation } from "@orda/shared-constants";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import AlarmIcon from "@material-ui/icons/NotificationsNone";
import NotificationModal from "../../NotificationModal";

import { orderListItemMuiStyles } from "./muiStyles";

const OrderItemExpandedContent = ({
  classes,
  venueSum,
  expandCard,
  expanded,
  items,
  feeSum,
  tipSum,
  hobbyCook,
  notes,
  pushBlockUntil,
  orderLocation,
  doneAt,
  handleOrderFinish,
  outlineColor,
  pushDisabled,
  numberOfItems,
  t,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const itemsList = useMemo(
    () =>
      items.map((item, index) => {
        if (!item.notOnKitchenReceipt) {
          switch (item.padding) {
            case 0:
              return (
                <>
                  {index !== 0 && <Divider className={classes.divider} />}
                  <Typography
                    key={item.id}
                    style={{
                      paddingTop: 8,
                      textTransform: "capitalize",
                      wordWrap: "break-word",
                    }}
                    variant="body2"
                  >
                    • {item.name.venue}
                    {notes && notes[index] ? (
                      <div className="order-note">
                        <i>
                          <u>Nutzerwunsch:</u>
                          &nbsp;
                          {notes[index]}
                        </i>
                      </div>
                    ) : null}
                  </Typography>
                </>
              );
            default:
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Typography className={classes.itemOptions}>
                  - {item.name.venue}
                </Typography>
              );
          }
        }
        return null;
      }),
    [items, notes, classes]
  );

  return (
    <>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid
          container
          spacing={16}
          justify="center"
          className={classes.expandedWrapper}
        >
          <CardActionArea onClick={() => expandCard(!expanded)}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={16}
              className={classes.content}
            >
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Grid item container sm={12} spacing={8} xs={12}>
                  <Grid item container xs={6}>
                    <CardContent className={classes.cardContentDetails}>
                      <Typography noWrap variant="body2">
                        <b>{t("itemsShort", { count: numberOfItems })}: </b>
                      </Typography>
                      {itemsList}
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    justify="flex-end"
                    container
                    alignItems="center"
                    xs={6}
                  >
                    <CardContent className={classes.cardContentDetails}>
                      <Countdown until={pushBlockUntil}>
                        {({ timeLeft }) => (
                          <Grid className={classes.progressContainer}>
                            {!!timeLeft && (
                              <CircularProgress
                                className={classes.progress}
                                variant="static"
                                size={100}
                                value={100 - timeLeft * 5}
                              />
                            )}
                            <Fab
                              classes={{
                                root: classes.fabDoneContainer,
                                label: classes.fabDoneLabel,
                              }}
                              style={{ color: outlineColor }}
                              aria-label="finish-order"
                              onClick={() => {
                                setModalOpen(true);
                              }}
                              disabled={timeLeft > 0 || pushDisabled}
                            >
                              <AlarmIcon
                                style={{
                                  color:
                                    timeLeft > 0 || pushDisabled
                                      ? "rgba(30,144,255,0.4)"
                                      : outlineColor,
                                }}
                                className={classes.alarmButton}
                              />
                            </Fab>
                          </Grid>
                        )}
                      </Countdown>
                    </CardContent>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid item container sm={12} spacing={8} xs={12}>
                  <Grid item container xs={6}>
                    <CardContent className={classes.cardContentDetails}>
                      <Typography noWrap variant="body1">
                        <b>Total: </b>
                        <FormattedNumber
                          // eslint-disable-next-line react/style-prop-object
                          style="currency"
                          currency="EUR"
                          value={(venueSum - feeSum) / 100}
                        />
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    justify="flex-end"
                    container
                    alignItems="center"
                    xs={6}
                  >
                    <CardContent
                      style={{
                        marginRight: "1vw",
                        textAlign: "right",
                      }}
                      className={classes.cardContentDetails}
                    >
                      <Typography noWrap variant="body1">
                        <b>{hobbyCook ? "Spende" : "Trinkgeld"}: </b>
                        <FormattedNumber
                          // eslint-disable-next-line react/style-prop-object
                          style="currency"
                          currency="EUR"
                          value={tipSum / 100}
                        />
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
      </Collapse>
      <NotificationModal
        isOpen={isModalOpen}
        info={{
          status: doneAt ? "success" : "warning",
          message:
            orderLocation === OrderLocation.Delivery
              ? `Dein Gast erhält durch Klicken ${
                  doneAt ? "eine erneute" : "eine"
                } Push-Benachrichtigung, dass die Bestellung auf dem Weg ist.`
              : `Dein Gast erhält durch Klicken ${
                  doneAt ? "erneute" : "eine"
                } Push-Benachrichtigung, dass die Bestellung bereit zum Abholen ist.`,
        }}
        hideIcon
        closeText="Benachrichtigung senden"
        onCloseIcon={() => {
          setModalOpen(false);
        }}
        closeNotificationModalAction={() => {
          setModalOpen(false);
          handleOrderFinish();
        }}
      />
    </>
  );
};

OrderItemExpandedContent.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array,
  notes: PropTypes.array,
  expanded: PropTypes.bool.isRequired,
  hobbyCook: PropTypes.bool,
  expandCard: PropTypes.func.isRequired,
  pushBlockUntil: PropTypes.func.isRequired,
  handleOrderFinish: PropTypes.func.isRequired,
  pushDisabled: PropTypes.bool.isRequired,
  doneAt: PropTypes.bool.isRequired,
  tipSum: PropTypes.number.isRequired,
  feeSum: PropTypes.number.isRequired,
  venueSum: PropTypes.number.isRequired,
  outlineColor: PropTypes.string.isRequired,
  numberOfItems: PropTypes.number,
  t: PropTypes.object.isRequired,
  orderLocation: PropTypes.number.isRequired,
};

OrderItemExpandedContent.defaultProps = {
  items: [],
  notes: [],
  numberOfItems: 0,
  hobbyCook: false,
};

export default withStyles(orderListItemMuiStyles, { withTheme: true })(
  withTranslation("order")(OrderItemExpandedContent)
);
