const strings = {
  de: {
    "activity.changed_global_status":
      "{sourceUserName} hat ORDA Bestellungen {status}.",
    "activity.changed_item_availability":
      "{sourceUserName} hat {targetItemName} als {available, select, true {verfügbar} other {nicht verfügbar}} gesetzt.",
    "error.message":
      "Es ist ein Fehler aufgetreten. Falls dieses Problem weiterhin auftritt, wenden Sie sich bitte an den Support.",
    "notfound.message": "Diese Seite konnte nicht gefunden werden.",
  },
};

export default strings;
