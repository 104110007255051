import { select, takeLatest, call, put } from "redux-saga/effects";
import * as types from "../types";
import { createActivityLogAPI } from "../../lib/venue";
import {
  retrieveActivityLogSuccess,
  retrieveActivityLogFailure,
} from "../actions/activityLog";

function* retrieveActivityLogSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId } = action;

    const response = yield call(fetch, createActivityLogAPI(venueId), {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    const data = yield call([response, response.json]);
    yield put(retrieveActivityLogSuccess(data));
  } catch (error) {
    yield put(retrieveActivityLogFailure(error));
  }
}

export default function* activityLogRootSaga() {
  yield takeLatest(
    types.activityLog.RETRIEVE_ACTIVITY_LOG.REQUEST,
    retrieveActivityLogSaga
  );
}
