// eslint-disable-next-line import/no-named-as-default
import NotFound from "../components/NotFound";
import { connect } from "react-redux";
import { updateNavBarTitle } from "../redux/actions/uiState";

const mapDispatchToProps = (dispatch) => ({
  updateNavBarTitle: () => dispatch(updateNavBarTitle(null)),
});

export default connect(null, mapDispatchToProps)(NotFound);
