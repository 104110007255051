import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";

import UpdateDishPropertiesFields from "./sub-components/UpdateDishPropertiesFields";
import UpdateDishFiles from "./sub-components/UpdateDishFiles";

import "./UpdateDishDialog.css";
import DeleteDishConfirm from "./sub-components/DeleteDishConfirm";
import { buildSelectOptionList } from "../../../util/helpers";

interface Props {
  open: boolean;
  isOrdered: boolean;
  itemId: string;
  itemData: any;
  venueId: string;
  handleSubmitUpdateDish: Function;
  handleDeleteMenuItem: Function;
  handleCloseDialog: Function;
  sectionId: string;
  checkOrderedByItemId: Function;
  menu: any;
  strings: any;
  openingHours: any[];
}

const UpdateDishPageEnum = {
  ROOT: "ROOT",
  PROPERTIES: "PROPERTIES",
  FILES: "FILES",
  DELETE_DISH: "DELETE_DISH",
};

const UpdateDishDialog: React.FC<Props> = ({
  open,
  isOrdered,
  itemId,
  venueId,
  itemData,
  handleSubmitUpdateDish,
  handleDeleteMenuItem,
  handleCloseDialog,
  checkOrderedByItemId,
  sectionId,
  menu,
  strings,
  openingHours,
}): ReturnType<React.FC<Props>> => {
  const { t } = useTranslation("updateDish");

  const [page, setPage] = useState(UpdateDishPageEnum.ROOT);

  const closeDialog = useCallback(() => {
    handleCloseDialog();
    setPage(UpdateDishPageEnum.ROOT);
  }, [handleCloseDialog]);

  const backToRoot = useCallback(() => {
    setPage(UpdateDishPageEnum.ROOT);
  }, [handleCloseDialog]);

  useEffect(() => {
    if (open) {
      checkOrderedByItemId(venueId, itemId, null, null, false);
    }
  }, [open, venueId, itemId]);

  return (
    <Dialog
      fullWidth
      PaperProps={{
        style: {
          margin: "4%",
        },
      }}
      open={open}
      maxWidth="md"
      aria-labelledby="update-menu-item-dialog"
    >
      <DialogTitle id="update-menu-item-dialog">
        <div className="update-item-dialog-title">
          <Close
            className="update-item-dialog-close"
            style={{
              width: "1.4em",
              height: "1.4em",
              fill: "#B9D47F",
            }}
            onClick={closeDialog}
          />
          <div style={{ padding: "10px", textAlign: "center" }}>
            {page === UpdateDishPageEnum.ROOT && t("updateDishTitle")}
            {page === UpdateDishPageEnum.PROPERTIES &&
              t("updateDishPropertiesTitle")}
            {page === UpdateDishPageEnum.FILES && t("updateDishFilesTitle")}
            {page === UpdateDishPageEnum.DELETE_DISH &&
              t("deleteDishModalTitle")}
          </div>
          <div />
        </div>
      </DialogTitle>
      {page === UpdateDishPageEnum.ROOT && (
        <div className="update-item-dialog-buttons-container">
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              backgroundColor: isOrdered ? "rgba(0, 0, 0, 0.12)" : "#B9D47F",
              border: isOrdered ? "solid 1px rgba(0, 0, 0, 0.12)" : "none",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "none",
              marginBottom: "12px",
            }}
            onClick={() => {
              setPage(UpdateDishPageEnum.PROPERTIES);
            }}
            disabled={isOrdered}
            type="button"
          >
            {t("updateDishPropertiesButton")}
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#B9D47F",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "none",
              marginBottom: "12px",
            }}
            onClick={() => {
              setPage(UpdateDishPageEnum.FILES);
            }}
            type="button"
          >
            {t("updateDishFilesButton")}
          </Button>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#B9D47F",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "none",
            }}
            onClick={() => {
              setPage(UpdateDishPageEnum.DELETE_DISH);
            }}
            type="button"
          >
            {t("deleteDishButton")}
          </Button>
        </div>
      )}
      {page === UpdateDishPageEnum.PROPERTIES && (
        <UpdateDishPropertiesFields
          itemId={itemId}
          itemData={itemData}
          handleSubmitUpdateDish={handleSubmitUpdateDish}
          sectionOrder={menu.sectionOrder}
          openingHours={openingHours}
          items={menu.items}
          strings={strings}
          options={buildSelectOptionList(menu, strings)}
          optionsTree={{
            ...menu.options,
          }}
          venueId={venueId}
        />
      )}
      {page === UpdateDishPageEnum.FILES && (
        <UpdateDishFiles
          itemId={itemId}
          venueId={venueId}
          videos={itemData.videos}
          closeDialog={closeDialog}
        />
      )}
      {page === UpdateDishPageEnum.DELETE_DISH && (
        <DeleteDishConfirm
          venueMenu={menu}
          sectionId={sectionId}
          handleCloseDialog={closeDialog}
          handleDeleteMenuItem={handleDeleteMenuItem}
          handleBackToRootDishEdit={backToRoot}
          itemId={itemId}
          venueId={venueId}
        />
      )}
    </Dialog>
  );
};

UpdateDishDialog.propTypes = {
  menu: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  isOrdered: PropTypes.bool.isRequired,
  handleSubmitUpdateDish: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  strings: PropTypes.any.isRequired,
  venueId: PropTypes.string.isRequired,
  itemData: PropTypes.any.isRequired,
  checkOrderedByItemId: PropTypes.func.isRequired,
  handleDeleteMenuItem: PropTypes.func.isRequired,
  openingHours: PropTypes.any.isRequired,
};

export default UpdateDishDialog;
