import React, { useState } from "react";
import PropTypes from "prop-types";
import { Paper, withStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

const styles = {
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#494949",
    cursor: "pointer",
  },
  contentContainer: {
    backgroundColor: "#f7f7f7",
  },
  icon: { color: "white", fontSize: 30, padding: 5 },
};

const Accordion = ({ classes, title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <Paper
        elevation={0}
        className={classes.titleContainer}
        onClick={() => setIsActive(!isActive)}
      >
        <div>
          {isActive ? (
            <KeyboardArrowDown className={classes.icon} />
          ) : (
            <KeyboardArrowRight className={classes.icon} />
          )}
        </div>
        {title}
      </Paper>
      <div className={classes.contentContainer}>{isActive && content}</div>
    </div>
  );
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
};

export default withStyles(styles)(Accordion);
