import { FoodPreference, Allergen } from "@orda/shared-constants";

export const UI_IGNORED_ROUTES = [
  "unauthorized",
  "register",
  "error",
  "not-found",
  "login",
];

export const SUPPORTED_LOCALES = ["de", "en"];

export const StatusEnum = {
  IDLE: "idle",
  WARNING: "warning",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const SECTIONS_TO_NOT_RENDER = [
  "hobby-cook-intro-section",
  "hobby-cook-how-it-works-section",
  "hobby-cook-about-section",
  "safety-heigine-section",
];

export const orderModusMap = [
  { orderModusName: "Nur im Haus", orderModusId: "TO-STAY" },
  {
    orderModusName: "Nur zum mitnehmen",
    orderModusId: "TO-GO",
  },
  {
    orderModusName: "Im Haus und zum Mitnehmen",
    orderModusId: "TO-STAYTO-GO",
  },
];

export const vatMap = [undefined, 0.19, 0.07];

export const PRICE_DISPLAY_DEFAULT = 0;
export const PRICE_DISPLAY_WITHOUT_PREFIX = 1;

export const tagsMap = [
  { tagName: "SCHARF", tagId: FoodPreference.Spicy },
  { tagName: "VEGETARISCH", tagId: "Sy6w1tRzb" },
  { tagName: "VEGAN", tagId: FoodPreference.Vegan },
  { tagName: "LOW CARB", tagId: FoodPreference.LowCarb },
  { tagName: "HIGH PROTEIN", tagId: FoodPreference.HighProtein },
  { tagName: "GLUTEN", tagId: Allergen.Gluten },
  { tagName: "KREBSTIERE", tagId: Allergen.Crustaceans },
  { tagName: "EIER", tagId: Allergen.Eggs },
  { tagName: "FISCHE", tagId: Allergen.Fish },
  { tagName: "ERDNÜSSE", tagId: Allergen.Peanuts },
  { tagName: "SOJABOHNEN", tagId: Allergen.Soy },
  { tagName: "LAKTOSE", tagId: Allergen.Lactose },
  { tagName: "NÜSSE", tagId: Allergen.Nuts },
  { tagName: "SELLERIE", tagId: Allergen.Celery },
  { tagName: "SENF", tagId: Allergen.Mustard },
  { tagName: "SESAMSAMEN", tagId: Allergen.Sesame },
  { tagName: "SULPHITE", tagId: Allergen.Sulfite },
  { tagName: "LUPINEN", tagId: Allergen.Lupins },
  { tagName: "WEICHTIERE", tagId: Allergen.Molluscs },
  { tagName: "Konservierungsstoff", tagId: Allergen.Preservative },
  { tagName: "Farbstoff", tagId: Allergen.Colorant },
  { tagName: "Antioxidationsmittel", tagId: Allergen.Antioxidant },
  { tagName: "Saccharin", tagId: Allergen.Saccharin },
  { tagName: "Cyclamat", tagId: Allergen.Cyclamate },
  {
    tagName: "Aspartam, enth. Phenylalaninquelle",
    tagId: Allergen.AspartamePhenylalanineSource,
  },
  { tagName: "Acesulfam", tagId: Allergen.Acesulfame },
  { tagName: "Phosphat", tagId: Allergen.Phosphate },
  { tagName: "Geschwefelt", tagId: Allergen.Sulfurized },
  { tagName: "Chininhaltig", tagId: Allergen.Quinine },
];

export const priceDisplayMap = [
  { priceDisplayName: "Default", priceDisplayId: PRICE_DISPLAY_DEFAULT },
  {
    priceDisplayName: "Ohne Präfix",
    priceDisplayId: PRICE_DISPLAY_WITHOUT_PREFIX,
  },
];
