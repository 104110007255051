import React from "react";
import Loading from "./Loading";

const Refresh: React.FC = (): ReturnType<React.FC> => (
  <div
    style={{
      textAlign: "center",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    }}
  >
    <h1>Es scheint keine Internet Verbindung zu bestehen.</h1>
    <div style={{ paddingTop: 40, paddingBottom: 20 }}>
      <Loading />
    </div>
    <h2>Wir versuchen, die Verbindung zum Server wiederherzustellen.</h2>
  </div>
);

export default Refresh;
