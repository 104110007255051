import { connect } from "react-redux";
import Orders from "../components/OrdersNew/Orders";
import { retrieveOrders } from "../redux/actions/orders";
import { updateNavBarTitle } from "../redux/actions/uiState";
import {
  updateVenueTraffic,
  retrieveVenue,
  enableAutomaticVenueTraffic,
  disableAutomaticVenueTraffic,
} from "../redux/actions/venue";
import { Order, Venue } from "@orda/shared-types";
import { Dispatch } from "react";
// @ts-ignore
import { get } from "lodash";

interface PropsFromDispatch {
  retrieveVenue: (venueId: string) => void;
  retrieveOrders: (
    venueId: string,
    start: number,
    end: number,
    hobbyCook: boolean
  ) => void;
  updateNavBarTitle: (date: string) => void;
  updateVenueTraffic: (venueId: string, status: number) => void;
  enableAutomaticVenueTraffic: (venueId: string) => void;
  disableAutomaticVenueTraffic: (venueId: string) => void;
}

interface PropsFromState {
  retrieveOrdersProcessing: boolean;
  reprintOrderProcessing: boolean;
  orders: Order[];
  currentVenueId: string;
  venue: Venue;
  updateVenueTrafficProcessing: boolean;
  retrieveVenueProcessing: boolean;
  automaticVenueTraffic: boolean;
  isHobbyCook: boolean;
}

interface State {
  orders: {
    requests: {
      retrieveOrders: {
        processing: boolean;
      };
      reprintOrder: {
        processing: boolean;
      };
      markOrderAsDone: {
        pendingOrderRequests: any[];
      };
    };
    orders: Order[];
  };
  venue: {
    requests: {
      updateVenueTraffic: {
        processing: boolean;
      };
      retrieveVenue: {
        processing: boolean;
      };
    };
    venue: Venue;
    automaticVenueTraffic: boolean;
  };
  uiState: {
    currentVenueId: string;
  };
}

const mapStateToProps = (state: State): PropsFromState => {
  const {
    orders: {
      requests: {
        retrieveOrders: { processing: retrieveOrdersProcessing },
        reprintOrder: { processing: reprintOrderProcessing },
        markOrderAsDone: pendingOrderRequests,
      },
      orders,
    },
    venue: {
      requests: {
        updateVenueTraffic: { processing: updateVenueTrafficProcessing },
        retrieveVenue: { processing: retrieveVenueProcessing },
      },
      venue,
      automaticVenueTraffic,
    },
    uiState: { currentVenueId },
  } = state;

  // Update orders with pending done at requests with a preliminary doneAt property
  // to show them as "done" already
  const updatedOrders =
    orders && !!orders.length
      ? orders.map((order) => {
          // @ts-ignore "target = es5"
          const pendingOrderRequest = pendingOrderRequests.find(
            ({ orderId }: { orderId: string }) => orderId === order.orderId
          );
          if (pendingOrderRequest) {
            return {
              ...order,
              doneAt: pendingOrderRequest.doneAt,
            };
          }
          return order;
        })
      : [];

  return {
    retrieveOrdersProcessing,
    reprintOrderProcessing,
    orders: updatedOrders,
    currentVenueId,
    venue,
    updateVenueTrafficProcessing,
    retrieveVenueProcessing,
    automaticVenueTraffic,
    // @ts-ignore
    isHobbyCook: get(venue, ["hobbyCook"], false),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): PropsFromDispatch => ({
  retrieveVenue: (venueId: string) => dispatch(retrieveVenue(venueId)),
  retrieveOrders: (
    venueId: string,
    start: number,
    end: number,
    hobbyCook: boolean
  ) => dispatch(retrieveOrders(venueId, start, end, hobbyCook)),
  updateNavBarTitle: (title: string) => dispatch(updateNavBarTitle(title)),
  updateVenueTraffic: (venueId: string, status: number) =>
    dispatch(updateVenueTraffic(venueId, status)),
  enableAutomaticVenueTraffic: (venueId: string) =>
    dispatch(enableAutomaticVenueTraffic(venueId)),
  disableAutomaticVenueTraffic: (venueId: string) =>
    dispatch(disableAutomaticVenueTraffic(venueId)),
});

export default connect(mapStateToProps, mapDispatchToProps)<any>(Orders);
