import React from "react";
import OrderListItem from "./OrderListItem";
import PropTypes from "prop-types";
import { useTime } from "react-time-sync";
import TimeSync from "time-sync";

const TimedOrderListItem = ({
  pushDisabled,
  time,
  getOrderTimeCounter,
  order,
  markOrderAsDone,
  reprintOrder,
  currentVenueId,
  venueLiteMode,
}) => {
  const currentMinute = useTime({ interval: TimeSync.SECONDS });
  return (
    <OrderListItem
      pushDisabled={pushDisabled}
      time={time}
      timed
      currentMinute={currentMinute}
      order={order}
      markOrderAsDone={markOrderAsDone}
      reprintOrder={reprintOrder}
      currentVenueId={currentVenueId}
      getOrderTimeCounter={getOrderTimeCounter}
      venueLiteMode={venueLiteMode}
    />
  );
};

TimedOrderListItem.propTypes = {
  pushDisabled: PropTypes.bool.isRequired,
  time: PropTypes.number.isRequired,
  getOrderTimeCounter: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  markOrderAsDone: PropTypes.func.isRequired,
  reprintOrder: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string.isRequired,
  venueLiteMode: PropTypes.bool.isRequired,
};

export default TimedOrderListItem;
