import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

interface Props {
  itemId: string;
  sectionId: string;
  handleCloseDialog: any;
  venueId: string;
  handleBackToRootDishEdit: any;
  handleDeleteMenuItem: Function;
  venueMenu: any;
}

const DeleteDishConfirm: React.FC<Props> = ({
  itemId,
  venueId,
  sectionId,
  handleCloseDialog,
  handleDeleteMenuItem,
  handleBackToRootDishEdit,
  venueMenu,
}): ReturnType<React.FC<Props>> => {
  function handleDeleteItem(): void {
    const { sections } = venueMenu;
    const items = [...sections[sectionId].items];
    items.splice(sections[sectionId].items.indexOf(itemId), 1);
    handleDeleteMenuItem(venueId, itemId, sectionId, items);
    handleCloseDialog();
  }

  return (
    <>
      <Typography
        variant="body1"
        style={{ padding: 30, paddingTop: 0, textAlign: "center" }}
      >
        Bist Du sicher, dass Du das Produkt löschen möchtest? Dieser Schritt
        kann nicht rückgängig gemacht werden.
      </Typography>
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 24,
        }}
      >
        <div
          style={{
            width: "45%",
            display: "inline-block",
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              height: 55,
              backgroundColor: "#B9D47F",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "none",
            }}
            onClick={handleBackToRootDishEdit}
            type="button"
          >
            Abbrechen
          </Button>
        </div>
        <div
          style={{
            width: "45%",
            marginLeft: "10%",
            display: "inline-block",
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              height: 55,
              backgroundColor: "#f44336",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
            }}
            type="button"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleDeleteItem}
          >
            Bestätigen
          </Button>
        </div>
      </div>
    </>
  );
};

DeleteDishConfirm.propTypes = {
  venueMenu: PropTypes.any.isRequired,
  itemId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  venueId: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleBackToRootDishEdit: PropTypes.func.isRequired,
  handleDeleteMenuItem: PropTypes.func.isRequired,
};

export default DeleteDishConfirm;
