// venue
export const VENUE_CHANGE_TRAFFIC_STATUS = "venue-change-traffic-status";
export const DIALOG_DISMISS = "dialog-dismiss";
export const VENUE_AUTO_TRAFFIC_DISABLED = "venue-auto-traffic-disabled";
export const VENUE_AUTO_TRAFFIC_ENABLED = "venue-auto-traffic-enabled";

// screen views
export const SCREEN_VIEW = "screen-view";

// marketing
export const MARKETING_TOGGLE_CAMPAIGN_STATUS =
  "marketing-toggle-campaign-status";
export const MARKETING_CREATE_CAMPAIGN = "marketing-create-campaign";
