import { API_ENDPOINT } from "../config";

export function createVenueDataAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}`;
}

export function createUserVenuesAPI(userId) {
  return `${API_ENDPOINT}/users/${userId}/venues`;
}

export function createUpdateItemAvailabilityAPI(venueId, itemId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/${itemId}/availability`;
}

export function createUpdateOptionAvailabilityAPI(venueId, optionId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/options/${optionId}/availability`;
}

export function createVenueGlobalStatusAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/maintenance`;
}

export function createVenueTrafficStatusAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/traffic`;
}

export function createVenueSalesAPI(venueId, params) {
  const url = `${API_ENDPOINT}/venues/${venueId}/sales?start=${params.start}&end=${params.end}`;
  return url;
}

export function createGetVenueCampaignsAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/marketing/campaigns`;
}

export function createUpdateVenueCampaignAPI(venueId, campaignId) {
  return `${API_ENDPOINT}/venues/${venueId}/marketing/campaigns/${campaignId}/status`;
}

export function createActivityLogAPI(venueId) {
  const url = `${API_ENDPOINT}/venues/${venueId}/activity`;
  return url;
}

// ** Orders :

export function checkOrderedByItemIdAPI(venueId, itemId, params) {
  return `${API_ENDPOINT}/venues/${venueId}/orders/${itemId}/checkOrdered?start=${params.start}&end=${params.end}&isHobbyCook=${params.isHobbyCook}`;
}

export function createVenueOrdersAPI(venueId, params) {
  const url = `${API_ENDPOINT}/venues/${venueId}/orders?start=${params.start}&end=${params.end}&isHobbyCook=${params.isHobbyCook}`;
  return url;
}

export function createPrintOrderAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/orders/print`;
}

export function createAcknowledgeOrderAPI(venueId, orderId) {
  return `${API_ENDPOINT}/venues/${venueId}/orders/${orderId}/acknowledge`;
}

export function createMarkOrderDoneAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/orders/done`;
}

// ****

export function createUpdateSectionAvailabilityAPI(venueId, sectionId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/sections/${sectionId}/availability`;
}

export function createCreateCampaignAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/marketing/campaigns`;
}

export function createMenuItemAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/createItem`;
}

export function createMenuSectionAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/addSection`;
}

export function updateMenuItemAPI(venueId, itemId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/${itemId}/updateItem`;
}

export function updateMenuSectionAPI(venueId, sectionId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/sections/${sectionId}/updateName`;
}

export function updateMenuSectionOrderAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/sectionOrder`;
}

export function deleteMenuItemAPI(venueId, itemId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/${itemId}/deleteItem`;
}

export function addItemToSectionAPI(venueId, sectionId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/sections/${sectionId}/addItem`;
}

export function uploadItemFileAPI(venueId, itemId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/${itemId}/upload`;
}

export function createDeliveryOrdersToggleAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/delivery-toggle`;
}

export function updateItemOptionAPI(venueId, itemId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/items/${itemId}/updateItemOption`;
}

export function updateOptionAPI(venueId, optionId) {
  return `${API_ENDPOINT}/venues/${venueId}/menu/options/${optionId}/updateOption`;
}

// ** QR Codes :
export function createVenueTablesAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/tables`;
}

export function deleteVenueTableAPI(venueId, tableId) {
  return `${API_ENDPOINT}/venues/${venueId}/table/${tableId}`;
}

export function updateVenueTableAPI(venueId) {
  return `${API_ENDPOINT}/venues/${venueId}/table`;
}
