import { connect } from "react-redux";
import Availability from "../components/Menu/Availability";
import {
  retrieveVenue,
  toggleSectionAvailability,
  toggleItemAvailability,
  toggleOptionAvailability,
  toggleVenueAvailability,
  createItem,
  updateItem,
  deleteItem,
  toggleDeliveryOrdersStatus,
} from "../redux/actions/venue";
import { updateNavBarTitle } from "../redux/actions/uiState";
import { get } from "lodash";

const mapStateToProps = (state) => {
  const {
    venue: {
      requests: {
        retrieveVenue: { processing: retrieveVenueProcessing },
      },
      requests: {
        updateAvailability: {
          processing: processingToggleAvailability,
          targetId: processingTargetId,
        },
      },
      venue,
    },
    uiState: { currentVenueId },
  } = state;
  return {
    retrieveVenueProcessing,
    processingToggleAvailability,
    venue,
    currentVenueId,
    processingTargetId,
    processingToggleDeliveryOrders: get(
      state,
      ["venue", "requests", "updateDeliveryStatus", "processing"],
      false
    ),
  };
};

const mapDispatchToProps = (dispatch, { appBarTitle }) => ({
  retrieveVenue: (venueId) => dispatch(retrieveVenue(venueId)),
  toggleVenueAvailability: (venueId, available) =>
    dispatch(toggleVenueAvailability(venueId, available)),
  toggleSectionAvailability: (venueId, sectionId, available) =>
    dispatch(toggleSectionAvailability(venueId, sectionId, available)),
  toggleItemAvailability: (venueId, itemId, available) =>
    dispatch(toggleItemAvailability(venueId, itemId, available)),
  toggleOptionAvailability: (venueId, optionId, available) =>
    dispatch(toggleOptionAvailability(venueId, optionId, available)),
  updateNavBarTitle: () => dispatch(updateNavBarTitle(appBarTitle)),
  createItem: (venueId, payload) => dispatch(createItem(venueId, payload)),
  updateItem: (venueId, itemId, payload) =>
    dispatch(updateItem(venueId, itemId, payload)),
  deleteItem: (venueId, itemId, sectionId, items) =>
    dispatch(deleteItem(venueId, itemId, sectionId, items)),
  toggleDeliveryOrdersStatus: (venueId, status) =>
    dispatch(toggleDeliveryOrdersStatus(venueId, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
