import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const OptionButtonIcon = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M89,0H10C4.477,0,0,4.477,0,10v79c0,5.521,4.477,10,10,10h79c5.521,0,10-4.479,10-10V10C99,4.477,94.521,0,89,0z    M75.952,36.461c0,0-31.607,31.849-31.607,31.977c-1.387,1.396-2.899,1.295-4.33-0.141l-15.43-15.625   c-1.562-1.432-1.596-2.863-0.098-4.297c1.496-1.431,2.96-1.431,4.395,0l13.281,13.086L71.46,31.969   c1.431-1.431,2.896-1.398,4.396,0.098C77.35,33.565,77.383,35.03,75.952,36.461z" />
    </g>
    <path
      display="none"
      d="M89.003,6.167c2.388,0,4.33,1.927,4.33,4.295v78.243c0,2.368-1.942,4.295-4.33,4.295H10.497  c-2.387,0-4.33-1.927-4.33-4.295V10.462c0-2.368,1.942-4.295,4.33-4.295H89.003 M89.003,0.167H10.497  c-5.705,0-10.33,4.609-10.33,10.295v78.243C0.167,94.391,4.792,99,10.497,99h78.506c5.704,0,10.33-4.609,10.33-10.295V10.462  C99.333,4.776,94.707,0.167,89.003,0.167L89.003,0.167z"
    />
    <g display="none">
      <path
        display="inline"
        d="M84.109,14.428C74.49,4.809,62.882,0,49.288,0C35.691,0,24.086,4.81,14.466,14.429   C4.847,24.048,0.038,35.657,0.038,49.25c0,13.598,4.81,25.202,14.429,34.82c9.62,9.621,21.225,14.43,34.822,14.43   c13.594,0,25.202-4.809,34.82-14.43c9.621-9.618,14.43-21.225,14.43-34.82C98.538,35.657,93.729,24.048,84.109,14.428z    M79.779,79.84c-8.4,8.465-18.563,12.697-30.491,12.697c-11.928,0-22.094-4.232-30.493-12.697   C10.393,71.374,6.194,61.178,6.194,49.25c0-11.927,4.199-22.091,12.601-30.493C27.194,10.358,37.36,6.156,49.288,6.156   c11.928,0,22.091,4.202,30.491,12.601c8.398,8.402,12.603,18.566,12.603,30.493C92.382,61.178,88.18,71.374,79.779,79.84z    M49.25,19.377c-16.592,0-30.042,13.45-30.042,30.042s13.45,30.043,30.042,30.043c16.591,0,30.042-13.451,30.042-30.043   C79.293,32.827,65.842,19.377,49.25,19.377z"
      />
    </g>
    <g display="none">
      <path
        display="inline"
        d="M84.109,14.428C74.49,4.809,62.882,0,49.288,0C35.691,0,24.086,4.81,14.466,14.429   C4.847,24.048,0.038,35.657,0.038,49.25c0,13.598,4.81,25.202,14.429,34.82c9.62,9.621,21.225,14.43,34.822,14.43   c13.594,0,25.202-4.809,34.82-14.43c9.621-9.618,14.43-21.225,14.43-34.82C98.538,35.657,93.729,24.048,84.109,14.428z    M79.779,79.84c-8.4,8.465-18.563,12.697-30.491,12.697c-11.928,0-22.094-4.232-30.493-12.697   C10.393,71.374,6.194,61.178,6.194,49.25c0-11.927,4.199-22.091,12.601-30.493C27.194,10.358,37.36,6.156,49.288,6.156   c11.928,0,22.091,4.202,30.491,12.601c8.398,8.402,12.603,18.566,12.603,30.493C92.382,61.178,88.18,71.374,79.779,79.84z"
      />
    </g>
  </SvgIcon>
);

export default OptionButtonIcon;
