import "normalize.css/normalize.css";
import "./index.css";

import React from "react";
import PropTypes from "prop-types";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import routes from "../../routes";
import AppBar from "../../containers/CustomAppBar";
import Refresh from "../Refresh";
import NotFound from "../../containers/NotFound";
import PrivateRoute from "../../containers/PrivateRoute";
import ErrorView from "../../containers/Error";
import NotificationModal from "../NotificationModal";
import PropsRoute from "../PropsRoute";
import Dialogs from "../../containers/Dialogs";
import { ToastContainer, toast } from "react-toastify";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.contentContainerRef = React.createRef();
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidMount() {
    const { bridgeInit } = this.props;
    bridgeInit();
  }

  UNSAFE_componentWillUpdate({ notifications: nextNotifications }) {
    const { notifications, closeNotificationById } = this.props;
    if (
      notifications &&
      nextNotifications &&
      notifications.length !== nextNotifications.length
    ) {
      nextNotifications
        .filter((notification) => notification.toast && notification.isOpen)
        .forEach((notification) => {
          toast.success(notification.message, {
            theme: "colored",
          });
          closeNotificationById(notification.id);
        });
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error, info } = this.state;
    const { userVenuesError, user, notifications, closeNotificationById } =
      this.props;
    let content;
    if (error) {
      content = <ErrorView error={error} info={info} />;
    } else if (userVenuesError) {
      content = <Refresh />;
    } else {
      content = (
        <div>
          <Switch>
            {Object.keys(routes).map((route) => {
              if (routes[route].public === true) {
                return (
                  <PropsRoute
                    key={route}
                    component={routes[route].component}
                    path={routes[route].url}
                    appBarTitle={routes[route].label}
                  />
                );
              }

              if (route === "home" && user) {
                return (
                  <PrivateRoute
                    key={route}
                    component={routes[route].component}
                    permissions={routes[route].permissions}
                    path={routes[route].url}
                    appBarTitle={routes[route].label}
                    exact
                  />
                );
              }

              return (
                <PrivateRoute
                  key={route}
                  component={routes[route].component}
                  permissions={routes[route].permissions}
                  path={routes[route].url}
                  appBarTitle={routes[route].label}
                  contentContainerRef={this.contentContainerRef}
                  exact
                />
              );
            })}
            {window.location.href.indexOf("index.html") >= 0 ? (
              <Redirect to="/orders" />
            ) : (
              <Route component={NotFound} />
            )}
          </Switch>
        </div>
      );
    }
    return (
      <div className="app-container">
        <Router>
          <div>
            <AppBar />
            {notifications
              .filter((notification) => notification.toast !== true)
              .map((notification) => {
                if (notification.message.includes("To-Go geändert")) {
                  const closeNotif = () =>
                    closeNotificationById(notification.id);
                  return (
                    <NotificationModal
                      key={notification.id}
                      isOpen={notification.isOpen}
                      closeNotificationModalAction={closeNotif}
                      info={{
                        message: "Bitte für die Mitnahme verpacken.",
                        status: notification.status,
                      }}
                      title={notification.message}
                      hideIcon
                      closeText="Close"
                      closeButtonStyle={{
                        backgroundColor: "#B9D47F",
                        color: "#2C2F3A",
                        fontWeight: "bold",
                        textTransform: "none",
                        fontSize: "1rem",
                        width: "40%",
                      }}
                      onCloseIcon={closeNotif}
                    />
                  );
                }
                return (
                  <NotificationModal
                    key={notification.id}
                    info={{
                      message: notification.message,
                      status: notification.status,
                    }}
                    isOpen={notification.isOpen}
                    closeNotificationModalAction={() =>
                      closeNotificationById(notification.id)
                    }
                  />
                );
              })}
            <div ref={this.contentContainerRef} className="content-container">
              {content}
              <ToastContainer autoClose={3000} />
            </div>
          </div>
        </Router>
        <Dialogs contentContainerRef={this.contentContainerRef} />
      </div>
    );
  }
}

App.propTypes = {
  user: PropTypes.object,
  userVenuesError: PropTypes.bool,
  notifications: PropTypes.array,
  bridgeInit: PropTypes.func.isRequired,
  closeNotificationById: PropTypes.func.isRequired,
};

App.defaultProps = {
  user: null,
  notifications: [],
  userVenuesError: null,
};

export default App;
