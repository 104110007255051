import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";

class ConfirmGlobalDisableDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (!open && nextProps.open) {
      this.setState({
        password: "",
      });
    }
  }

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  render() {
    const { password } = this.state;
    const {
      open,
      closeHandler,
      confirmHandler,
      passwordError,
      processingPassword,
    } = this.props;

    return (
      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        aria-labelledby="confirm-global-disable-dialog-title"
        onClose={closeHandler}
      >
        <DialogTitle id="confirm-global-disable-dialog-title">
          Bitte bestätige die Deaktivierung mit Deinem Passwort
        </DialogTitle>
        <form
          id="confirm-global-disable-form"
          onSubmit={(e) => {
            e.preventDefault();
            confirmHandler(password);
          }}
        >
          <DialogContent>
            <TextField
              error={passwordError}
              fullWidth
              label="Passwort"
              value={password}
              onChange={this.handlePasswordChange}
              type="password"
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              disabled={processingPassword}
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              form="confirm-global-disable-form"
            >
              Bestätigen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ConfirmGlobalDisableDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  passwordError: PropTypes.bool.isRequired,
  processingPassword: PropTypes.bool.isRequired,
};

export default ConfirmGlobalDisableDialog;
