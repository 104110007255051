import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import routes from "../../routes";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavMenuItem from "./sub-components/NavMenuItem";
import "./Nav.css";
import { isAuthorized } from "../../lib/routes";
import { resetUser } from "../../lib/analytics";
import { version } from "../../../package.json";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venuesCardExpanded: false,
    };
  }

  handleVenuesCardExpandChange = (expanded) => {
    this.setState({
      venuesCardExpanded: expanded,
    });
  };

  handleLogout = async (e) => {
    const { closeHandler, logout, updateCurrentVenue } = this.props;
    e.preventDefault();
    closeHandler();
    resetUser();
    updateCurrentVenue(null);
    logout();
  };

  handleVenueChange = (venueId) => {
    const { retrieveVenue, closeHandler, history, updateCurrentVenue } =
      this.props;
    retrieveVenue(venueId);
    updateCurrentVenue(venueId);
    // update UI
    this.handleVenuesCardExpandChange(false);
    closeHandler();
    // history.replace(history.location.pathname);
    history.push("/");
  };

  render() {
    let content;
    const { closeHandler, open, currentVenueId, user, venues } = this.props;
    const { venuesCardExpanded } = this.state;
    if (user && venues) {
      const venueDropDownEnabled = venues && Object.keys(venues).length > 1;
      content = (
        <div>
          <Card
            style={{
              backgroundColor: "#e0e0e0",
              boxShadow: "none",
              borderRadius: "0px",
            }}
          >
            <CardHeader
              title={user.displayName}
              subheader={
                venues && venues[currentVenueId] && venues[currentVenueId].name
              }
              action={
                venueDropDownEnabled ? (
                  <IconButton
                    style={{
                      transform: venuesCardExpanded
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      marginLeft: "auto",
                    }}
                    onClick={() =>
                      this.handleVenuesCardExpandChange(!venuesCardExpanded)
                    }
                    aria-label="Show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                ) : null
              }
            />
            {venueDropDownEnabled ? (
              <Collapse in={venuesCardExpanded} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: 0 }}>
                  <List
                    style={{
                      padding: 0,
                      backgroundColor: "#fff",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {Object.keys(venues).map((venue) => (
                      <ListItem
                        onClick={() => this.handleVenueChange(venue)}
                        style={{ fontSize: "0.9em" }}
                        key={venue}
                        button
                      >
                        {venues[venue].name}
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Collapse>
            ) : null}
          </Card>
          {Object.keys(routes)
            .filter(
              (route) =>
                user &&
                venues &&
                isAuthorized(
                  venues[currentVenueId],
                  routes[route].permissions
                ) &&
                !routes[route].posOnly
            )
            .map(
              (route) =>
                route !== "login" &&
                routes[route].icon && (
                  <Link
                    to={routes[route].url}
                    href={routes[route].url}
                    key={route}
                  >
                    <NavMenuItem
                      key={route}
                      icon={routes[route].icon}
                      closeHandler={closeHandler}
                      label={routes[route].label}
                    />
                  </Link>
                )
            )}
          <Divider />
          <ListItem button onClick={this.handleLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>Ausloggen</ListItemText>
          </ListItem>
        </div>
      );
    } else {
      content = (
        <Link to={routes.login.url} href={routes.login.url}>
          <NavMenuItem
            icon={routes.login.icon}
            closeHandler={closeHandler}
            label={routes.login.label}
          />
        </Link>
      );
    }

    return (
      <div>
        <Drawer width={250} open={open} onClose={closeHandler}>
          {content}
          <Divider />
          <div className="info-container">
            <p className="info-header">Support Hotline:</p>
            <p className="info-value">+49-2151-4498389</p>
          </div>
          <div className="info-container">
            <p className="info-header">Support E-Mail:</p>
            <p className="info-value">partner@orda-app.com</p>
          </div>
          <div className="info-container">
            <p className="info-header">Version</p>
            <p className="info-value">{version}</p>
          </div>
        </Drawer>
      </div>
    );
  }
}

Nav.contextTypes = {
  router: PropTypes.object,
};

Nav.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  retrieveVenue: PropTypes.func.isRequired,
  updateCurrentVenue: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  venues: PropTypes.object,
};

Nav.defaultProps = {
  currentVenueId: null,
  user: null,
  venues: null,
};

export default withRouter(Nav);
