/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import { REHYDRATE } from "redux-persist";
import { cloneDeep, get } from "lodash";

export const initialState = {
  requests: {
    retrieveVenue: {
      processing: false,
      error: null,
    },
    updateAvailability: {
      processing: false,
      error: null,
    },
    updateVenueTraffic: {
      processing: false,
      error: null,
    },
    updateDeliveryStatus: {
      processing: false,
      error: null,
    },
  },
  venue: null,
  automaticVenueTraffic: false,
};

function removeDeletedItemFromMenuState(state, itemId, sectionId) {
  const menuCopy = cloneDeep(get(state, "venue.menu", { sections: {} }));
  const { sections } = menuCopy;
  const items = [...sections[sectionId].items];
  items.splice(sections[sectionId].items.indexOf(itemId), 1);
  sections[sectionId].items = items;
  return menuCopy;
}

export default function venueReducer(state = initialState, action) {
  switch (action.type) {
    case types.venue.RETRIEVE_VENUE.REQUEST:
      return produce(state, (newState) => {
        newState.requests.retrieveVenue.processing = true;
        newState.requests.retrieveVenue.error = null;
      });
    case types.venue.RETRIEVE_VENUE.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.retrieveVenue.processing = false;
        newState.venue = action.venue;
      });
    case types.venue.RETRIEVE_VENUE.FAILURE:
      return produce(state, (newState) => {
        newState.requests.retrieveVenue.processing = false;
        newState.requests.retrieveVenue.error = action.error;
      });
    case types.venue.TOGGLE_VENUE_AVAILABILITY.REQUEST:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = true;
        newState.requests.updateAvailability.error = null;
      });
    case types.venue.TOGGLE_VENUE_AVAILABILITY.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.venue.maintenance = !action.available;
      });
    case types.venue.TOGGLE_VENUE_AVAILABILITY.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.error = action.error;
      });
    case types.venue.UDPATE_VENUE_TRAFFIC.REQUEST:
      return produce(state, (newState) => {
        newState.requests.updateVenueTraffic.processing = true;
        newState.requests.updateVenueTraffic.error = null;
      });
    case types.venue.UDPATE_VENUE_TRAFFIC.SUCCESS:
      return produce(state, (newState) => {
        if (!newState.venue) {
          return;
        }
        if (!newState.venue.traffic) {
          newState.venue.traffic = {};
        }
        newState.requests.updateVenueTraffic.processing = false;
        newState.venue.traffic.status = action.status;
        newState.venue.traffic.lastUpdate = action.lastUpdate;
      });
    case types.venue.UDPATE_VENUE_TRAFFIC.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateVenueTraffic.processing = false;
        newState.requests.updateVenueTraffic.error = action.error;
      });
    case types.venue.TOGGLE_SECTION_AVAILABILITY.REQUEST:
      return produce(state, (newState) => {
        if (!newState.requests.updateAvailability) {
          newState.requests.updateAvailability = {};
        }
        newState.requests.updateAvailability.processing = true;
        newState.requests.updateAvailability.targetId = action.sectionId;
        newState.requests.updateAvailability.error = null;
      });
    case types.venue.TOGGLE_SECTION_AVAILABILITY.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.targetId = null;
        newState.venue.menu.sections[action.sectionId].items.forEach(
          (itemId) => {
            newState.venue.menu.items[itemId].unavailable = !action.available;
          }
        );
      });
    case types.venue.TOGGLE_SECTION_AVAILABILITY.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.error = action.error;
      });
    case types.venue.TOGGLE_ITEM_AVAILABILITY.REQUEST:
      return produce(state, (newState) => {
        if (!newState.requests.updateAvailability) {
          newState.requests.updateAvailability = {};
        }
        newState.requests.updateAvailability.processing = true;
        newState.requests.updateAvailability.targetId = action.itemId;
        newState.requests.updateAvailability.error = null;
      });
    case types.venue.TOGGLE_ITEM_AVAILABILITY.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.targetId = null;
        newState.venue.menu.items[action.itemId].unavailable =
          !action.available;
      });
    case types.venue.TOGGLE_ITEM_AVAILABILITY.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.error = action.error;
      });
    case types.venue.TOGGLE_OPTION_AVAILABILITY.REQUEST:
      return produce(state, (newState) => {
        if (!newState.requests.updateAvailability) {
          newState.requests.updateAvailability = {};
        }
        newState.requests.updateAvailability.processing = true;
        newState.requests.updateAvailability.targetId = action.optionId;
        newState.requests.updateAvailability.error = null;
      });
    case types.venue.TOGGLE_OPTION_AVAILABILITY.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.targetId = null;
        newState.venue.menu.options[action.optionId].unavailable =
          !action.available;
      });
    case types.venue.TOGGLE_OPTION_AVAILABILITY.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateAvailability.processing = false;
        newState.requests.updateAvailability.error = action.error;
      });
    case types.venue.AUTOMATIC_VENUE_TRAFFIC.ENABLE:
      return produce(state, (newState) => {
        newState.automaticVenueTraffic = true;
      });
    case types.venue.AUTOMATIC_VENUE_TRAFFIC.DISABLE:
      return produce(state, (newState) => {
        newState.automaticVenueTraffic = false;
      });
    case types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.REQUEST:
      return produce(state, (newState) => {
        newState.requests.updateDeliveryStatus.processing = true;
      });
    case types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.updateDeliveryStatus.processing = false;
        newState.venue.deliveryEnabled = action.available;
      });
    case types.venue.TOGGLE_DELIVERY_ORDERS_STATUS.FAILURE:
      return produce(state, (newState) => {
        newState.requests.updateDeliveryStatus.processing = false;
        newState.requests.updateDeliveryStatus.error = action.error;
      });
    case types.venue.VENUE_DELETE_ITEM.SUCCESS: {
      const { itemId, sectionId } = action;
      return produce(state, (newState) => {
        newState.venue.menu = removeDeletedItemFromMenuState(
          state,
          itemId,
          sectionId
        );
      });
    }

    case REHYDRATE:
      return produce(state, (newState) => {
        const savedTrafficState =
          action.payload &&
          action.payload.venue &&
          action.payload.venue.automaticVenueTraffic;
        if (savedTrafficState) {
          newState.automaticVenueTraffic = savedTrafficState;
        }
      });
    default:
      return state;
  }
}
