export default {
  RETRIEVE_ORDERS: {
    REQUEST: "RETRIEVE_ORDERS.REQUEST",
    SUCCESS: "RETRIEVE_ORDERS.SUCCESS",
    FAILURE: "RETRIEVE_ORDERS.FAILURE",
  },
  MARK_ORDER_AS_DONE: {
    REQUEST: "MARK_ORDER_AS_DONE.REQUEST",
    SUCCESS: "MARK_ORDER_AS_DONE.SUCCESS",
    FAILURE: "MARK_ORDER_AS_DONE.FAILURE",
  },
  REPRINT_ORDER: {
    REQUEST: "REPRINT_ORDER.REQUEST",
    SUCCESS: "REPRINT_ORDER.SUCCESS",
    FAILURE: "REPRINT_ORDER.FAILURE",
  },
  ACKNOWLEDGE_ORDER: {
    REQUEST: "ACKNOWLEDGE_ORDER.REQUEST",
    SUCCESS: "ACKNOWLEDGE_ORDER.SUCCESS",
    FAILURE: "ACKNOWLEDGE_ORDER.FAILURE",
  },
  UPDATE_ORDER_TOGO: "UPDATE_ORDER_TOGO",
  UPDATE_ORDERS: "UPDATE_ORDERS",
  ORDER: {
    CHECKIN: "ORDER.CHECKIN",
    CHECK_ORDERED: "CHECK_ORDERED",
    CHECK_ORDERED_SUCCESS: "CHECK_ORDERED_SUCCESS",
    CHECK_ORDERED_FAILURE: "CHECK_ORDERED_FAILURE",
  },
  PURGE_ORDER_REQUEST: "PURGE_ORDER_REQUEST",
  PURGE_ALL_PENDING_ORDER_REQUESTS: "PURGE_ALL_PENDING_ORDER_REQUESTS",
};
