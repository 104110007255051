import * as types from "../types";

export const updateNavBarTitle = (navBarTitle) => ({
  type: types.uiState.UPDATE_NAVBAR_TITLE,
  navBarTitle,
});

export const setAppBarHidden = (appBarHidden) => ({
  type: types.uiState.SET_NAVBAR_HIDDEN,
  appBarHidden,
});

export const updateCurrentVenue = (venueId) => {
  localStorage.setItem("lastVenueId", venueId);
  return {
    type: types.uiState.UPDATE_CURRENT_VENUE_ID,
    venueId,
  };
};

export const incrementBuildNumberTapCount = () => ({
  type: types.uiState.INCREMENT_BUILD_NUMBER_TAP_COUNT,
});

export const resetBuildNumberTapCount = () => ({
  type: types.uiState.RESET_BUILD_NUMBER_TAP_COUNT,
});

export const toggleStatusDialog = (status) => ({
  type: types.uiState.DIALOGS.STATUS.TOGGLE,
  status,
});

export const dismissCheckInDialog = (orderId) => ({
  type: types.uiState.DIALOGS.CHECKIN.DISMISS,
  orderId,
});

export const showBatteryDialog = () => ({
  type: types.uiState.DIALOGS.BATTERY_WARNING.SHOW,
});

export const dismissBatteryDialog = () => ({
  type: types.uiState.DIALOGS.BATTERY_WARNING.DISMISS,
});

export const setPrintersInitialized = () => ({
  type: types.uiState.PRINTERS_INITIALIZED,
});
