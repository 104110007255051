import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { SocketEvent } from "@orda/shared-constants/backoffice/order-events";
import MenuIcon from "@material-ui/icons/Menu";

import Nav from "../../containers/Nav";
import addSoundListenerOnEvents from "../../lib/sound-events";
import "./CustomAppBar.css";

addSoundListenerOnEvents(
  SocketEvent.NewOrder,
  SocketEvent.OrderReminder,
  SocketEvent.GroupOrder
);

const customTitle = (
  <Link to="/" href="/">
    <span className="orda-icon-ORDA" />
  </Link>
);

class CustomAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  handleDrawerToggle = () => {
    const { drawerOpen } = this.state;
    this.setState({
      drawerOpen: !drawerOpen,
    });
  };

  handleRequestChange = (open) => {
    this.setState({
      drawerOpen: open,
    });
  };

  handleClose = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  render() {
    const { drawerOpen } = this.state;
    const { t, appBarHidden, navBarTitle } = this.props;
    return (
      <div>
        {!appBarHidden ? (
          <AppBar
            position="static"
            color="secondary"
            style={{ boxShadow: "none" }}
          >
            <Toolbar variant="dense" className="toolbar">
              <IconButton
                onClick={this.handleDrawerToggle}
                aria-label="Menu"
                disableRipple
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                {t(navBarTitle) || customTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        ) : null}
        <Nav
          open={drawerOpen}
          closeHandler={this.handleClose}
          requestChangeHandler={this.handleRequestChange}
        />
      </div>
    );
  }
}

CustomAppBar.contextTypes = {
  router: PropTypes.object,
};

CustomAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  appBarHidden: PropTypes.bool.isRequired,
  navBarTitle: PropTypes.string,
};

CustomAppBar.defaultProps = {
  navBarTitle: null,
};

export default withTranslation("routeLabels")(CustomAppBar);
