import React from "react";
import PropTypes from "prop-types";
import PulseLoader from "../../PulseLoader";
import Toggle from "../../CustomToggle";
import { THEME } from "../../../util/theme";
import { Edit } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const styles = {
  sectionHeaderStyle: {
    fontSize: "0.9em",
    color: THEME.WHITE_TEXT_COLOR,
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 12,
    backgroundColor: "#494949",
    width: "100%",
  },
};

const SectionHeader = ({
  venue,
  processingTargetId,
  id,
  processingToggleAvailability,
  name,
  toggleSectionAvailability,
  updateSection,
}) => {
  const sectionToggled = venue.menu.sections[id].items.reduce(
    (acc, current) => !venue.menu.items[current].unavailable || acc,
    false
  );

  const toggle =
    processingTargetId === id ? (
      <PulseLoader />
    ) : (
      <Toggle
        toggled={sectionToggled}
        disabled={processingToggleAvailability || venue.maintenance}
        onToggle={({ target: { checked } }) => {
          toggleSectionAvailability(venue.id, id, checked);
        }}
      />
    );

  return (
    <div style={styles.sectionHeaderStyle}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>{name}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={updateSection}
            type="button"
            style={{
              color: THEME.WHITE_TEXT_COLOR,
              fontWeight: "bold",
              backgroundColor: "transparent",
              border: 0,
              cursor: "pointer",
            }}
          >
            <Edit />
          </IconButton>
          {toggle}
        </div>
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  venue: PropTypes.object,
  toggleSectionAvailability: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  processingTargetId: PropTypes.string,
  processingToggleAvailability: PropTypes.bool.isRequired,
};

SectionHeader.defaultProps = {
  venue: null,
  processingTargetId: null,
};

export default SectionHeader;
