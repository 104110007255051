import { put, retry, select, takeEvery } from "redux-saga/effects";
import {
  statusLoadingFinished,
  storePrinterConfig,
  updatePrinterConfigSuccess,
} from "../actions/status";
import * as types from "../types";
import { send } from "../actions/bridge";
import { ToNativeEvent } from "@orda/shared-constants/backoffice/tablet-bridge-events";

function* checkStatusSaga() {
  const statusMap = yield select((state) => state.status.data);
  const finished = Object.keys(statusMap).reduce(
    (acc, key) => acc && statusMap[key].status,
    true
  );

  if (finished) {
    return true;
  }
  throw new Error(`[STATUS]: all status map items are not online`);
}

export function* statusLoadingSaga() {
  try {
    yield retry(15, 1000, checkStatusSaga);
  } catch (error) {
    // loading did not finish
    // we still need to mark loading as finished
  }
  yield put(statusLoadingFinished());
}

function* updatePrinterConfigSaga(action) {
  const { updatedPrinters, oldPrinters } = action;

  // Save updated printers to local store
  yield put(storePrinterConfig(updatedPrinters));

  // save the updated information in the store
  for (let i = 0; i < updatedPrinters.length; i++) {
    const { id: printerIdFromUpdate, address: newAddress } = updatedPrinters[i];
    const { id: printerIdFromOld } = oldPrinters[i];

    if (printerIdFromOld !== printerIdFromUpdate) {
      throw new Error("Printers could not be updated!");
    }

    // Send down to native layer
    yield put(
      send(ToNativeEvent.UpdatePrinterConfig, {
        printerId: printerIdFromOld,
        address: newAddress === "none" ? "" : newAddress,
      })
    );
  }

  yield put(updatePrinterConfigSuccess());
}

export default function* statusRootSaga() {
  yield takeEvery(types.status.UPDATE_PRINTER_CONFIG, updatePrinterConfigSaga);
}
