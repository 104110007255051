export default {
  RETRIEVE_CAMPAIGNS: {
    REQUEST: "RETRIEVE_CAMPAIGNS.REQUEST",
    SUCCESS: "RETRIEVE_CAMPAIGNS.SUCCESS",
    FAILURE: "RETRIEVE_CAMPAIGNS.FAILURE",
  },
  UPDATE_CAMPAIGN_STATUS: {
    REQUEST: "UPDATE_CAMPAIGN_STATUS.REQUEST",
    SUCCESS: "UPDATE_CAMPAIGN_STATUS.SUCCESS",
    FAILURE: "UPDATE_CAMPAIGN_STATUS.FAILURE",
  },
  CREATE_CAMPAIGN: {
    RESET: "CREATE_CAMPAIGN.RESET",
    REQUEST: "CREATE_CAMPAIGN.REQUEST",
    SUCCESS: "CREATE_CAMPAIGN.SUCCESS",
    FAILURE: "CREATE_CAMPAIGN.FAILURE",
  },
};
