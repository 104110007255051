import amplitude from "amplitude-js";
import { AMPLITUDE_API_KEY } from "../../config";
import { store } from "../../redux/store";

amplitude.getInstance().init(AMPLITUDE_API_KEY);

export const logEvent = (name, properties) => {
  try {
    const venueId = store.getState().uiState.currentVenueId;
    const allVenues = store.getState().auth.venues;

    const finalProperties = { ...properties };
    if (venueId && allVenues) {
      finalProperties.venueId = venueId;
      finalProperties.venueName = allVenues[venueId].name;
    }

    amplitude
      .getInstance()
      .logEventWithTimestamp(name, finalProperties, Date.now());
    // amplitude.logEvent(name, properties);
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(`Event: ${JSON.stringify({ name, finalProperties })}`);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};

export const setUserId = (userId) => {
  try {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `UserId: ${JSON.stringify({
          userId,
        })}`
      );
    }
    amplitude.getInstance().setUserId(userId);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};

export const resetUser = () => {
  try {
    amplitude.getInstance().regenerateDeviceId();
    amplitude.getInstance().setUserId(null);
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log("Reset Amplitude User");
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};
