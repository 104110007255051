import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { useDispatch } from "react-redux";
import { addExistingOptionToOption } from "../../../redux/actions/venue";

interface SelectOptionDialogProps {
  options: any;
  open: boolean;
  setOpen: any;
  optionId: any;
  venueId: any;
  option: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function SelectOptionDialog(props: SelectOptionDialogProps) {
  const { venueId, optionId, options, open, setOpen, option } = props;
  const { t } = useTranslation("updateDish");

  const [newOptionId, setNewOptionId] = useState<string>();
  const handleClose = (): any => setOpen(false);
  const dispatch = useDispatch();

  const handleSave = (): void => {
    dispatch(addExistingOptionToOption(venueId, optionId, option, newOptionId));
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Wähle eine Option</DialogTitle>
      <DialogContent className="create-option-dialog">
        <FormControl style={{ width: "85%" }}>
          <InputLabel id="select-option-mutiple-chip-label">
            {t("selectOptionPlaceholder")}
          </InputLabel>
          <Select
            id="select-option-mutiple-chip"
            value={optionId}
            onChange={(event) => setNewOptionId(event.target.value)}
            input={<Input id="select-multiple-chip" />}
            renderValue={() => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {options.find((obj: any) => obj.id === newOptionId)?.label}
              </div>
            )}
            required
            MenuProps={MenuProps}
          >
            {options.map(({ id, label }: any) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            if (optionId !== "") {
              handleSave();
            } else {
              handleClose();
            }
          }}
          color="primary"
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
