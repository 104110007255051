import React from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setTableNumber as setTableNumberAction } from "../../../redux/actions/tables";
import { useTranslation } from "react-i18next";
import { Add, Remove } from "@material-ui/icons";

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: 5,
  },
};

interface NumberSelectorProps {
  classes: any;
}

const NumberSelector: React.FC<NumberSelectorProps> = ({
  classes,
}): ReturnType<React.FC<NumberSelectorProps>> => {
  const { t } = useTranslation("qrCodes");
  const dispatch = useDispatch();
  const { currentTableNumber } = useSelector((state: any) => state.tables);
  const setTableNumber = (number: number): void => {
    dispatch(setTableNumberAction(number));
  };

  return (
    <div className={classes.container}>
      <Button
        onClick={() =>
          setTableNumber(
            +currentTableNumber - 1 < 0 ? 0 : +currentTableNumber - 1
          )
        }
      >
        <Remove />
      </Button>
      <div className={classes.inputContainer}>
        <Typography className={classes.inputLabel}>
          {t("numberInputLabel")}
        </Typography>
        <TextField
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
              inputMode: "numeric",
            },
          }}
          value={currentTableNumber}
          onChange={(event) =>
            setTableNumber(
              +(!Number.isNaN(Number(event.target.value))
                ? event.target.value
                : currentTableNumber)
            )
          }
        />
      </div>
      <Button onClick={() => setTableNumber(+currentTableNumber + 1)}>
        <Add />
      </Button>
    </div>
  );
};

NumberSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberSelector);
