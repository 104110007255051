import * as types from "../types";

export const retrieveSales = (venueId, start, end) => ({
  type: types.sales.RETRIEVE_SALES.REQUEST,
  venueId,
  start,
  end,
});

export const retrieveSalesSuccess = (data) => ({
  type: types.sales.RETRIEVE_SALES.SUCCESS,
  data,
});

export const retrieveSalesFailure = (error) => ({
  type: types.sales.RETRIEVE_SALES.FAILURE,
  error,
});
