import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NotificationModal from "../../../../NotificationModal";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ArrowUp from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import MenuIcon from "../../../../../assets/icons/menu.svg";

// Map the videos to a format of video draggable item
const getVideoItems = (itemVideos) =>
  itemVideos.map((video, index) => ({
    id: `item-${index}`,
    content: `Video ${index + 1}`,
    data: video,
  }));

// A little function to help with reorder the video results
const reorderVideoItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
// Pixel Size of the spacing between video items
const grid = 8;

const getVideoItemStyle = (isDragging, draggableStyle) => ({
  // Basic styles
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  boxShadow: "0px 0px 2px 0px",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  // Change background colour if dragging
  background: isDragging ? "grey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getVideosListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightgrey" : "transparent",
  padding: grid,
  width: "100%",
});

class DraggableVideos extends Component {
  constructor(props) {
    super(props);
    // Items from the parent component that handle the update video action
    const { itemVideos } = props;
    this.state = {
      isDeleteModalOpen: false,
      currentIndex: null,
      items: getVideoItems(itemVideos),
      playState: new Array(itemVideos.length).fill(false),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { items: currentItems, playState } = this.state;
    const { itemVideos } = this.props;
    if (nextProps.itemVideos.length > itemVideos.length) {
      this.setState({
        items: [
          ...currentItems,
          {
            id: `item-${currentItems.length}`,
            content: `Video ${currentItems.length + 1}`,
            data: nextProps.itemVideos[nextProps.itemVideos.length - 1],
          },
        ],
        playState: [...playState, false],
      });
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const { setItemVideos, itemVideos: currentItemVideos } = this.props;
    const { items: currentItems } = this.state;

    // Items from the state
    const items = reorderVideoItems(
      currentItems,
      result.source.index,
      result.destination.index
    );
    // Items from the parent
    const itemVideos = reorderVideoItems(
      currentItemVideos,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
      playState: new Array(items.length).fill(false),
    });
    setItemVideos([...itemVideos]);
  };

  onDelete = (index) => () => {
    const { setItemVideos, itemVideos: currentItemVideos } = this.props;
    const { items, playState } = this.state;
    const currentItems = [...items];
    const currentPlayState = [...playState];
    currentItemVideos.splice(index, 1);
    currentItems.splice(index, 1);
    currentPlayState.splice(index, 1);
    setItemVideos([...currentItemVideos]);
    this.setState({
      items: currentItems,
      playState: currentPlayState,
    });
  };

  togglePlay = (index) => () => {
    const { playState } = this.state;
    const currentPlayState = [...playState];
    currentPlayState[index] = !currentPlayState[index];
    this.setState({
      playState: currentPlayState,
    });
  };

  render() {
    const { items, playState, isDeleteModalOpen, currentIndex } = this.state;
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(rootProvider, snapshot1) => (
              <div
                {...rootProvider.droppableProps}
                ref={rootProvider.innerRef}
                style={getVideosListStyle(snapshot1.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getVideoItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <IconButton
                            style={{ padding: 4 }}
                            onClick={this.togglePlay(index)}
                          >
                            {playState[index] ? (
                              <ArrowUp style={{ width: "25px" }} />
                            ) : (
                              <ArrowDown style={{ width: "25px" }} />
                            )}
                          </IconButton>
                          {item.content}
                          <div
                            style={{
                              display: "flex",
                              gap: "2vw",
                            }}
                          >
                            <IconButton
                              style={{ padding: 4 }}
                              onClick={() => {
                                this.setState({
                                  isDeleteModalOpen: true,
                                  currentIndex: index,
                                });
                              }}
                            >
                              <img
                                alt="delete"
                                style={{ width: "25px" }}
                                src={DeleteIcon}
                              />
                            </IconButton>
                            <img
                              alt="menu"
                              style={{ width: "25px" }}
                              src={MenuIcon}
                            />
                          </div>
                        </div>
                        {playState[index] && (
                          <div
                            style={{
                              marginTop: "-8px",
                              marginBottom: "16px",
                              backgroundColor: "lightgray",
                              marginLeft: "8px",
                              marginRight: "8px",
                              borderBottomRightRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                          >
                            <video
                              src={
                                item.data.url ||
                                URL.createObjectURL(item.data.file)
                              }
                              width="100%"
                              height="140vh"
                              muted
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
                {rootProvider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <NotificationModal
          isOpen={isDeleteModalOpen}
          info={{
            status: "idle",
            message:
              "Bist Du sicher, dass Du das Video löschen möchtest? Dieser Schritt kann nicht rückgängig gemacht werden. ",
          }}
          title="Video löschen"
          hideIcon
          closeText="Bestätigen"
          closeButtonStyle={{
            backgroundColor: "#B9D47F",
            color: "#2C2F3A",
            fontWeight: "bold",
            textTransform: "none",
            fontSize: "1.2rem",
            width: "40%",
          }}
          onCloseIcon={() => {
            this.setState({
              isDeleteModalOpen: false,
              currentIndex: null,
            });
          }}
          closeNotificationModalAction={() => {
            this.setState({
              isDeleteModalOpen: false,
              currentIndex: null,
            });
            this.onDelete(currentIndex)();
          }}
        />
      </>
    );
  }
}

DraggableVideos.propTypes = {
  itemVideos: PropTypes.array,
  setItemVideos: PropTypes.func.isRequired,
  updateItemFiles: PropTypes.func.isRequired,
};

DraggableVideos.defaultProps = {
  itemVideos: [],
};

export default DraggableVideos;
