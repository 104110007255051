import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const DeletePrompt = ({ open, confirm, cancel, title, content }) => {
  return (
    <Dialog
      open={open}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <div
          style={{
            width: "45%",
            display: "inline-block",
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              height: 55,
              backgroundColor: "#B9D47F",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "none",
            }}
            onClick={cancel}
            type="button"
          >
            Abbrechen
          </Button>
        </div>
        <div
          style={{
            width: "45%",
            marginLeft: "10%",
            display: "inline-block",
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{
              height: 55,
              backgroundColor: "#f44336",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
            }}
            type="button"
            onClick={confirm}
          >
            Bestätigen
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

DeletePrompt.propTypes = {
  open: PropTypes.bool.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
};

export default DeletePrompt;
