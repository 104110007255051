import React from "react";
import { Route, Redirect } from "react-router-dom";

import OrderReroute from "../OrderReroute";
import PropTypes from "prop-types";
import Loading from "../Loading";
import { isAuthorized } from "../../lib/routes";
// import { isAuthorized } from "../../lib/routes";

const renderProtectedComponent = (
  component,
  routeProps,
  user,
  venues,
  currentVenueId,
  permissions,
  ...rest
) => {
  if (
    user &&
    venues &&
    currentVenueId &&
    isAuthorized(venues[currentVenueId], permissions)
  ) {
    const finalProps = Object.assign({}, routeProps, ...rest);
    return React.createElement(component, finalProps);
  }

  if (
    user &&
    venues &&
    currentVenueId &&
    !isAuthorized(venues[currentVenueId], permissions)
  ) {
    return (
      <Redirect
        to={{ pathname: "/unauthorised", state: { from: routeProps.location } }}
      />
    );
  }

  return <Loading />;
};

const PrivateRoute = ({
  component,
  permissions,
  currentVenueId,
  user,
  venues,
  processingLogin,
  processingLogout,
  syncFinished,
  ...rest
}) => (
  <Route
    {...rest}
    render={(routeProps) => {
      if (user) {
        return (
          <div>
            {renderProtectedComponent(
              component,
              routeProps,
              user,
              venues,
              currentVenueId,
              permissions,
              rest
            )}
            {window.isInsideWebView ? <OrderReroute /> : null}
          </div>
        );
      }
      if (processingLogout || processingLogin || !syncFinished) {
        return <Loading />;
      }
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: routeProps.location } }}
        />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func,
  permissions: PropTypes.array,
  currentVenueId: PropTypes.string,
  user: PropTypes.object,
  venues: PropTypes.object,
  processingLogin: PropTypes.bool.isRequired,
  processingLogout: PropTypes.bool.isRequired,
  syncFinished: PropTypes.bool.isRequired,
  processingUserVenues: PropTypes.bool.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
  permissions: null,
  currentVenueId: null,
  user: null,
  venues: null,
};

export default PrivateRoute;
