/* eslint-disable  @typescript-eslint/camelcase */
export default {
  common: {
    number: "{{data, number}}",
    money: "{{data, money}}",
    percent: "{{data, percent}}",
    "time-llll": "{{time, time-llll}}",
    duration: "{{duration, duration}}",
  },
  routeLabels: {
    home: "Home",
    availability: "Speisekarte",
    menu: "Speisekarte",
    orders: "Bestellungen",
    sales: "Umsätze",
    activityLog: "Aktivität",
    login: "Anmeldung",
    campaigns: "Kampagnen",
    settings: "Einstellungen",
    tables: "QR-Code",
  },
  marketing: {
    retrieveCampaignsError: "Fehler beim Abrufen von Marketingkampagnen",
    startDate: "Startdatum",
    timeRange: "Uhrzeit",
    discountAmount: "Rabatthöhe",
    status: "Status",
    description: "Beschreibung",
    inReview: "In Bearbeitung",
    benefitAlwaysAvailable: "Ganztägig",
    createCampaign: "Neu Kampagne erstellen",
  },
  createCampaign: {
    nameLabel: "Name",
    namePlaceholder: "Pizzas at ORDA Restaurant",
    allDayLabel: "Ganztägig",
    fromTimeLabel: "Von",
    fromTimePlaceholder: "13:00",
    toTimeLabel: "Bis",
    toTimePlaceholder: "15:00",
    amountLabel: "Rabatthöhe",
    amountPlaceholder: "30",
    noteLabel: "Details",
    notePlaceholder:
      "Bitte gib hier weitere Details und Wünsche zu Deiner Rabattaktion an wie z.B.\n\n- Nicht alle Produkte sollen rabattiert werden.\n- Die Aktion soll nur an bestimmten Wochentagen stattfinden.",
    explanation:
      "Nach Abschicken dieser Rabattaktion werden wir diese für dich aufsetzen und zu den gewünschten Konditionen schnellstmöglich starten. Dies geschieht in der Regel innerhalb von 24h an Werktagen. Wir werden uns bei Rückfragen gegebenenfalls bei dir melden.",
    createButtonLabel: "Abschicken",
    timePickerCancelLabel: "Abbrechen",
    timePickerOkLabel: "Bestätigen",
  },
  settings: {
    printer: "Drucker",
    bluetoothAddress: "Bluetooth-Adresse",
    noPrinterSelected: "Kein Drucker",
    testPrint: "Test Druck",
    saveButton: "Speichern",
    noPhysicalPrinter:
      "Kein Drucker gefunden. Bitte verbinde einen Drucker mit dem Tablet per Bluetooth.",
  },
  venueAvailability: {
    ordersAvailability: "ORDA Bestellungen",
    addDish: "Produkt hinzufügen",
    editItem: "Bearbeiten",
    addItem: "Neues Produkt Hinzufügen",
    addSection: "Warengruppe Hinzufügen",
    addExistingItem: "Existierendes Produkt Hinzufügen",
  },
  updateDish: {
    editItemTitle: "Produkt bearbeiten",
    namePlaceholder: "Name",
    nameEnPlaceholder: "Name (English)",
    nameDePlaceholder: "Name (Deutsch)",
    descriptionPlaceholder: "Beschreibung",
    descriptionEnPlaceholder: "Bechreibung (English)",
    descriptionDePlaceholder: "Bechreibung (Deutsch)",
    tagsPlaceholder: "Tags / Allergene",
    sectionPlaceholder: "Warengruppen",
    budgetPlaceholder: "Ausgaben Deines Einkaufs in Euro",
    pricePlaceholder: "Preis in Euro",
    vatPlaceholder: "MwSt. im Haus",
    vatToGoPlaceholder: "MwSt. ausser Haus",
    orderModusPlaceholder: "Bestellmöglichkeiten",
    addonPlaceholder: "Produkt ist ein Options-Artikel",
    priceDisplayPlaceholder: "Preisdarstellung",
    updateItemTab: "Artikel-Daten",
    updateOptionTab: "Artikel-Optionen",
    selectOptionPlaceholder: "Suche eine Option aus",
    addExistingOption: "Wähle eine Option",
    portionsAmountPlaceholder: "Anzahl Portionen",
    hoursPlaceholder: "Verfügbar zu folgenden Uhrzeiten",
    invalidPrice: "Ungültiger Preis",
    chooseAnImage: "Bild auswählen",
    chooseAVideo: "Video auswählen",
    menuItem: "Produkt",
    priceOfIndividualItem: "Preis pro Portion",
    pickupUntilTime: "Zeitfenster bis",
    pickupFromTime: "Zeitfenster von",
    submitUpdate: "Änderungen speichern",
    date: "Datum",
    infoVideoMsg:
      "Videos vom Kochprozess schaffen zusätzliches Vertrauen in Dich als HobbyCook, Deine Gerichte und Küchenhygiene. Sie wirken inspirierend und kreieren zusätzliche Nähe zwischen Dir und Deinem Gast.",
    updateDishTitle: "Was möchtest Du bearbeiten?",
    updateDishPropertiesTitle: "Produkt ändern",
    updateDishFilesTitle: "Video / Bild bearbeiten",
    deleteDishModalTitle: "Produkt löschen",
    updateDishPropertiesButton: "Produkt bearbeiten",
    updateDishFilesButton: "Video oder Bild bearbeiten",
    deleteDishButton: "Produkt löschen",
  },
  addDish: {
    addItemTitle: "Produkt hinzufügen",
    namePlaceholder: "Name",
    nameEnPlaceholder: "Name (English)",
    nameDePlaceholder: "Name (Deutsch)",
    descriptionPlaceholder: "Beschreibung",
    descriptionEnPlaceholder: "Bechreibung (English)",
    descriptionDePlaceholder: "Bechreibung (Deutsch)",
    hoursPlaceholder: "Verfügbar zu folgenden Uhrzeiten",
    invalidPrice: "Ungültiger Preis",
    tagsPlaceholder: "Tags / Allergene",
    sectionPlaceholder: "Warengruppen / Sektionen",
    vatPlaceholder: "MwSt. im Haus",
    vatToGoPlaceholder: "MwSt. ausser Haus",
    orderModusPlaceholder: "Bestellmöglichkeiten",
    addonPlaceholder: "Produkt ist ein Options-Artikel",
    priceDisplayPlaceholder: "Preisdarstellung",
    budgetPlaceholder: "Ausgaben Deines Einkaufs Euro",
    pricePlaceholder: "Preis in Euro",
    portionsAmountPlaceholder: "Anzahl Portionen",
    chooseAnImage: "Bild auswählen",
    chooseAVideo: "Video auswählen",
    menuItem: "Gericht",
    priceOfIndividualItem: "Preis pro Portion",
    createItem: "Hinzufügen",
    requiredImage: "Bitte füge ein Bild hinzu *",
    pickupUntilTime: "Zeitfenster bis",
    pickupFromTime: "Zeitfenster von",
    date: "Datum",
  },
  order: {
    items: "Anzahl Gericht",
    items_plural: "Anzahl Gerichte",
    itemsShort: "Gericht",
    itemsShort_plural: "Gerichte",
  },
  notificationModal: {
    close: "Schließen",
  },
  qrCodes: {
    code: "Code",
    number: "Nr.",
    emptyQRTablesList: "Keine Daten verfügbar",
    actions: "Aktionen",
    errorGettingQRCodes: "Fehler beim Abrufen der QR-Codes!",
    searchQRTable: "Suche (z.B.. XXXXXX oder 42)",
    errorDeleteQRTable:
      "Fehler beim Löschen des QR-Codes. Bitte versuchen Sie es erneut!",
    successDeleteQRTable: "QR-Code erfolgreich gelöscht!",
    scanQRCode: "QR-Code scannen",
    numberInputLabel: "Nr.",
  },
  qrCodeScan: {
    title: "QR-Code scannen",
    errorUpdateQRTable:
      "Fehler beim Durchführen der Aktion. Bitte versuchen Sie es erneut.",
    successUpdateQRTable: "Aktion erfolgreich!",
    errorUpdateVenueQRTable:
      "WARNUNG: Dieser QR-Code wird bereits von einem anderen Benutzer verwendet! Bitte kontaktieren Sie den Support.",
    cameraAccessError:
      "Die Kamera ist blockiert oder nicht zugänglich. Bitte erlauben Sie die Nuzuung der Kamera in Ihren Browser-Berechtigungen und laden Sie die Seite neu.",
    qrCodeScanError: "Scannen des QR-Codes fehlgeschlagen!",
    qrCodeSaveError: "Fehler beim Speichern/Aktualisieren des QR-Codes!",
    qrCodeScanResult: "Gescannter Code: {{data}}",
    qrCodeScanSuccess: "QR-Code erfolgreich verlinkt!",
    numberAlreadyExist:
      "Sie haben diese Nummer bereits mit einem anderen Code verwendet!",
    numberNotValid: "Bitte wählen Sie eine gültige Nummer für den QR-Code!",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
  },
};
