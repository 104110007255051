import * as types from "../types";
import {
  put,
  fork,
  take,
  takeLeading,
  retry,
  actionChannel,
  call,
  spawn,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  FromNativeEvent,
  ToNativeEvent,
} from "@orda/shared-constants/backoffice/tablet-bridge-events";
import {
  updateStatus,
  initStatus,
  appendToStatus,
  setDatabaseManagedPrinters,
} from "../actions/status";
import { ready, send } from "../actions/bridge";
import { unblock as unblockAuth } from "../actions/auth";
import { statusLoadingSaga } from "./status";
import { orderCheckIn } from "../actions/orders";
import { showBatteryDialog, setPrintersInitialized } from "../actions/uiState";
import EventEmitter from "eventemitter3";

function isPrinterAutomatching(printers) {
  return printers && printers.length === 1 && printers[0].address !== "";
}

export const ReceiveEmitter = new EventEmitter();

function bridgeChannel() {
  return eventChannel((emit) => {
    // create handler and subscribe
    const handler = (data) => {
      emit(data);
    };
    ReceiveEmitter.addListener("message", handler);

    // return unsubscriber
    return () => ReceiveEmitter.removeEventListener("message", handler);
  });
}

function* bridgeReceiveSaga() {
  const channel = bridgeChannel();

  while (true) {
    const { type, payload } = yield take(channel);

    switch (type) {
      case FromNativeEvent.StatusInit:
        yield put(initStatus(payload));
        yield spawn(statusLoadingSaga);
        break;
      case FromNativeEvent.StatusAddItem: {
        const { key, value } = payload;
        yield put(appendToStatus(key, value));
        break;
      }
      case FromNativeEvent.StatusUpdate: {
        const {
          key,
          value,
          printerId,
          printers,
          availablePrinters,
          hasBluetoothAddresses,
        } = payload;

        if (hasBluetoothAddresses) {
          yield put(setPrintersInitialized());
          yield put(setDatabaseManagedPrinters());
        } else if (isPrinterAutomatching(printers)) {
          yield put(setPrintersInitialized());
        }

        yield put(
          updateStatus(key, value, printerId, printers, availablePrinters)
        );
        break;
      }
      case FromNativeEvent.LoginSuccess:
        yield put(unblockAuth());
        break;
      case FromNativeEvent.OrderCheckin: {
        const { orderId, tableId, table } = payload;
        yield put(orderCheckIn(orderId, tableId, table));
        break;
      }
      case FromNativeEvent.BatteryWarning: {
        yield put(showBatteryDialog());
        break;
      }
      default:
        break;
    }
  }
}

const postMessageProxy = (message) =>
  window.ReactNativeWebView.postMessage(message);

function* bridgeSendSaga() {
  const channel = yield actionChannel(types.bridge.SEND);

  // wait until the bridge is ready
  yield take(types.bridge.READY);

  while (true) {
    const {
      data: { type, payload },
    } = yield take(channel);
    yield call(
      postMessageProxy,
      JSON.stringify({ type, payload: payload || {} })
    );
  }
}

function* bridgeInitSaga() {
  try {
    window.WebViewBridge = {};
    window.WebViewBridge.onMessage = (message) => {
      ReceiveEmitter.emit("message", message);
    };
    yield retry(10, 1000, () => {
      if (window.ReactNativeWebView.postMessage) {
        return true;
      }
      throw new Error(`[BRIDGE] not available`);
    });
    yield put(send(ToNativeEvent.Init));
    yield put(ready());
  } catch (error) {
    throw new Error(`[BRIDGE] unable to initialize bridge`);
  }
}

export default function* bridgeRootSaga() {
  yield fork(bridgeReceiveSaga);
  yield fork(bridgeSendSaga);
  yield takeLeading(types.bridge.INIT, bridgeInitSaga);
}
