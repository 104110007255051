import React from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailFormatting: {
        error: null,
        errorText: "",
      },
      passwordFormatting: {
        error: null,
        errorText: "",
      },
      allowed: true,
    };
  }

  componentDidMount() {
    const { updateNavBarTitle } = this.props;
    updateNavBarTitle();
  }

  resetTextFieldFormatting = () => {
    this.setState({
      emailFormatting: {
        error: false,
        errorText: "",
      },
      passwordFormatting: {
        error: false,
        errorText: "",
      },
    });
  };

  handleSubmit = async (e) => {
    const { email, password } = this.state;
    const { login } = this.props;
    e.preventDefault();
    this.resetTextFieldFormatting();

    if (email === "") {
      this.setState({
        emailFormatting: {
          error: true,
          errorText: "Dieses Feld darf nicht leer sein.",
        },
      });
      return;
    }

    if (password === "") {
      this.setState({
        passwordFormatting: {
          error: true,
          errorText: "Dieses Feld darf nicht leer sein.",
        },
      });
      return;
    }

    let callbackRegistered = false;
    login(email, password);
    callbackRegistered = true;

    if (callbackRegistered) {
      return;
    }

    this.setState({
      allowed: false,
    });
  };

  handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { user, processingLogin, loginError } = this.props;
    const { allowed, emailFormatting, passwordFormatting } = this.state;

    if (allowed) {
      if (user && !processingLogin) {
        return <Redirect to="/" />;
      }

      return (
        <div className="container" style={{ marginTop: 30 }}>
          <Paper style={{ padding: 20 }}>
            <form onSubmit={this.handleSubmit}>
              <TextField
                fullWidth
                name="email"
                type="email"
                placeholder="email@domain.de"
                label="Email"
                error={loginError || emailFormatting.error}
                helperText={
                  (loginError &&
                    "Der eingegebene Benutzername oder das Passwort ist falsch") ||
                  emailFormatting.errorText
                }
                onChange={this.handleInputChange}
                style={{ margin: "0.5rem 0" }}
              />
              <TextField
                fullWidth
                name="password"
                type="password"
                label="Passwort"
                error={loginError || passwordFormatting.error}
                helperText={(loginError && "") || passwordFormatting.errorText}
                onChange={this.handleInputChange}
                style={{ margin: "0.5rem 0" }}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={processingLogin}
                style={{
                  marginTop: 20,
                  height: 50,
                }}
                type="submit"
              >
                {processingLogin ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "Bestätigen"
                )}
              </Button>
            </form>
          </Paper>
        </div>
      );
    }
    return <Redirect to="/unauthorised" />;
  }
}

Login.propTypes = {
  updateNavBarTitle: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.object,
  processingLogin: PropTypes.bool.isRequired,
  loginError: PropTypes.bool,
};

Login.defaultProps = {
  user: null,
  loginError: null,
};
