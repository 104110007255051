import * as types from "../types";

export const initStatus = (data) => ({
  type: types.status.INIT,
  data,
});

export const statusLoadingFinished = () => ({
  type: types.status.LOADING_FINISHED,
});

export const appendToStatus = (key, value) => ({
  type: types.status.APPEND,
  key,
  value,
});

export const updateStatus = (
  key,
  value,
  printerId,
  printers,
  availablePrinters
) => ({
  type: types.status.UPDATE,
  key,
  value,
  printerId,
  printers,
  availablePrinters,
});

export const updatePrinterConfig = (updatedPrinters, oldPrinters) => ({
  type: types.status.UPDATE_PRINTER_CONFIG,
  updatedPrinters,
  oldPrinters,
});

export const updatePrinterConfigSuccess = () => ({
  type: types.status.UPDATE_PRINTER_CONFIG_SUCCESS,
});

export const storePrinterConfig = (printerUpdates) => ({
  type: types.status.STORE_PRINTER_CONFIG,
  printerUpdates,
});

export const setDatabaseManagedPrinters = () => ({
  type: types.status.SET_DATABASE_MANAGED_PRINTERS,
});
