// eslint-disable-next-line import/no-named-as-default
import Login from "../components/Login";
import { connect } from "react-redux";
import { updateNavBarTitle } from "../redux/actions/uiState";
import { login } from "../redux/actions/auth";

const mapStateToProps = (state) => {
  const {
    auth: {
      requests: {
        login: { processing: processingLogin, error: loginError },
      },
      user,
    },
  } = state;
  return {
    processingLogin,
    loginError: loginError && !!loginError.message,
    user,
  };
};

const mapDispatchToProps = (dispatch, { appBarTitle }) => ({
  updateNavBarTitle: () => dispatch(updateNavBarTitle(appBarTitle)),
  login: (email, password) => dispatch(login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
