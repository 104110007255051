import React from "react";
import { Redirect, Link } from "react-router-dom";

import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { grey600 } from "@material-ui/core/colors";
import PropTypes from "prop-types";

export default class Unauthorised extends React.Component {
  componentDidMount() {
    const { updateNavBarTitle } = this.props;
    updateNavBarTitle();
  }

  render() {
    const { user } = this.props;
    if (user) {
      return (
        <div className="container" style={{ marginTop: 30 }}>
          <div style={{ textAlign: "center" }}>
            <ErrorOutline color={grey600} style={{ height: 150, width: 150 }} />
            <h1>Zugriff verwehrt</h1>
            <Link style={{ fontWeight: "bold" }} href="/" to="/">
              Zurück zur Startseite
            </Link>
          </div>
        </div>
      );
    }

    return <Redirect to="/" />;
  }
}

Unauthorised.propTypes = {
  updateNavBarTitle: PropTypes.func.isRequired,
  user: PropTypes.object,
};

Unauthorised.defaultProps = {
  user: null,
};
