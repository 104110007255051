import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { grey600 } from "@material-ui/core/colors";

export default class Unauthorized extends React.Component {
  componentDidMount() {
    const { updateNavBarTitle } = this.props;
    updateNavBarTitle();
  }

  render() {
    return (
      <div className="container" style={{ marginTop: 30 }}>
        <div style={{ textAlign: "center" }}>
          <ErrorOutline color={grey600} style={{ height: 150, width: 150 }} />
          <h1>Not Found</h1>
          <p style={{ fontSize: "1em", color: grey600, lineHeight: "1.5em" }}>
            <FormattedMessage
              id="notfound.message"
              defaultMessage="The page you're looking for could not be found."
            />
          </p>
          <Link style={{ fontWeight: "bold" }} href="/" to="/">
            Zurück zur Startseite
          </Link>
        </div>
      </div>
    );
  }
}

Unauthorized.propTypes = {
  updateNavBarTitle: PropTypes.func.isRequired,
};
