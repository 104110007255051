/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import { REHYDRATE } from "redux-persist";

export const initialState = {
  currentVenueId: null,
  buildNumberTapCount: 0,
  navBarTitle: null,
  appBarHidden: false,
  dialogs: {
    status: {
      open: false,
    },
    batteryWarning: {
      open: false,
    },
  },
  printersInitialized: false,
};

export default function uiStateReducer(state = initialState, action) {
  switch (action.type) {
    case types.uiState.UPDATE_NAVBAR_TITLE:
      return produce(state, (newState) => {
        newState.navBarTitle = action.navBarTitle;
      });
    case types.uiState.SET_NAVBAR_HIDDEN:
      return produce(state, (newState) => {
        newState.appBarHidden = action.appBarHidden;
      });
    case types.uiState.UPDATE_CURRENT_VENUE_ID:
      return produce(state, (newState) => {
        newState.currentVenueId = action.venueId;
      });
    case types.uiState.INCREMENT_BUILD_NUMBER_TAP_COUNT:
      return produce(state, (newState) => {
        newState.buildNumberTapCount += 1;
      });
    case types.uiState.RESET_BUILD_NUMBER_TAP_COUNT:
      return produce(state, (newState) => {
        newState.buildNumberTapCount = initialState.buildNumberTapCount;
      });
    case types.uiState.DIALOGS.STATUS.TOGGLE:
      return produce(state, (newState) => {
        newState.dialogs.status.open = action.status;
      });
    case types.uiState.DIALOGS.BATTERY_WARNING.SHOW:
      return produce(state, (newState) => {
        newState.dialogs.batteryWarning.open = true;
      });
    case types.uiState.DIALOGS.BATTERY_WARNING.DISMISS:
      return produce(state, (newState) => {
        newState.dialogs.batteryWarning.open = false;
      });
    case types.uiState.PRINTERS_INITIALIZED:
      return produce(state, (newState) => {
        newState.printersInitialized = true;
      });
    case types.auth.LOGIN.SUCCESS:
      return produce(state, (newState) => {
        newState.printersInitialized = false;
      });
    case REHYDRATE:
      return produce(state, (newState) => {
        if (
          action.payload &&
          action.payload.uiState &&
          action.payload.uiState.printersInitialized
        ) {
          newState.printersInitialized =
            action.payload.uiState.printersInitialized;
        }
      });
    default:
      return state;
  }
}
