const useStyles = () => ({
  containerModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    minWidth: "40%",
    maxWidth: "85%",
    borderRadius: "16px",
    backgroundColor: "white",
    border: "3px solid #000",
  },
  title: {
    textAlign: "center",
    padding: "14px",
    fontSize: "1.4em",
  },
  msgContainer: {
    display: "grid",
    gridTemplateColumns: "11vw auto",
    wordWrap: "anywhere",
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 0",
  },
  icon: {
    width: "2em",
    height: "2em",
  },
  msg: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.4rem",
    textAlign: "center",
    padding: "10px",
  },
  close: {
    fontSize: "1.4rem",
    padding: "8px",
    border: "none",
    backgroundColor: "#2C2F3A",
    color: "white",
    cursor: "pointer",
    boxShadow: "0px 0px 4px 0px #2C2F3A",
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "4px",
  },
  closeContainer: {
    width: "100%",
    paddingBottom: "16px",
    textAlign: "center",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid",
    margin: "0 10%",
    padding: "8px",
  },
});

export default useStyles;
