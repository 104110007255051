import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Close from "@material-ui/icons/Close";
import CustomFileInput from "../../CustomFileInput";
// @ts-ignore
import shortid from "shortid";
import "./AddDishDialog.css";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
// @ts-ignore
import ImageUploadIcon from "../../../assets/icons/image-upload.svg";
import VideoUploadIcon from "../../../assets/icons/video-upload.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  orderModusMap,
  PRICE_DISPLAY_DEFAULT,
  // SECTIONS_TO_NOT_RENDER,
  tagsMap,
  vatMap,
} from "../../../constants";
import { Grid, OutlinedInput, withStyles } from "@material-ui/core";
import { isNumeric } from "../../../util/helpers";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
  },
  flexableWrap: {
    flexWrap: "nowrap",
    gap: "10px",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width: 960px)"]: {
      gap: "0px",
      flexWrap: "wrap",
    },
  },
};

interface Props {
  open: boolean;
  handleSubmitAddDish: Function;
  handleCloseDialog: Function;
  sectionId: string;
  // strings: any[];
  // sectionOrder: any[];
  openingHours: any[];
  classes: any;
}

function findTagName(tagId: string): string {
  const tagObject = tagsMap.find((tagObj) => tagObj.tagId === tagId);
  if (tagObject) {
    return tagObject.tagName;
  }
  return "";
}

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddDishDialog: React.FC<Props> = ({
  classes,
  open,
  handleSubmitAddDish,
  handleCloseDialog,
  sectionId,
  // strings,
  // sectionOrder,
  openingHours,
}): ReturnType<React.FC<Props>> => {
  const { t } = useTranslation("addDish");
  const imageReader: any = new FileReader();
  const videoReader: any = new FileReader();

  const [nameEn, setNameEN] = useState("");
  const [nameDE, setNameDE] = useState("");

  const [price, setPrice] = useState("");

  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionDE, setDescriptionDE] = useState("");

  const [errors, setErrors] = useState({
    price: undefined,
  });

  const [itemImage, setItemImage] = useState<any>(null);
  const [itemImageUrl, setItemImageUrl] = useState<string>("");
  // const [imageError, setImageError] = useState<any>(false);

  const [itemVideos, setItemVideos] = useState<any[]>([]);
  const [itemVideoNames, setItemVideoNames] = useState<string[]>([]);

  const [tags, setTags] = useState<string[]>([]);
  const [vat, setVat] = useState<any>("");
  const [vatToGo, setVatToGo] = useState<any>("");
  const [hours, setHours] = useState<any>("");
  const [orderModus, setOrderModus] = useState<string>("TO-STAYTO-GO");
  const [addon, setAddon] = useState<boolean>(false);

  const setErrorsOnChange = useCallback(
    (priceValue): any => {
      const validationErrors: any = {};
      try {
        if (
          parseFloat(priceValue.replace(/[,]/g, ".")) < 0 ||
          !isNumeric(priceValue.replace(/[,]/g, "."))
        ) {
          validationErrors.price = t("invalidPrice");
        }
      } catch {
        validationErrors.price = t("invalidPrice");
      }
      setErrors(validationErrors);
    },
    [setErrors]
  );

  const handleTagChange = useCallback(
    (event: any): void => {
      const { value } = event.target;
      setTags(value);
    },
    [setTags]
  );

  const handleVatChange = useCallback(
    (event: any): void => {
      const { value } = event.target;
      setVat(value);
    },
    [setVat]
  );

  const handleVatToGoChange = useCallback(
    (event: any): void => {
      const { value } = event.target;
      setVatToGo(value);
    },
    [setVatToGo]
  );

  const handleOnItemPictureChange = useCallback(
    (event: any): void => {
      const { files } = event.target;

      if (files && files.length) {
        imageReader.addEventListener("load", () => {
          setItemImageUrl(imageReader.result);
          setItemImage(files[0]);
        });
        imageReader.readAsDataURL(files[0]);
      }
    },
    [setItemImageUrl, setItemImage]
  );

  const handleOnItemVideosChange = useCallback(
    (event: any): void => {
      const { files } = event.target;
      // TODO(Mehdi): when multiple files refactor this part

      if (files && files.length) {
        videoReader.addEventListener("load", () => {
          setItemVideoNames([
            files[0].name ||
              `item-video-${shortid.generate()}-${new Date().getTime()}.mp4`,
          ]);
          setItemVideos([files[0]]);
        });
        videoReader.readAsDataURL(files[0]);
      }
    },
    [setItemVideoNames, setItemVideos]
  );

  const handleOnSubmitForm = useCallback(
    (event: any): void => {
      event.preventDefault();
      if (errors.price) {
        return;
      }
      const itemId = shortid.generate();
      const descriptionId = shortid.generate();
      const formattedPrice = +(+price.replace(/[,]/g, ".") * 100).toFixed(2);

      handleSubmitAddDish({
        itemId,
        sectionId,
        itemImage: {
          fileName: `item-image-${shortid.generate()}-${new Date().getTime()}.jpg`,
          file: itemImage,
        },
        itemVideos: (itemVideos || []).map((itemVideo) => ({
          fileName: `item-video-${shortid.generate()}-${new Date().getTime()}.mp4`,
          file: itemVideo,
        })),
        item: {
          price: formattedPrice,
          priceDisplay: PRICE_DISPLAY_DEFAULT,
          description: descriptionId,
          tags,
          orderedAmount: 0,
          vat: vat ? +vat : undefined,
          vatToGo: vatToGo ? +vatToGo : undefined,
          notEatIn: orderModus.indexOf("TO-STAY") > -1 ? undefined : true,
          notToGo: orderModus.indexOf("TO-GO") > -1 ? undefined : true,
          addon,
          hours,
        },
        stringsToCreate: {
          en: {
            new: {
              [itemId]: `${nameEn}`,
              [descriptionId]: descriptionEN,
            },
          },
          de: {
            new: {
              [itemId]: `${nameDE}`,
              [descriptionId]: descriptionDE,
            },
          },
        },
      });
    },
    [
      handleSubmitAddDish,
      tags,
      descriptionDE,
      descriptionEN,
      nameEn,
      nameDE,
      price,
      itemVideos,
      itemImage,
      vat,
      vatToGo,
      hours,
      sectionId,
      orderModus,
    ]
  );

  useEffect(() => {
    setErrorsOnChange(price);
  }, [price]);
  return (
    <Dialog
      fullWidth
      PaperProps={{
        style: {
          margin: "4%",
        },
      }}
      open={open}
      maxWidth="md"
      aria-labelledby="add-menu-item-dialog"
    >
      <DialogTitle id="add-menu-item-dialog">
        <div className="add-item-dialog-title">
          <div>{t("addItemTitle")}</div>
          <Close
            style={{
              width: "1.4em",
              height: "1.4em",
            }}
            className="add-item-dialog-close"
            onClick={() => handleCloseDialog()}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <form id="add-menu-item-dialog-form" onSubmit={handleOnSubmitForm}>
          <Grid container>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={nameDE}
                  label={t("nameDePlaceholder")}
                  required
                  onChange={(event) => setNameDE(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10, marginRight: 5 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={nameEn}
                  label={t("nameEnPlaceholder")}
                  onChange={(event) => setNameEN(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <TextField
                  value={descriptionDE}
                  fullWidth
                  label={t("descriptionDePlaceholder")}
                  onChange={(event) => setDescriptionDE(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10, marginRight: 5 }}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={descriptionEN}
                  fullWidth
                  label={t("descriptionEnPlaceholder")}
                  onChange={(event) => setDescriptionEN(event.target.value)}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              className={`${classes.root} ${classes.flexableWrap}`}
            >
              <Grid item xs={12}>
                <FormControl
                  style={{ marginBottom: 10, marginRight: 5 }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="vat-mutiple-chip-label">
                    {t("vatPlaceholder")}
                  </InputLabel>
                  <Select
                    value={vat}
                    onChange={handleVatChange}
                    input={
                      <OutlinedInput
                        id="vat-mutiple-chip-label"
                        labelWidth={t("vatPlaceholder").length * 8}
                      />
                    }
                    renderValue={() => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {vat}
                      </div>
                    )}
                    required
                    MenuProps={MenuProps}
                  >
                    {vatMap.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ marginBottom: 10, marginRight: 5 }}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel htmlFor="vat-togo-mutiple-chip-label">
                    {t("vatToGoPlaceholder")}
                  </InputLabel>
                  <Select
                    value={vatToGo}
                    onChange={handleVatToGoChange}
                    input={
                      <OutlinedInput
                        id="vat-togo-mutiple-chip-label"
                        labelWidth={t("vatToGoPlaceholder").length * 8}
                      />
                    }
                    renderValue={() => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {vatToGo}
                      </div>
                    )}
                    required
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {vatMap.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={price}
                  label={t("pricePlaceholder")}
                  required
                  error={errors.price}
                  onChange={(event) => setPrice(event.target.value)}
                  inputProps={{ inputMode: "decimal" }}
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="hours-mutiple-chip-label">
                {t("hoursPlaceholder")}
              </InputLabel>
              <Select
                fullWidth
                value={hours}
                onChange={(event) => setHours(event.target.value)}
                input={
                  <OutlinedInput
                    id="hours-mutiple-chip-label"
                    labelWidth={t("hoursPlaceholder").length * 8}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {hours}
                  </div>
                )}
                required
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                <MenuItem key="" value=""></MenuItem>
                {Object.keys(openingHours)
                  .filter((h) => h !== "timeZone")
                  .map((h) => {
                    return (
                      <MenuItem key={h} value={h}>
                        {h}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="order-mode-mutiple-chip-label">
                {t("orderModusPlaceholder")}
              </InputLabel>
              <Select
                fullWidth
                value={orderModus}
                onChange={(event) => setOrderModus(event.target.value)}
                input={
                  <OutlinedInput
                    id="order-mode-mutiple-chip-label"
                    labelWidth={t("orderModusPlaceholder").length * 8}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                      orderModusMap.find(
                        (obj) => obj.orderModusId === orderModus
                      )?.orderModusName
                    }
                  </div>
                )}
                required
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                {orderModusMap.map(({ orderModusName, orderModusId }) => (
                  <MenuItem key={orderModusId} value={orderModusId}>
                    {orderModusName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="tags-mutiple-chip-label">
                {t("tagsPlaceholder")}
              </InputLabel>
              <Select
                multiple
                fullWidth
                value={tags}
                onChange={handleTagChange}
                input={
                  <OutlinedInput
                    id="tags-mutiple-chip-label"
                    labelWidth={t("tagsPlaceholder").length * 10}
                  />
                }
                renderValue={() => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {tags.map((tagId) => (
                      <Chip
                        key={tagId}
                        label={findTagName(tagId)}
                        style={{ margin: 2 }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
                style={{ marginBottom: 10 }}
              >
                {tagsMap.map(({ tagName, tagId }) => (
                  <MenuItem
                    key={tagId}
                    value={tagId}
                    style={{
                      fontWeight:
                        tags.indexOf(tagId) === -1 ? "normal" : "bold",
                    }}
                  >
                    {tagName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox color="primary" checked={addon} />}
              label={t("addonPlaceholder")}
              onChange={() => setAddon(!addon)}
            />
            <div className="add-dish-file-input">
              <CustomFileInput
                id="imageFile"
                name="itemImage"
                label={t("chooseAnImage")}
                iconHeight={60}
                inputProps={{
                  onChange: handleOnItemPictureChange,
                  accept: "image/*",
                }}
                color="#424242"
                iconSrc={ImageUploadIcon}
              />
              <CustomFileInput
                id="videoFile"
                name="itemVideos"
                label={t("chooseAVideo")}
                iconHeight={60}
                inputProps={{
                  onChange: handleOnItemVideosChange,
                  accept: "video/*",
                }}
                color="#424242"
                iconSrc={VideoUploadIcon}
              />
            </div>
            <div className="add-dish-file-results">
              <div>
                {itemImageUrl && (
                  <img
                    alt="Crop"
                    src={itemImageUrl}
                    style={{ maxWidth: "100%", maxHeight: 100 }}
                  />
                )}
              </div>
              <div>{(itemVideoNames || []).join(",")}</div>
            </div>
          </Grid>
          <DialogActions>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              form="add-menu-item-dialog-form"
            >
              {t("createItem")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

AddDishDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired,
  handleSubmitAddDish: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  sectionId: PropTypes.string.isRequired,
  // strings: PropTypes.any.isRequired,
  // sectionOrder: PropTypes.any.isRequired,
  openingHours: PropTypes.any.isRequired,
};

export default withStyles(styles as any)(AddDishDialog);
