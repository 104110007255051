import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "../components/Dialog";
import {
  NEW_ORDER,
  ORDER_REMINDER,
  CHECKIN,
  BATTERY_WARNING,
} from "@orda/backend-shared-constants/tablet-bridge-events";
import moment from "moment";
import { acknowledgeOrder } from "../redux/actions/orders";
import { dequeueOrderReminder } from "../redux/actions/orderReminders";
import { startReminder, stopReminder } from "../redux/actions/sound";
import {
  dismissCheckInDialog,
  dismissBatteryDialog,
} from "../redux/actions/uiState";
import { logEvent } from "../lib/analytics";
import { DIALOG_DISMISS } from "../lib/analytics/events";

const DEFAULT_NOTIFICATION = {
  BATTERY_WARNING: true,
  CHECKIN: true,
  NEW_ORDER: true,
  ORDER_REMINDER: true,
};

export default function Dialogs({ contentContainerRef }) {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders.orders);
  const currentVenueId = useSelector((state) => state.uiState.currentVenueId);
  const orderReminders = useSelector(
    (state) => state.orderReminders.orderReminders
  );
  const checkIns = useSelector((state) => state.orders.checkIns);
  const batteryWarning = useSelector(
    (state) => state.uiState.dialogs.batteryWarning.open
  );
  const acknowledgeOrderProcessing = useSelector(
    (state) => state.orders.requests.acknowledgeOrder.processing
  );
  const venues = useSelector((state) => state.auth.venues);
  const { notifications = DEFAULT_NOTIFICATION } = !!venues &&
    !!currentVenueId && { ...venues[currentVenueId] };

  const filteredOrders =
    orders &&
    orders.length &&
    orders
      .filter(
        (o) =>
          moment.unix(o.executedAt).isSame(moment(), "day") &&
          !o.doneAt &&
          !o.acknowledgedAt
      )
      .sort((a, b) => a.time - b.time);

  /* New Order Dialog */
  const order = filteredOrders && filteredOrders[0];
  if (!currentVenueId) {
    return null;
  }

  if (order) {
    return notifications.NEW_ORDER ? (
      <Dialog
        contentContainerRef={contentContainerRef}
        type={NEW_ORDER}
        content={{
          order,
          currentVenueId,
        }}
        isHobbyCook={order.hobbyCook}
        action={() => {
          logEvent(DIALOG_DISMISS, { type: NEW_ORDER });
          dispatch(
            acknowledgeOrder(currentVenueId, order.orderId, order.userId)
          );
        }}
        startReminder={() => dispatch(startReminder(NEW_ORDER))}
        stopReminder={() => dispatch(stopReminder())}
        processing={acknowledgeOrderProcessing}
      />
    ) : null;
  }

  /* Order Reminder Dialog */
  const remindingOrder = orderReminders && orderReminders[0];

  if (remindingOrder) {
    return (
      <Dialog
        contentContainerRef={contentContainerRef}
        type={ORDER_REMINDER}
        content={{
          order: remindingOrder,
        }}
        action={() => {
          logEvent(DIALOG_DISMISS, { type: ORDER_REMINDER });
          dispatch(dequeueOrderReminder(remindingOrder.orderId));
        }}
        startReminder={() => dispatch(startReminder(ORDER_REMINDER))}
        stopReminder={() => dispatch(stopReminder())}
      />
    );
  }

  /* Check-In Dialog */
  const checkInOrder =
    orders && orders.length && checkIns.length
      ? orders.find((o) => o.orderId === checkIns[0])
      : null;

  if (checkInOrder) {
    return notifications.CHECKIN ? (
      <Dialog
        contentContainerRef={contentContainerRef}
        type={CHECKIN}
        content={{
          order: checkInOrder,
        }}
        action={() => {
          logEvent(DIALOG_DISMISS, { type: CHECKIN });
          dispatch(dismissCheckInDialog(checkInOrder.orderId));
        }}
        startReminder={() => dispatch(startReminder(CHECKIN))}
        stopReminder={() => dispatch(stopReminder())}
      />
    ) : null;
  }

  /* Battery Warning Dialog */
  if (batteryWarning) {
    return (
      <Dialog
        contentContainerRef={contentContainerRef}
        type={BATTERY_WARNING}
        action={() => {
          logEvent(DIALOG_DISMISS, { type: BATTERY_WARNING });
          dispatch(dismissBatteryDialog());
        }}
        startReminder={() => dispatch(startReminder(BATTERY_WARNING))}
        stopReminder={() => dispatch(stopReminder())}
      />
    );
  }

  return null;
  // orders
  // reminders
  // checkins
  // warnings
  // computation
  // if any dialog to be displayed
  // <Dialog ....
  // else
  // null
}

Dialogs.propTypes = {
  contentContainerRef: PropTypes.object.isRequired,
};
