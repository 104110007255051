import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import UpdateDishDialog from "../update-dish-dialog";
import Pencil from "../../../assets/icons/pencil.svg";
import PulseLoader from "../../PulseLoader";
import Toggle from "../../CustomToggle";
import { SupportedCurrency } from "@orda/shared-constants";
import { withTranslation } from "react-i18next";
import { THEME } from "../../../util/theme";

const styles = {
  listEntryStyle: {
    borderBottom: "1px solid #bdbdbd",
    fontSize: "1em",
    color: "black",
    fontWeight: "bold",
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  updateContainer: { display: "flex", justifyContent: "center" },
  updateButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "14px",
    justifyContent: "right",
    border: "none",
    color: "#424242",
    outline: "none",
    background: "transparent",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "end",
    justifySelf: "end",
  },
};

const computePadding = (level, expandable) => {
  if (level === 0) return 0;
  const expandablePadding = expandable ? 0 : 12;
  return 12 * level + expandablePadding;
};

class ListItem extends React.Component {
  constructor(props) {
    super(props);
    const { level, expandable } = props;
    this.state = {
      expanded: false,
      updateDishDialogOpen: false,
      padding: {
        paddingLeft: computePadding(level, expandable),
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { expandable, level } = this.props;
    if (expandable !== nextProps.expandable) {
      if (!nextProps.expandable) {
        this.setState({
          expanded: false,
          padding: {
            paddingLeft: computePadding(level, nextProps.expandable),
          },
        });
      } else {
        this.setState({
          padding: {
            paddingLeft: computePadding(level, nextProps.expandable),
          },
        });
      }
    }
  }

  openUpdateDishDialog = () => {
    this.setState({ updateDishDialogOpen: true });
  };

  handleSubmitUpdateDish = (itemId, payload) => {
    const {
      updateItem,
      venue: { id },
    } = this.props;
    updateItem(id, itemId, payload);
    this.setState({ updateDishDialogOpen: false });
  };

  handleExpandChange = () => {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  };

  render() {
    const {
      type,
      venue,
      processingTargetId,
      id,
      sectionId,
      processingToggleAvailability,
      parentOption,
      expandable,
      name,
      nameEn,
      children,
      toggleable,
      toggleItemAvailability,
      toggleOptionAvailability,
      deleteItem,
      t,
      level,
    } = this.props;
    const { padding, expanded, updateDishDialogOpen } = this.state;
    const arrow = expanded ? (
      <IconButton onClick={this.handleExpandChange}>
        <ArrowDown />
      </IconButton>
    ) : (
      <IconButton onClick={this.handleExpandChange}>
        <ArrowRight />
      </IconButton>
    );

    let itemToggled = null;
    if (type === "option") {
      itemToggled = !venue.menu.options[id].unavailable;
    } else if (type === "item") {
      itemToggled = !venue.menu.items[id].unavailable;
    }
    const customItemStyle =
      type === "item" && level === 0
        ? {
            backgroundColor: itemToggled
              ? THEME.BACKGROUND_GREEN
              : THEME.BACKGROUND_RED,
            margin: 10,
          }
        : {};
    const toggle =
      processingTargetId === id ? (
        <PulseLoader />
      ) : (
        <Toggle
          toggled={itemToggled}
          disabled={!!processingToggleAvailability || venue.maintenance}
          onToggle={({ target: { checked } }) => {
            if (type === "item") {
              toggleItemAvailability(venue.id, id, checked, parentOption);
            } else {
              toggleOptionAvailability(venue.id, id, checked);
            }
          }}
        />
      );

    let itemData = {};

    if (venue && venue.menu && venue.menu.items[id]) {
      const { notEatIn, notToGo } = venue.menu.items[id];
      let orderModus = "TO-STAYTO-GO";
      if (notEatIn) {
        orderModus = orderModus.replace("TO-STAY", "");
      }
      if (notToGo) {
        orderModus = orderModus.replace("TO-GO", "");
      }
      itemData = {
        name,
        descriptionDE: venue.strings.de[venue.menu.items[id].description],
        descriptionId: venue.menu.items[id].description,
        nameEn,
        descriptionEN: venue.strings.en[venue.menu.items[id].description],
        tags: venue.menu.items[id].tags,
        availableAmount: venue.menu.items[id].availableAmount,
        availableOnDate: venue.menu.items[id].availableOnDate,
        price: venue.menu.items[id].price,
        pickupFromTime: venue.menu.items[id].pickupFromTime,
        pickupToTime: venue.menu.items[id].pickupToTime,
        videos: venue.menu.items[id].videos,
        sectionId: venue.menu.items[id].sectionId,
        hours: venue.menu.items[id].hours,
        optionId: venue.menu.items[id]?.options,
        vat: venue.menu.items[id].vat,
        vatToGo: venue.menu.items[id]?.vatToGo,
        orderModus,
        addon: venue.menu.items[id].addon || false,
      };
    }

    return (
      <div style={{ backgroundColor: THEME.CARD_BACKGROUND_COLOR }}>
        <li style={{ listStyle: "none" }}>
          <div
            style={{
              ...styles.listEntryStyle,
              ...customItemStyle,
            }}
          >
            <div
              style={{
                ...padding,
                ...styles.nameContainer,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ width: 40 }}>{expandable ? arrow : ""}</div>
                <div>{name}</div>
              </div>
              {!!venue &&
                !!venue.menu &&
                !!venue.menu.items[id] &&
                !!venue.menu.items[id].price && (
                  <h4
                    style={{
                      fontWeight: "bold",
                      paddingRight: 10,
                    }}
                  >
                    {t("money", {
                      data: {
                        currency: SupportedCurrency.EUR,
                        value: venue.menu.items[id].price,
                      },
                    })}
                  </h4>
                )}
            </div>

            {toggleable && (
              <div style={styles.toggleContainer}>
                <>
                  <button
                    type="button"
                    style={styles.updateButton}
                    onClick={() => {
                      this.openUpdateDishDialog();
                    }}
                  >
                    <img alt="pencil" style={{ width: "25px" }} src={Pencil} />
                  </button>
                </>
                {toggle}
              </div>
            )}
          </div>
          {expanded ? children : null}
        </li>
        <UpdateDishDialog
          open={updateDishDialogOpen}
          itemData={itemData}
          itemId={id}
          sectionId={sectionId}
          strings={venue.strings[venue.defaultLocale]}
          defaultLocale={venue.defaultLocale}
          venueId={venue.id}
          menu={(venue && venue.menu) || {}}
          handleSubmitUpdateDish={this.handleSubmitUpdateDish}
          handleCloseDialog={() =>
            this.setState({ updateDishDialogOpen: false })
          }
          handleDeleteMenuItem={deleteItem}
          openingHours={(venue && venue.openingHours) || []}
        />
      </div>
    );
  }
}

ListItem.defaultProps = {
  children: null,
  venue: null,
  processingTargetId: null,
  parentOption: null,
  nameEn: "",
};

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  toggleable: PropTypes.bool.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  nameEn: PropTypes.string,
  expandable: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
  children: PropTypes.object,
  venue: PropTypes.object,
  toggleItemAvailability: PropTypes.func.isRequired,
  toggleOptionAvailability: PropTypes.func.isRequired,
  processingToggleAvailability: PropTypes.bool.isRequired,
  processingTargetId: PropTypes.string,
  parentOption: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation("common")(ListItem);
