import auth from "./auth";
import sales from "./sales";
import venue from "./venue";
import activityLog from "./activityLog";
import orders from "./orders";
import socket from "./socket";
import bridge from "./bridge";
import sound from "./sound";
import marketing from "./marketing";
import status from "./status";
import tables from "./tables";
import { all, fork } from "redux-saga/effects";
import { INSIDE_WEBVIEW } from "../../config";

export default function* rootSaga() {
  const sagas = [
    auth,
    orders,
    sales,
    venue,
    socket,
    activityLog,
    sound,
    marketing,
    status,
    tables,
  ];

  if (INSIDE_WEBVIEW) {
    sagas.push(bridge);
  }

  yield all(sagas.map(fork));
}
