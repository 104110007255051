import { select, takeLatest, call, put } from "redux-saga/effects";
import * as types from "../types";
import {
  createVenueTablesAPI,
  deleteVenueTableAPI,
  updateVenueTableAPI,
} from "../../lib/venue";
import {
  deleteVenueTableFailure,
  deleteVenueTableSuccess,
  getVenueTablesFailure,
  getVenueTablesSuccess,
  updateTableNumberFailure,
  updateTableNumberSuccess,
  getVenueTables,
  setTableNumber,
} from "../actions/tables";

function* retrieveVenueTablesSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId } = action;

    const response = yield call(fetch, createVenueTablesAPI(venueId), {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    const tables = yield call([response, response.json]);
    yield put(getVenueTablesSuccess(venueId, tables));
  } catch (error) {
    yield put(getVenueTablesFailure(action.venueId, error));
  }
}

function* updateVenueTableSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const { currentTableNumber } = yield select((state) => state.tables);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, qrPayload, tableNumber = "" } = action;

    const response = yield call(fetch, updateVenueTableAPI(venueId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        qrPayload,
        tableNumber: `${tableNumber || currentTableNumber}`,
      }),
    });

    if (!response.ok) {
      throw new Error("Error adding scanned qr code");
    }

    yield put(updateTableNumberSuccess(venueId));
    yield put(getVenueTables(venueId));
    yield put(setTableNumber(+currentTableNumber + 1));
  } catch (error) {
    yield put(updateTableNumberFailure(action.venueId, error));
  }
}

function* deleteVenueTableSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, tableId } = action;

    yield call(fetch, deleteVenueTableAPI(venueId, tableId), {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
      }),
    });

    yield put(deleteVenueTableSuccess(venueId, tableId));
  } catch (error) {
    yield put(deleteVenueTableFailure(action.venueId, error));
  }
}
export default function* activityLogRootSaga() {
  yield takeLatest(
    types.tables.GET_VENUE_TABLES.REQUEST,
    retrieveVenueTablesSaga
  );
  yield takeLatest(
    types.tables.DELETE_VENUE_TABLE.REQUEST,
    deleteVenueTableSaga
  );
  yield takeLatest(
    types.tables.UPDATE_TABLE_NUMBER.REQUEST,
    updateVenueTableSaga
  );
}
