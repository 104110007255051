import App from "../components/App/index";
import { connect } from "react-redux";
import { init } from "../redux/actions/bridge";
import { closeNotificationById } from "../redux/actions/notifications";

const mapStateToProps = (state) => {
  const {
    uiState: { currentVenueId, printersInitialized },
    auth: {
      requests: {
        login: { processing: processingLogin },
        retrieveUserVenues: { error: userVenuesError },
      },
      user,
    },
    notifications,
  } = state;
  return {
    currentVenueId,
    processingLogin,
    user,
    userVenuesError,
    printersInitialized,
    notifications,
  };
};

const mapDispatchToProps = {
  bridgeInit: init,
  closeNotificationById,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
