import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core";
import routes from "../routes";
import PropTypes from "prop-types";
import { isAuthorized } from "../lib/routes";
import { updateNavBarTitle } from "../redux/actions/uiState";
import HomeScreenButton from "./HomeScreenButton";

interface HomeProps {
  appBarTitle: string;
  classes: any;
}

const styles = {
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    maxWidth: "100%",
  },
};

const Home: React.FC<HomeProps> = ({
  appBarTitle,
  classes,
}): ReturnType<React.FC<HomeProps>> => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateNavBarTitle(appBarTitle));
  }, []);

  const user = useSelector((state: any) => state.auth.user);
  const venues = useSelector((state: any) => state.auth.venues);
  const currentVenueId = useSelector(
    (state: any) => state.uiState.currentVenueId
  );

  return (
    <div className={classes.container}>
      {Object.keys(routes)
        .filter(
          (route) =>
            user &&
            venues &&
            isAuthorized(venues[currentVenueId], routes[route].permissions) &&
            !routes[route].posOnly
        )
        .map((route) => {
          return (
            route !== "home" &&
            route !== "login" &&
            routes[route].icon && (
              <HomeScreenButton
                key={route}
                url={routes[route].url}
                icon={routes[route].icon}
                label={routes[route].label}
              />
            )
          );
        })}
    </div>
  );
};

Home.propTypes = {
  appBarTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
