/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  orderReminders: [],
};

export default function orderRemindersReducer(state = initialState, action) {
  switch (action.type) {
    case types.orderReminders.ORDER_REMINDER.ENQUEUE:
      return produce(state, (newState) => {
        newState.orderReminders.push(action.order);
      });
    case types.orderReminders.ORDER_REMINDER.DEQUEUE:
      return produce(state, (newState) => {
        newState.orderReminders.splice(
          newState.orderReminders.findIndex(
            (order) => order.orderId === action.orderId
          ),
          1
        );
      });
    default:
      return state;
  }
}
