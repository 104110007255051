export default {
  INIT: "INIT",
  APPEND: "APPEND",
  LOADING_FINISHED: "LOADING_FINISHED",
  UPDATE: "UPDATE",
  UPDATE_PRINTER_CONFIG: "UPDATE_PRINTER_CONFIG",
  UPDATE_PRINTER_CONFIG_SUCCESS: "UPDATE_PRINTER_CONFIG_SUCCESS",
  STORE_PRINTER_CONFIG: "STORE_PRINTER_CONFIG",
  SET_DATABASE_MANAGED_PRINTERS: "SET_DATABASE_MANAGED_PRINTERS",
};
