// ** The english version is not used yet
export default {
  common: {
    number: "{{data, number}}",
    money: "{{data, money}}",
    percent: "{{data, percent}}",
    "time-llll": "{{time, time-llll}}",
    duration: "{{duration, duration}}",
  },
  routeLabels: {
    home: "Home",
    availability: "Availability",
    orders: "Orders",
    sales: "Sales",
    activityLog: "Activity Log",
    login: "Login",
    campaigns: "Campaigns",
    settings: "Settings",
    tables: "QR Codes",
  },
  marketing: {
    retrieveCampaignsError: "Error retrieving marketing campaigns",
    startDate: "Start Date",
    timeRange: "Time Range",
    discountAmount: "Discount Amount",
    status: "Status",
    description: "Description",
    inReview: "In Review",
    benefitAlwaysAvailable: "All Day",
  },
  settings: {
    printer: "Printer",
    bluetoothAddress: "Bluetooth Address",
    noPrinterSelected: "None",
    testPrint: "Test Print",
    saveButton: "Save",
    noPhysicalPrinter:
      "No printer was found. Please pair a bluetooth printer with this tablet.",
  },
  venueAvailability: {
    ordersAvailability: "ORDA availability",
    addDish: "Add dish",
    addItemButton: "Add Item to menu",
    editItem: "Update Menu item",
  },
  qrCodes: {
    code: "Code",
    number: "Nr.",
    emptyQRTablesList: "No data row available.",
    actions: "Actions",
    errorGettingQRCodes: "Error when retrieving the QR codes!",
    searchQRTable: "Search (e.g. XXXXXX oder 42)",
    errorDeleteQRTable: "Error deleting the QR code. Please try again!",
    successDeleteQRTable: "QR code successfully deleted!",
    scanQRCode: "Scan qr code",
    numberInputLabel: "Nr.",
  },
  qrCodeScan: {
    title: "Scan QR Code",
    errorUpdateQRTable: "Error performing the action. Please try again.",
    successUpdateQRTable: "Action succeeded!",
    errorUpdateVenueQRTable:
      "WARNING: This QR code is already used by another venue! Please contact support.",
    cameraAccessError:
      "The camera is blocked or not accessible. Please allow access to the camera in your browser permissions and reload the page.",
    qrCodeScanError: "Failed to scan the QR code!",
    qrCodeSaveError: "Error when saving/updating the QR code!",
    qrCodeScanResult: "Scanned code: {{data}}",
    qrCodeScanSuccess: "QR Code linked successfully!",
    numberAlreadyExist: "You have already used this number with another code!",
    numberNotValid: "Please select a valid number for the qr code!",
    cancel: "Cancel",
    confirm: "Confirm",
  },
};
