import { connect } from "react-redux";
import OrderListItemWrapper from "../components/OrdersNew/sub-components/OrderListItemWrapper";
import { reprintOrder, markOrderAsDone } from "../redux/actions/orders";

const mapStateToProps = (state) => {
  const {
    orders: {
      requests: {
        reprintOrder: { processing: reprintOrderProcessing },
      },
    },
    uiState: { currentVenueId },
    venue,
  } = state;
  return {
    reprintOrderProcessing,
    currentVenueId,
    venueLiteMode: venue && venue.venue ? !!venue.venue.lite : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reprintOrder: (venueId, order) => dispatch(reprintOrder(venueId, order)),
  markOrderAsDone: (venueId, orderId, userId, usedMode) =>
    dispatch(markOrderAsDone(venueId, orderId, userId, usedMode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderListItemWrapper);
