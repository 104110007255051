import React from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import RestaurantMenu from "@material-ui/icons/RestaurantMenu";
import moment from "moment";
import Loading from "../Loading";
import {
  GENERATE_SINGLE_INVITE,
  GENERATE_MULTI_INVITE,
  ACCEPTED_INVITE,
  ADDED_USER_PERMISSION,
  REMOVED_USER_PERMISSION,
  DELETED_USER,
  CHANGED_ITEM_AVAILABILITY,
  CHANGED_GLOBAL_STATUS,
} from "@orda/shared-constants-js/activity";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import TimelineEntry from "./sub-components/TimelineEntry";

import "./ActivityLog.css";

const locale = "de";

const getActionIcon = (action) => {
  const userActions = [
    GENERATE_SINGLE_INVITE,
    GENERATE_MULTI_INVITE,
    ACCEPTED_INVITE,
    ADDED_USER_PERMISSION,
    REMOVED_USER_PERMISSION,
    DELETED_USER,
  ];

  return userActions.includes(action) ? (
    <AccountCircle
      style={{ width: 36, height: 36, marginTop: 6, marginLeft: 6 }}
    />
  ) : (
    <RestaurantMenu
      style={{ width: 36, height: 36, marginTop: 6, marginLeft: 6 }}
    />
  );
};

class ActivityLog extends React.Component {
  componentWillMount() {
    const { updateNavBarTitle } = this.props;
    updateNavBarTitle();
  }

  componentDidMount() {
    const { retrieveVenue, retrieveActivityLog, currentVenueId } = this.props;
    retrieveVenue(currentVenueId);
    retrieveActivityLog(currentVenueId);
  }

  generateFormattedMessage = (activity) => {
    const { venue } = this.props;
    if (activity.action === CHANGED_ITEM_AVAILABILITY) {
      let idForString = "";
      if (activity.additionalInfo.itemId) {
        idForString = activity.additionalInfo.itemId;
      } else if (activity.additionalInfo.optionId) {
        idForString = activity.additionalInfo.optionId;
      } else if (activity.additionalInfo.sectionId) {
        idForString = activity.additionalInfo.sectionId;
      }

      return (
        <FormattedMessage
          id="activity.changed_item_availability"
          defaultMessage="{sourceUserName} marked {targetItemName} as {available, select, true {available} other {unavailable}}."
          values={{
            sourceUserName: activity.additionalInfo.sourceUserName,
            targetItemName: venue.strings[locale][idForString],
            available: activity.additionalInfo.unavailable ? "false" : "true",
          }}
        />
      );
    }
    if (activity.action === CHANGED_GLOBAL_STATUS) {
      return (
        <FormattedMessage
          id="activity.changed_global_status"
          defaultMessage="{sourceUserName} set global status to {status}."
          values={{
            sourceUserName: activity.additionalInfo.sourceUserName,
            status: activity.additionalInfo.status
              ? "deaktiviert"
              : "aktiviert",
          }}
        />
      );
    }
    return null;
  };

  render() {
    const { venue, processingActivityLog, activityLog } = this.props;
    if (processingActivityLog) {
      return <Loading />;
    }

    if (!activityLog || !activityLog.length) {
      return (
        <p className="activity-empty">
          Keine Aktivität in den letzten 30 Tagen
        </p>
      );
    }

    return (
      <div className="timeline-container">
        <div className="vertical-line" />
        <div className="timeline">
          {activityLog &&
            venue &&
            activityLog.map((activity) => {
              const message = this.generateFormattedMessage(activity);
              if (message) {
                return (
                  <TimelineEntry
                    key={activity.id}
                    message={message}
                    time={moment.unix(activity.time).utc().local().fromNow()}
                    icon={getActionIcon(activity.action)}
                  />
                );
              }
              return null;
            })}
        </div>
      </div>
    );
  }
}

ActivityLog.propTypes = {
  retrieveVenue: PropTypes.func.isRequired,
  venue: PropTypes.object,
  processingActivityLog: PropTypes.bool.isRequired,
  retrieveActivityLog: PropTypes.func.isRequired,
  activityLog: PropTypes.array,
  currentVenueId: PropTypes.string,
  updateNavBarTitle: PropTypes.func.isRequired,
};

ActivityLog.defaultProps = {
  venue: null,
  activityLog: null,
  currentVenueId: null,
};

export default ActivityLog;
