import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import OrOptionIcon from "./CheckBoxIcon";
import AndOptionIcon from "./OptionButtonIcon";
import ItemIcon from "@material-ui/icons/Remove";
// import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { SelectOptionDialog } from "./SelectOptionDialog";
import { useSelector } from "react-redux";
import { buildSelectAllOptionList } from "../../../util/helpers";

interface OptionTreeEntryProps {
  // key: any;
  // index: any;
  type: any;
  id: any;
  entry: any;
  label: any;
  parentId: any;
  // parentType: any;
  // list: any;
  // link: any;
  level: any | undefined | null;
  option: any;
  venueId: any;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function OptionTreeEntry(props: OptionTreeEntryProps) {
  const { type, id, entry, label, parentId, level, option, venueId } = props;

  const [highlight, setHighlight] = useState(false);
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [openSelectOptionDialog, setOpenSelectOptionDialog] = useState(false);
  const closeMenu = (): any => {};
  const { menu, strings, defaultLocale } = useSelector(
    (state: any) => state.venue.venue
  );
  const handleAddExistingChild = (): any => {
    if (type === "OPTION") {
      if (entry.options) {
        setOpenSelectOptionDialog(true);
        // openSelectOptionDialog(id, "OPTION");
      }
    }
    setAddAnchorEl(null);
  };

  const handleAddExistingSibling = (): any => {
    if (type === "OPTION") {
      setOpenSelectOptionDialog(true);
      // openSelectOptionDialog(parentId, parentType);
    }
    setAddAnchorEl(null);
  };

  let icon = null;
  if (type === "OPTION" && entry.type === "AND") {
    icon = <OrOptionIcon viewBox="0 0 125 125" />;
  } else if (type === "OPTION" && entry.type === "OR") {
    icon = <AndOptionIcon viewBox="0 0 100 100" />;
  } else {
    icon = <ItemIcon />;
  }
  const actions = [];
  // if (highlight) {
  //   actions.push(
  //     <IconButton
  //       key={`${id}-edit`}
  //       // component={Link as any}
  //       // /* @ts-ignore */
  //       // to={`${window.location.pathname}${link}`}
  //     >
  //       <EditIcon />
  //     </IconButton>
  //   );
  // }
  if (type === "OPTION") {
    actions.push(
      <IconButton
        key={`${id}-add`}
        onClick={(event) => setAddAnchorEl(event.currentTarget as any)}
      >
        <AddIcon />
      </IconButton>
    );
  }

  return (
    <>
      <ListItem
        onMouseEnter={() => {
          setHighlight(true);
        }}
        onMouseLeave={() => {
          setHighlight(false);
        }}
        style={{
          paddingLeft: 24 * level,
          backgroundColor: highlight ? "#efefef" : undefined,
        }}
      >
        <ListItemIcon style={{ marginRight: 0 }}>{icon}</ListItemIcon>
        <ListItemText primary={label} />
        {/* <ListItemSecondaryAction>{actions}</ListItemSecondaryAction> */}
        <Menu
          anchorEl={addAnchorEl}
          open={Boolean(addAnchorEl)}
          onClose={closeMenu}
        >
          {/* <MenuItem onClick={handleAddNewSibling}>New Sibling</MenuItem> */}
          <MenuItem onClick={handleAddExistingSibling}>
            Existing Sibling
          </MenuItem>
          {/* <MenuItem onClick={handleAddNewChild}>New Child</MenuItem> */}
          <MenuItem onClick={handleAddExistingChild}>Existing Child</MenuItem>
        </Menu>
      </ListItem>
      <SelectOptionDialog
        open={openSelectOptionDialog}
        setOpen={setOpenSelectOptionDialog}
        options={buildSelectAllOptionList(menu, strings[defaultLocale])}
        option={option}
        venueId={venueId}
        optionId={entry.options ? id : parentId}
      />
    </>
  );
}
