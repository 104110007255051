const pad = (num) => (num.toString().length > 1 ? num : `0${num}`);

export const formattedTime = (seconds) => {
  if (seconds <= 0) {
    return "00:00";
  }
  if (seconds > 0 && seconds < 3600) {
    return [pad(parseInt(seconds / 60, 10)), pad(seconds % 60)].join(":");
  }
  return [
    pad(parseInt(seconds / 3600, 10)),
    pad(parseInt((seconds - Math.floor(seconds / 3600) * 3600) / 60, 10)),
    pad(seconds % 60),
  ].join(":");
};
