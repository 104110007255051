import {
  select,
  takeLatest,
  call,
  put,
  all,
  takeEvery,
  delay,
  take,
  fork,
  race,
} from "redux-saga/effects";
import * as types from "../types";
import {
  createMarkOrderDoneAPI,
  createVenueOrdersAPI,
  createPrintOrderAPI,
  createAcknowledgeOrderAPI,
  checkOrderedByItemIdAPI,
} from "../../lib/venue";
import {
  retrieveOrdersSuccess,
  retrieveOrdersFailure,
  markOrderAsDoneSuccess,
  reprintOrderSuccess,
  reprintOrderFailure,
  acknowledgeOrderSuccess,
  acknowledgeOrderFailure,
  markOrderAsDone,
  purgeOrderRequest,
  checkOrderedByItemIdSuccess,
  checkOrderedByItemIdFailure,
} from "../actions/orders";
import { INSIDE_WEBVIEW } from "../../config";
import { TO_NATIVE_LOCAL_PRINT_JOB } from "@orda/backend-shared-constants/tablet-bridge-events";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";
import { generateId } from "@orda/shared-functions-js";
import { generate } from "shortid";
import moment from "moment";
import { send } from "../actions/bridge";
import { REHYDRATE } from "redux-persist";
import { NEW_ORDER_PRINTJOB } from "@orda/shared-constants-js/print-jobs";

const BASE_WAITING_TIME = 100; // ms
const MAX_BACKOFF_THRESHOLD = 300000; // ms

function* retrieveOrdersSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { venueId, start, end, isHobbyCook } = action;

    const response = yield call(
      fetch,
      createVenueOrdersAPI(venueId, {
        start,
        end,
        isHobbyCook,
      }),
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
        }),
      }
    );
    const data = yield call([response, response.json]);
    yield put(retrieveOrdersSuccess(data));
  } catch (error) {
    yield put(retrieveOrdersFailure(error));
  }
}

function* markAsDoneSaga(action) {
  const { venueId, orderId, userId, usedMode, ttl, retries } = action;
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    if (retries) {
      yield delay(
        Math.min(2 ** retries * BASE_WAITING_TIME, MAX_BACKOFF_THRESHOLD)
      );
    }

    const pressedDoneAt = moment().unix();
    const response = yield call(fetch, createMarkOrderDoneAPI(venueId), {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        userId,
        orderId,
        doneAt: pressedDoneAt,
      }),
    });

    const { doneAt, en } = yield call([response, response.json]);

    if (!response.ok) {
      throw new Error(en);
    }

    yield put(markOrderAsDoneSuccess(orderId, usedMode, doneAt));
  } catch (error) {
    if (ttl && moment.unix(ttl).diff(moment(), "days") > 7) {
      yield put(purgeOrderRequest(orderId));
    } else {
      yield put(
        markOrderAsDone(venueId, orderId, userId, usedMode, ttl, retries + 1)
      );
    }
  }
}

function* reprintOrderSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);

    const { venueId, order } = action;
    if (INSIDE_WEBVIEW) {
      const printerList = uniq(
        flatMap(order.items, ({ printers }) => printers)
      );

      yield all(
        printerList.map((printer) => {
          const job = {
            id: generateId(generate),
            type: NEW_ORDER_PRINTJOB,
            venueId,
            createdAt: moment().unix(),
            orderId: order.orderId,
            userId: order.userId,
            printerQueue: printer,
            local: true,
          };
          return put(send(TO_NATIVE_LOCAL_PRINT_JOB, { job, order }));
        })
      );

      // if print on POS - bypass storing print job in Dynamo
      yield put(reprintOrderSuccess(order.orderId));
      return;
    }

    const authToken = yield call([user, user.getIdToken]);

    yield call(fetch, createPrintOrderAPI(venueId), {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        userId: order.userId,
        orderId: order.orderId,
      }),
    });

    yield put(reprintOrderSuccess(order.orderId));
  } catch (error) {
    yield put(reprintOrderFailure(error));
  }
}

function* acknowledgeOrderSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);

    const { orderId, userId, venueId } = action;

    const response = yield call(
      fetch,
      createAcknowledgeOrderAPI(venueId, orderId),
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          userId,
        }),
      }
    );

    const { acknowledgedAt } = yield call([response, response.json]);

    if (!response.ok) {
      throw new Error("Error acknowledging the order");
    }

    yield put(acknowledgeOrderSuccess(orderId, acknowledgedAt));
  } catch (error) {
    yield put(acknowledgeOrderFailure(error));
  }
}

function* retryMarkAsDoneSaga() {
  yield all([take(REHYDRATE), take(types.auth.SYNC_FIREBASE_USER)]);
  const pendingOrders = yield select(
    (state) => state.orders.requests.markOrderAsDone
  );

  yield all(
    pendingOrders.map(({ orderId, venueId, userId, usedMode, ttl, retries }) =>
      put(markOrderAsDone(venueId, orderId, userId, usedMode, ttl, retries))
    )
  );
}

function* checkOrderedByItemIdSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield call([user, user.getIdToken]);
    const { venueId, itemId, start, end, isHobbyCook } = action;

    const response = yield call(
      fetch,
      checkOrderedByItemIdAPI(venueId, itemId, {
        start,
        end,
        isHobbyCook,
      }),
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
        }),
      }
    );
    const data = yield call([response, response.json]);

    yield put(checkOrderedByItemIdSuccess(data));
  } catch (error) {
    yield put(checkOrderedByItemIdFailure(error));
  }
}

function* markAsDoneCancellableSaga(action) {
  yield race({
    task: call(markAsDoneSaga, action),
    cancel: take(types.orders.PURGE_ALL_PENDING_ORDER_REQUESTS),
  });
}

export default function* ordersRootSaga() {
  yield all([
    takeLatest(types.orders.RETRIEVE_ORDERS.REQUEST, retrieveOrdersSaga),
    takeEvery(
      types.orders.MARK_ORDER_AS_DONE.REQUEST,
      markAsDoneCancellableSaga
    ),
    takeLatest(types.orders.REPRINT_ORDER.REQUEST, reprintOrderSaga),
    takeLatest(types.orders.ACKNOWLEDGE_ORDER.REQUEST, acknowledgeOrderSaga),
    takeLatest(types.orders.ORDER.CHECK_ORDERED, checkOrderedByItemIdSaga),
    fork(retryMarkAsDoneSaga),
  ]);
}
