import { connect } from "react-redux";
import ActivityLog from "../components/ActivityLog";
import { retrieveVenue } from "../redux/actions/venue";
import { retrieveActivityLog } from "../redux/actions/activityLog";
import { updateNavBarTitle } from "../redux/actions/uiState";

const mapStateToProps = (state) => {
  const {
    activityLog: {
      requests: {
        retrieveActivityLog: { processing: processingActivityLog },
      },
      activityLog,
    },
    venue: { venue },
    uiState: { currentVenueId },
  } = state;
  return {
    venue,
    activityLog,
    processingActivityLog,
    currentVenueId,
  };
};

const mapDispatchToProps = (dispatch, { appBarTitle }) => ({
  retrieveVenue: (venueId) => dispatch(retrieveVenue(venueId)),
  updateNavBarTitle: () => dispatch(updateNavBarTitle(appBarTitle)),
  retrieveActivityLog: (venueId) => dispatch(retrieveActivityLog(venueId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
