import React from "react";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const styles: any = {
  container: {
    position: "relative",
  },
  progress: {
    left: -20,
    top: 50,
    marginLeft: "50%",
  },
};

interface LoadingProps {
  classes: any;
  customIconStyle?: any;
}

const Loading: React.FC<LoadingProps> = ({
  classes,
  customIconStyle,
}): ReturnType<React.FC<LoadingProps>> => {
  return (
    <div className={classes.container}>
      <CircularProgress
        size={40}
        className={classes.progress}
        style={customIconStyle}
      />
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  customIconStyle: PropTypes.object,
};

Loading.defaultProps = {
  customIconStyle: {},
};

export default withStyles(styles)(Loading);
