/* eslint no-param-reassign: 0 */
import { cloneDeep } from "lodash";
import * as types from "../types";
import produce from "immer";

export const initialState = {
  tables: null,
  getVenueTablesRequest: {
    processing: false,
    error: null,
    success: false,
  },
  deleteVenueTableRequest: {
    processing: false,
    error: null,
    success: false,
  },
  currentTableNumber: null,
  updateTableNumberRequest: {
    processing: false,
    error: null,
    success: false,
  },
};

function tablesReducer(state = initialState, action) {
  switch (action.type) {
    case types.tables.SET_TABLE_NUMBER:
      return produce(state, (newState) => {
        newState.currentTableNumber = action.tableNumber;
      });
    case types.tables.UPDATE_TABLE_NUMBER.REQUEST:
      return produce(state, (newState) => {
        newState.updateTableNumberRequest.processing = true;
        newState.updateTableNumberRequest.error = null;
        newState.updateTableNumberRequest.success = false;
      });
    case types.tables.UPDATE_TABLE_NUMBER.SUCCESS:
      return produce(state, (newState) => {
        newState.updateTableNumberRequest.processing = false;
        newState.updateTableNumberRequest.error = null;
        newState.updateTableNumberRequest.success = true;
      });
    case types.tables.UPDATE_TABLE_NUMBER.FAILURE:
      return produce(state, (newState) => {
        newState.updateTableNumberRequest.processing = false;
        newState.updateTableNumberRequest.error = action.error;
        newState.updateTableNumberRequest.success = false;
      });
    case types.tables.UPDATE_TABLE_NUMBER.RESET:
      return produce(state, (newState) => {
        newState.updateTableNumberRequest.processing = false;
        newState.updateTableNumberRequest.error = null;
        newState.updateTableNumberRequest.success = false;
      });
    case types.tables.GET_VENUE_TABLES.REQUEST:
      return produce(state, (newState) => {
        newState.getVenueTablesRequest.processing = true;
        newState.getVenueTablesRequest.error = null;
        newState.getVenueTablesRequest.success = false;
      });
    case types.tables.GET_VENUE_TABLES.SUCCESS:
      return produce(state, (newState) => {
        newState.tables = action.tables;
        newState.getVenueTablesRequest.error = null;
        newState.getVenueTablesRequest.processing = false;
        newState.getVenueTablesRequest.success = true;
      });
    case types.tables.GET_VENUE_TABLES.FAILURE:
      return produce(state, (newState) => {
        newState.getVenueTablesRequest.processing = false;
        newState.getVenueTablesRequest.error = action.error;
        newState.getVenueTablesRequest.success = false;
      });
    case types.tables.DELETE_VENUE_TABLE.REQUEST:
      return produce(state, (newState) => {
        newState.deleteVenueTableRequest.processing = true;
        newState.deleteVenueTableRequest.error = null;
        newState.deleteVenueTableRequest.success = false;
      });
    case types.tables.DELETE_VENUE_TABLE.SUCCESS:
      return produce(state, (newState) => {
        newState.deleteVenueTableRequest.processing = false;
        // delete the table from local array
        const tablesCopy = cloneDeep(newState.tables);
        tablesCopy.splice(
          tablesCopy.findIndex((table) => table.id === action.tableId),
          1
        );
        newState.tables = tablesCopy;
        newState.deleteVenueTableRequest.success = true;
        newState.deleteVenueTableRequest.error = null;
      });
    case types.tables.DELETE_VENUE_TABLE.FAILURE:
      return produce(state, (newState) => {
        newState.deleteVenueTableRequest.processing = false;
        newState.deleteVenueTableRequest.error = action.error;
        newState.deleteVenueTableRequest.success = false;
      });
    default:
      return state;
  }
}

export default tablesReducer;
