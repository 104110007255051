import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CustomFileInput from "../../../../CustomFileInput";
import ImageUploadIcon from "../../../../../assets/icons/image-upload.svg";
import VideoUploadIcon from "../../../../../assets/icons/video-upload.svg";
import DraggableVideos from "./DraggableVideos";

import "../../UpdateDishDialog.css";

interface Props {
  itemId: string;
  videos: any;
  closeDialog: any;
  venueId: string;
  updateItemFilesAction: any;
}

const UpdateDishFiles: React.FC<Props> = ({
  itemId,
  videos,
  updateItemFilesAction,
  closeDialog,
  venueId,
}): ReturnType<React.FC<Props>> => {
  const { t } = useTranslation("updateDish");
  const imageReader: any = new FileReader();
  const videoReader: any = new FileReader();

  const [itemImage, setItemImage] = useState<any>(null);
  const [itemImageUrl, setItemImageUrl] = useState<string>("");

  const [itemVideos, setItemVideos] = useState<any[]>(
    videos.map((videoUrl: any): any => ({
      type: "video",
      url: videoUrl,
    }))
  );

  const handleOnItemPictureChange = useCallback(
    (event: any): void => {
      const { files } = event.target;

      if (files && files.length) {
        imageReader.addEventListener("load", () => {
          setItemImageUrl(imageReader.result);
          setItemImage({ file: files[0] });
        });
        imageReader.readAsDataURL(files[0]);
      }
    },
    [setItemImageUrl, setItemImage]
  );

  const handleOnItemVideosChange = useCallback(
    (event: any): void => {
      const { files } = event.target;

      if (files && files.length) {
        videoReader.addEventListener("load", () => {
          setItemVideos([...itemVideos, { file: files[0] }]);
        });
        videoReader.readAsDataURL(files[0]);
      }
    },
    [setItemVideos, itemVideos]
  );

  const handleOnSubmitForm = useCallback((): void => {
    closeDialog();
    updateItemFilesAction(venueId, itemId, itemImage, itemVideos);
  }, [venueId, itemId, itemImage, itemVideos, updateItemFilesAction]);

  return (
    <DialogContent>
      <form id="update-menu-item-dialog-form" onSubmit={handleOnSubmitForm}>
        <DialogContent style={{ padding: "1%" }}>
          <div
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              padding: "12px",
            }}
          >
            <DraggableVideos
              itemVideos={itemVideos}
              setItemVideos={setItemVideos}
            />
          </div>
          <div className="update-dish-file-input">
            <CustomFileInput
              id="imageFile"
              name="itemImage"
              label={t("chooseAnImage")}
              iconHeight={60}
              inputProps={{
                onChange: handleOnItemPictureChange,
                required: true,
                accept: "image/*",
              }}
              color="#424242"
              iconSrc={ImageUploadIcon}
            />
            <CustomFileInput
              id="videoFile"
              name="itemVideos"
              label={t("chooseAVideo")}
              iconHeight={55}
              inputProps={{
                onChange: handleOnItemVideosChange,
                accept: "video/*",
                multiple: true,
              }}
              infoMsg={t("infoVideoMsg")}
              color="#424242"
              iconSrc={VideoUploadIcon}
            />
          </div>
          <div className="update-dish-file-results">
            <div>
              {itemImageUrl && (
                <img
                  alt="Crop"
                  src={itemImageUrl}
                  style={{ maxWidth: "100%", maxHeight: 150 }}
                />
              )}
            </div>
            <div></div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            type="button"
            onClick={handleOnSubmitForm}
            style={{
              backgroundColor: "#B9D47F",
              color: "#2C2F3A",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            form="update-files-dialog-form"
          >
            {t("submitUpdate")}
          </Button>
        </DialogActions>
      </form>
    </DialogContent>
  );
};

UpdateDishFiles.propTypes = {
  itemId: PropTypes.string.isRequired,
  videos: PropTypes.array,
  venueId: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateItemFilesAction: PropTypes.func.isRequired,
};

UpdateDishFiles.defaultProps = {
  videos: [],
};
export default UpdateDishFiles;
