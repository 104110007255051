import { generate } from "shortid";
import * as types from "../types";
import { StatusEnum } from "../../constants";
import notificationStrings from "../../strings/notificationStrings";

const addMessageToState = (state, status, message, toast) =>
  [
    {
      id: generate(),
      status,
      message,
      toast,
      isOpen: true,
    },
  ].concat(state);

export default function notificationsReducer(state = [], action) {
  switch (action.type) {
    // ** VENUES NOTIFICATIONS ACTIONS :
    case types.venue.VENUE_CREATE_ITEM.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.ADD_ITEM_SUCCESS,
        true
      );
    case types.venue.VENUE_CREATE_ITEM.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.ADD_ITEM_ERROR
      );
    case types.venue.VENUE_CREATE_SECTION.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.ADD_SECTION_SUCCESS,
        true
      );
    case types.venue.VENUE_CREATE_SECTION.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.ADD_SECTION_ERROR
      );
    case types.venue.ADD_EXISTING_OPTION_TO_ITEM.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.SUCCESS_ADD_EXISTING_OPTION_TO_ITEM,
        true
      );
    case types.venue.ADD_EXISTING_OPTION_TO_ITEM.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.ERROR_ADD_EXISTING_OPTION_TO_ITEM
      );
    case types.venue.ADD_EXISTING_OPTION_TO_OPTION.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.SUCCESS_ADD_EXISTING_OPTION_TO_OPTION,
        true
      );
    case types.venue.ADD_EXISTING_OPTION_TO_OPTION.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.ERROR_ADD_EXISTING_OPTION_TO_OPTION
      );
    case types.venue.VENUE_UPDATE_ITEM.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.UPDATE_ITEM_SUCCESS,
        true
      );
    case types.venue.VENUE_UPDATE_ITEM.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.UPDATE_ITEM_ERROR
      );
    case types.venue.VENUE_UPDATE_SECTION.SUCCESS:
      return addMessageToState(
        state,
        StatusEnum.SUCCESS,
        notificationStrings.de.VENUE.UPDATE_SECTION_SUCCESS,
        true
      );
    case types.venue.VENUE_UPDATE_SECTION.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.UPDATE_SECTION_ERROR
      );
    case types.venue.VENUE_DELETE_ITEM.FAILURE:
      return addMessageToState(
        state,
        StatusEnum.ERROR,
        notificationStrings.de.VENUE.DELETE_ITEM_ERROR
      );

    // ** GLOBAL NOTIFICATIONS ACTIONS :
    case types.notifications.SET:
      return addMessageToState(
        state,
        action.status,
        action.message,
        action.toast
      );
    case types.notifications.CLOSE_LATEST: {
      const newStateList = Array.from(state || []);
      if (newStateList.length > 0) {
        newStateList[0].isOpen = false;
      }
      return newStateList;
    }
    case types.notifications.SHIFT: {
      const newStateList = Array.from(state || []);
      newStateList.shift();
      return newStateList;
    }
    case types.notifications.CLOSE_BY_ID: {
      const newStateList = Array.from(state || []);
      const indexOfNotification = newStateList.findIndex(
        (e) => e.id === action.id
      );
      if (indexOfNotification !== -1) {
        newStateList[indexOfNotification].isOpen = false;
      }
      return newStateList;
    }
    case types.notifications.CLOSE_ALL:
      return (state || []).map((notification) => ({
        ...notification,
        isOpen: false,
      }));
    case types.notifications.CLEAR:
      return [];
    default:
      return state;
  }
}
