import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SectionNameDialog from "../components/Menu/dish-dialog/SectionNameDialog";
import {
  createSection,
  updateSection,
  updateSectionOrder,
} from "../redux/actions/venue";

const mapStateToProps = (state) => {
  const {
    venue: { venue },
    uiState: { currentVenueId },
  } = state;

  return {
    currentVenueId,
    strings: !!venue && venue.strings,
    sectionOrder: get(venue, "menu.sectionOrder", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateSection: (venueId, names) =>
      dispatch(createSection(venueId, names)),
    handleRenameSection: (venueId, sectionId, names) =>
      dispatch(updateSection(venueId, sectionId, names)),
    updateSectionOrder: (venueId, sectionOrder) =>
      dispatch(updateSectionOrder(venueId, sectionOrder)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionNameDialog)
);
