import { API_ENDPOINT } from "../../config";
import { eventChannel } from "redux-saga";
import io from "socket.io-client";
import { call, cancel, fork, take, select, put } from "redux-saga/effects";
import * as types from "../types";
import { SocketEvent } from "@orda/shared-constants/backoffice/order-events";
import {
  updateOrders,
  retrieveOrders,
  updateOrderTOGO,
} from "../actions/orders";
import { setNotification } from "../actions/notifications";
import { enqueueOrderReminder } from "../actions/orderReminders";
import moment from "moment-timezone";
import { delay } from "../../util/delay";
import { get } from "lodash";
import { StatusEnum } from "../../constants";

const SOCKET_EMIT_TYPE = {
  MESSAGE: 1,
  CONNECT: 2,
};

function createCancellationToken() {
  return {
    cancelled: false,
  };
}

async function authenticateSocket(
  client,
  emit,
  user,
  venueId,
  cancellationToken
) {
  while (!cancellationToken.cancelled) {
    let authToken;
    try {
      // eslint-disable-next-line no-await-in-loop
      authToken = await user.getIdToken();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error retrieving id token for user: ${error.message}`);
    }
    if (authToken && !cancellationToken.cancelled) {
      try {
        client.emit("authentication", {
          token: authToken,
          venueId,
        });
        emit({ type: SOCKET_EMIT_TYPE.CONNECT });
        break;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error emitting authentication on client or emit: ${error.message}`
        );
      }
    }
    // eslint-disable-next-line no-await-in-loop
    await delay(1000);
  }
}

function socketChannel(user, venueId) {
  return eventChannel((emit) => {
    const client = io.connect(API_ENDPOINT);
    let cancellationToken = createCancellationToken();

    client.on("connect", () => {
      cancellationToken.cancelled = true;
      cancellationToken = createCancellationToken();
      authenticateSocket(client, emit, user, venueId, cancellationToken);
    });

    client.on("disconnect", (reason) => {
      cancellationToken.cancelled = true;
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        client.connect();
      }
    });

    Object.keys(SocketEvent).forEach((event) => {
      client.on(SocketEvent[event], (payload) => {
        emit({
          type: SOCKET_EMIT_TYPE.MESSAGE,
          data: {
            type: SocketEvent[event],
            payload,
          },
        });
      });
    });

    return () => {
      cancellationToken.cancelled = true;
      client.close();
    };
  });
}

function* shouldUpdateOrderReminders(payload) {
  const orderReminders = yield select(
    (state) => state.orderReminders.orderReminders
  );
  if (orderReminders && orderReminders.length) {
    return orderReminders.every(({ orderId }) => orderId !== payload.orderId);
  }
  return true;
}
function* shouldUpdateOrders(payload) {
  const orders = yield select((state) => state.orders.orders);
  if (orders && orders.length) {
    return orders.every(({ orderId, groupOrderId }) =>
      groupOrderId
        ? groupOrderId !== payload.groupOrderId
        : orderId !== payload.orderId
    );
  }
  return true;
}

function* socketSaga(user, venueId) {
  const channel = yield call(socketChannel, user, venueId);

  while (true) {
    const { type, data } = yield take(channel);
    switch (type) {
      case SOCKET_EMIT_TYPE.CONNECT: {
        const start = moment().tz("Europe/Berlin");
        start.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const end = moment(start);
        end.add(1, "d");
        const currentVenueId = yield select(
          (state) => state.uiState.currentVenueId
        );
        const isHobbyCook = yield select((state) =>
          get(state, ["venue", "venue", "hobbyCook"], false)
        );
        yield put(
          retrieveOrders(currentVenueId, start.unix(), end.unix(), isHobbyCook)
        );
        break;
      }
      case SOCKET_EMIT_TYPE.MESSAGE: {
        const { type: messageType, payload: messagePayload } = data;
        switch (messageType) {
          case SocketEvent.GroupOrder:
          case SocketEvent.NewOrder: {
            if (yield call(shouldUpdateOrders, messagePayload)) {
              yield put(updateOrders(messagePayload));
            }
            break;
          }
          case SocketEvent.OrderReminder: {
            if (yield call(shouldUpdateOrderReminders, messagePayload)) {
              yield put(enqueueOrderReminder(messagePayload));
            }
            break;
          }
          case SocketEvent.MarkOrderToGo: {
            yield put(
              setNotification(
                StatusEnum.IDLE,
                `Die Bestellung ${messagePayload.orderNumber} wurde auf To-Go geändert.`
              )
            );
            yield put(updateOrderTOGO(messagePayload.orderId));
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        throw new Error(`[SOCKET] unknown message type: ${type}`);
    }
  }
}

export default function* socketRootSaga() {
  while (true) {
    yield take(types.auth.RETRIEVE_USER_VENUES.SUCCESS);

    const user = yield select((state) => state.auth.user);
    const venueId = yield select((state) => state.uiState.currentVenueId);

    const socket = yield fork(socketSaga, user, venueId);
    yield take(types.auth.LOGOUT.SUCCESS);
    yield cancel(socket);
  }
}
