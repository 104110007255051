import { connect } from "react-redux";
import Nav from "../components/Nav";
import { retrieveVenue } from "../redux/actions/venue";
import {
  resetBuildNumberTapCount,
  updateCurrentVenue,
  toggleStatusDialog,
} from "../redux/actions/uiState";
import { logout } from "../redux/actions/auth";

const mapStateToProps = (state) => {
  const {
    venue: {
      requests: {
        retrieveVenue: { processing },
      },
      venue,
    },
    auth: {
      requests: {
        login: { processing: processingLogin },
      },
      user,
      venues,
    },
    uiState: { buildNumberTapCount, currentVenueId },
  } = state;
  return {
    retrieveVenueProcessing: processing,
    venue,
    buildNumberTapCount,
    currentVenueId,
    processingLogin,
    user,
    venues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  retrieveVenue: (venueId) => dispatch(retrieveVenue(venueId)),
  resetBuildNumberTapCount: () => dispatch(resetBuildNumberTapCount()),
  updateCurrentVenue: (venueId) => dispatch(updateCurrentVenue(venueId)),
  logout: () => dispatch(logout()),
  openStatusDialog: () => dispatch(toggleStatusDialog(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
