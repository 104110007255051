import React from "react";
import moment from "moment";
import { VENUE_MODE_OTC } from "@orda/shared-constants-js/venue-types";
import TimedOrderListItem from "./TimedOrderListItem";
import OrderListItem from "./OrderListItem";
import PropTypes from "prop-types";
import TimeSync from "time-sync";

const MAX_ORDER_TIME_THRESHHOLD = 2; // 2 hours

class OrderListItemWrapper extends React.Component {
  getOrderTimeCounter = (currentTime) => {
    const {
      order: { doneAt, fullTime },
    } = this.props;
    const orderTimeDiff = currentTime - fullTime;
    const now = moment.unix(currentTime);

    if (
      doneAt &&
      moment.unix(doneAt).diff(moment.unix(fullTime), "hours") <
        MAX_ORDER_TIME_THRESHHOLD
    ) {
      return {
        orderTimerCounter: doneAt - fullTime,
        displayTimed: false,
      };
    }
    if (now.diff(moment.unix(fullTime), "hours") >= MAX_ORDER_TIME_THRESHHOLD) {
      return {
        orderTimerCounter: 60 * 60 * MAX_ORDER_TIME_THRESHHOLD,
        displayTimed: false,
      }; // 2 hours
    }
    return {
      orderTimerCounter: orderTimeDiff,
      displayTimed: true,
    };
  };

  render() {
    const {
      order,
      future,
      markAsDoneEnabled,
      markOrderAsDone,
      reprintOrder,
      currentVenueId,
      venueLiteMode,
    } = this.props;

    const pushDisabled =
      future ||
      !markAsDoneEnabled ||
      (order.doneAt && order.usedMode !== VENUE_MODE_OTC);

    const currentTime = TimeSync.getCurrentTime();
    const { orderTimerCounter, displayTimed } =
      this.getOrderTimeCounter(currentTime);

    const now = moment.unix(currentTime);
    const isToday = moment.unix(order.fullTime).isSame(now, "day");
    // TODO: check if hits time limit and stop counter
    if (orderTimerCounter >= 0 && !order.doneAt && isToday && displayTimed) {
      return (
        <TimedOrderListItem
          pushDisabled={pushDisabled}
          order={order}
          getOrderTimeCounter={this.getOrderTimeCounter}
          markOrderAsDone={markOrderAsDone}
          reprintOrder={reprintOrder}
          currentVenueId={currentVenueId}
          venueLiteMode={venueLiteMode}
        />
      );
    }
    return (
      <OrderListItem
        displayTime={orderTimerCounter}
        order={order}
        venueLiteMode={venueLiteMode}
        pushDisabled={pushDisabled}
        markOrderAsDone={markOrderAsDone}
        reprintOrder={reprintOrder}
        currentVenueId={currentVenueId}
      />
    );
  }
}

OrderListItemWrapper.propTypes = {
  order: PropTypes.object.isRequired,
  future: PropTypes.bool.isRequired,
  markAsDoneEnabled: PropTypes.bool.isRequired,
  venueLiteMode: PropTypes.bool.isRequired,
  reprintOrder: PropTypes.func.isRequired,
  markOrderAsDone: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string,
};

OrderListItemWrapper.defaultProps = {
  currentVenueId: null,
};

export default OrderListItemWrapper;
