import React from "react";
import { DAYS, connectTime } from "react-time-sync";
import moment from "moment";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import { DatePicker } from "material-ui-pickers";

import "./Sales.css";

const tableStyles = {
  tableCellWide: {
    width: "70%",
    fontSize: 14,
    whitespace: "normal",
    wordWrap: "break-word",
    paddingLeft: 6,
    paddingRight: 6,
  },
  tableCell: {
    fontSize: 14,
    textAlign: "right",
    whitespace: "normal",
    wordWrap: "break-word",
    paddingLeft: 6,
    paddingRight: 6,
  },
  tableCellWideBold: {
    width: "70%",
    fontSize: 14,
    fontWeight: 600,
    whitespace: "normal",
    wordWrap: "break-word",
    paddingLeft: 6,
    paddingRight: 6,
  },
  tableCellBold: {
    fontSize: 14,
    textAlign: "right",
    fontWeight: 600,
    whitespace: "normal",
    wordWrap: "break-word",
    paddingLeft: 6,
    paddingRight: 6,
  },
};

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSpan: {
        selected: moment().hours(0).minutes(0).seconds(0),
        start: moment().hours(0).minutes(0).seconds(0),
        end: moment(),
      },
    };
  }

  componentDidMount() {
    const { updateNavBarTitle, retrieveSales, currentVenueId } = this.props;
    const {
      timeSpan: { start, end },
    } = this.state;
    updateNavBarTitle();
    retrieveSales(currentVenueId, start.unix(), end.unix());
  }

  handleDateChange = (date) => {
    this.setState(
      {
        timeSpan: {
          selected: date,
          start: moment(date),
          end: moment(date).add(1, "d"),
        },
      },
      () => {
        const {
          timeSpan: { start, end },
        } = this.state;
        const { currentVenueId, retrieveSales } = this.props;
        retrieveSales(currentVenueId, start.unix(), end.unix());
      }
    );
  };

  render() {
    const { data, retrieveSalesProcessing } = this.props;
    const { timeSpan } = this.state;

    const formatttedNumberStyle = "currency";
    return (
      <div className="sales-container">
        <Paper style={{ padding: 20 }}>
          <DatePicker
            style={{ marginTop: 10 }}
            value={timeSpan.selected}
            okLabel="Ok"
            cancelLabel="Abbrechen"
            format="DD.MM.YYYY"
            disableFuture
            label="Wählen Sie ein Datum"
            onChange={this.handleDateChange}
          />
        </Paper>
        {retrieveSalesProcessing || !data ? (
          <div style={{ position: "relative" }}>
            <CircularProgress
              size={40}
              left={-20}
              top={50}
              style={{ marginLeft: "50%" }}
            />
          </div>
        ) : (
          <Paper style={{ padding: 20, marginTop: 10 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={tableStyles.tableCellWideBold}>
                    Anzahl an Bestellungen
                  </TableCell>
                  <TableCell style={tableStyles.tableCellBold}>
                    {data.numOrders} *
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={tableStyles.tableCellWideBold}
                    className="sales-no-border"
                  >
                    Gesamtumsatz vor Rabatten
                  </TableCell>
                  <TableCell
                    style={tableStyles.tableCellBold}
                    className="sales-no-border"
                  >
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style={formatttedNumberStyle}
                      currency="EUR"
                      value={data.totalBeforeBenefits / 100}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={tableStyles.tableCellWide}>
                    Rabatte
                  </TableCell>
                  <TableCell style={tableStyles.tableCell}>
                    {"- "}
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="EUR"
                      value={data.benefitSum / 100}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={tableStyles.tableCellWideBold}
                    className="sales-no-border"
                  >
                    Brutto Umsatz
                  </TableCell>
                  <TableCell
                    style={tableStyles.tableCellBold}
                    className="sales-no-border"
                  >
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="EUR"
                      value={data.grossTotal / 100}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={tableStyles.tableCellWide}>
                    Trinkgeld
                  </TableCell>
                  <TableCell style={tableStyles.tableCell}>
                    {"+ "}
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="EUR"
                      value={data.tips / 100}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={tableStyles.tableCellWideBold}
                    className="sales-no-border"
                  >
                    Gesamtumsatz
                  </TableCell>
                  <TableCell
                    style={tableStyles.tableCellBold}
                    className="sales-no-border"
                  >
                    <FormattedNumber
                      // eslint-disable-next-line react/style-prop-object
                      style={formatttedNumberStyle}
                      currency="EUR"
                      value={data.total / 100}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
        <div
          style={{
            padding: 16,
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: "bold",
          }}
        >
          * - Gruppenbestellungen werden individuell gezählt
        </div>
      </div>
    );
  }
}

Sales.propTypes = {
  retrieveSales: PropTypes.func.isRequired,
  retrieveSalesProcessing: PropTypes.bool.isRequired,
  data: PropTypes.object,
  updateNavBarTitle: PropTypes.func.isRequired,
  currentVenueId: PropTypes.string,
};

Sales.defaultProps = {
  data: null,
  currentVenueId: null,
};

export default connectTime({ interval: DAYS })(Sales);
