// eslint-disable-next-line import/no-named-as-default
import Unauthorised from "../components/Unauthorised";
import { connect } from "react-redux";
import { updateNavBarTitle } from "../redux/actions/uiState";

const mapStateToProps = (state) => {
  const {
    auth: { user },
  } = state;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateNavBarTitle: () => dispatch(updateNavBarTitle(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorised);
