import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNavBarTitle } from "../redux/actions/uiState";
import PropTypes from "prop-types";
import {
  retrieveCampaignsRequest,
  updateCampaignRequest,
  createCampaignReset,
} from "../redux/actions/marketing";
import Loading from "./Loading";
import Error from "./Error";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography, Switch, withStyles } from "@material-ui/core";
import { CampaignStatus } from "@orda/shared-constants";
import moment from "moment";
import { Modifier } from "@orda/shared-constants/benefits";
import { Benefit, VenueBenefitHours, VenueTimeSlot } from "@orda/shared-types";
import { blue, grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import routes from "../routes";
import { logEvent } from "../lib/analytics";
import {
  MARKETING_TOGGLE_CAMPAIGN_STATUS,
  SCREEN_VIEW,
} from "../lib/analytics/events";

const styles: any = {
  container: {
    padding: "1rem",
  },
  createCampaignButton: {
    padding: 10,
    height: 120,
  },
  iconContainer: {
    textAlign: "center",
  },
  icon: {
    color: "lightgray",
    width: 80,
    height: 80,
  },
  campaignName: {
    fontFamily: "Open Sans",
    fontSize: 18,
  },
  titles: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#828282",
  },
  root: {
    padding: 8,
    paddingLeft: 12,
    paddingBottom: 20,
    paddingRight: 12,
    paddingTop: 12,
    flex: 1,
  },
  campaignNameContainer: {
    paddingBottom: 24,
  },
  information: {
    fontSize: 15,
    fontFamily: "Open Sans",
  },
  informationHighlight: {
    fontSize: 15,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  campaignsContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
  },
  switchBase: {
    height: "auto",
  },
  checked: {
    color: blue[50],
    "&$checked": {
      color: "#0288D1",
      "& + $bar": {
        backgroundColor: "#0288D1",
      },
    },
  },
  bar: {},
  disabled: {
    color: grey[50],
    "&$checked": {
      color: grey[500],
      "& + $bar": {
        backgroundColor: grey[500],
      },
    },
  },
  notesContainer: {
    marginTop: 10,
  },
};

interface CampaignsProps {
  appBarTitle: string;
  classes: any;
}

const Campaigns: React.FC<CampaignsProps> = ({
  appBarTitle,
  classes,
}): ReturnType<React.FC<CampaignsProps>> => {
  const dispatch = useDispatch();
  const { t } = useTranslation("marketing");

  const {
    requests: {
      retrieveCampaigns: { error, processing },
    },
    data,
  } = useSelector<any, any>((state) => state.marketing as any);

  const currentVenueId = useSelector<any, any>(
    (state) => state.uiState.currentVenueId
  );

  useEffect(() => {
    dispatch(updateNavBarTitle(appBarTitle));
    dispatch(createCampaignReset());
    dispatch(retrieveCampaignsRequest(currentVenueId));
    logEvent(SCREEN_VIEW, {
      id: "CAMPAIGNS",
    });
  }, []);

  const getCampaignTimeRange = (benefits: Benefit[]): string => {
    const timeEligibleBenefits = benefits.filter(
      (benefit) => !!benefit.validWithinHours
    );
    if (timeEligibleBenefits.length) {
      const benefitHours = (
        timeEligibleBenefits[0].validWithinHours as VenueBenefitHours
      ).hours;
      const benefitHoursKeys = Object.keys(benefitHours);
      const benefitSlots =
        benefitHours[
          Math.min(
            benefitHoursKeys.map((benefitKey) => parseInt(benefitKey, 10))[0]
          ) as any
        ];

      // moment is used just for fomatting the times. current time not relevant
      const startTime = moment()
        .set({
          hour: (benefitSlots as VenueTimeSlot[])[0].startHour,
          minute: (benefitSlots as VenueTimeSlot[])[0].startMinute,
        })
        .format("HH:mm");

      const endTime = moment()
        .set({
          hour: (benefitSlots as VenueTimeSlot[])[0].endHour,
          minute: (benefitSlots as VenueTimeSlot[])[0].endMinute,
        })
        .format("HH:mm");

      return `${startTime} - ${endTime}`;
    }
    return t("benefitAlwaysAvailable");
  };

  if (error) {
    return (
      <Error
        error={{ message: t("retrieveCampaignsError") }}
        updateNavBarTitle={() => updateNavBarTitle(appBarTitle)}
      />
    );
  }

  if (processing) {
    return <Loading />;
  }

  return (
    <Grid container spacing={16} className={classes.campaignsContainer}>
      {data &&
        !!data.length &&
        data.map((campaign: any) => (
          <Grid item xs={12} key={campaign.id}>
            <Paper className={classes.root}>
              <Grid container>
                <Grid item xs={12} className={classes.campaignNameContainer}>
                  <Typography className={classes.campaignName}>
                    {campaign.name}
                  </Typography>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <Typography className={classes.titles}>
                      {t("startDate")}
                    </Typography>
                    <Typography className={classes.information}>
                      {moment.unix(campaign.createdAt).format("DD.MM.YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {campaign.benefits && !!campaign.benefits.length && (
                      <Grid>
                        <Typography className={classes.titles}>
                          {t("timeRange")}
                        </Typography>
                        <Typography className={classes.information}>
                          {getCampaignTimeRange(campaign.benefits)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {/* discount amount will only be shown if it's a single basket based benefit */}
                    {campaign.benefits &&
                      campaign.benefits.length === 1 &&
                      campaign.benefits[0].modifier === Modifier.Multiply &&
                      campaign.benefits[0].validForAll && (
                        <Grid>
                          <Typography className={classes.titles}>
                            {t("discountAmount")}
                          </Typography>
                          <Typography className={classes.informationHighlight}>
                            {campaign.benefits[0].amount * 100}%
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.titles}>
                      {t("status")}
                    </Typography>
                    <Typography className={classes.information}>
                      {campaign.status === CampaignStatus.InReview ||
                      !(campaign.benefits && campaign.benefits.length) ? (
                        t("inReview")
                      ) : (
                        <Switch
                          checked={campaign.status === CampaignStatus.Active}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.checked,
                            bar: classes.bar,
                            disabled: classes.disabled,
                          }}
                          onChange={() => {
                            logEvent(MARKETING_TOGGLE_CAMPAIGN_STATUS, {
                              campaignId: campaign.id,
                              status: !(
                                campaign.status === CampaignStatus.Active
                              ),
                            });
                            dispatch(
                              updateCampaignRequest(
                                currentVenueId,
                                campaign.id,
                                campaign.status,
                                campaign.benefits.map(
                                  (benefit: any) => benefit.id
                                )
                              )
                            );
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {campaign.note && (
                  <Grid item xs={12} className={classes.notesContainer}>
                    <Typography className={classes.titles}>
                      {t("description")}
                    </Typography>
                    <Typography className={classes.information}>
                      {campaign.note}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        ))}
      <Grid item xs={12}>
        <Link to={routes.createCampaign.url} href={routes.createCampaign.url}>
          <Paper className={classes.createCampaignButton}>
            <Typography variant="body1">{t("createCampaign")}</Typography>
            <div className={classes.iconContainer}>
              <Add className={classes.icon} fontSize="large" />
            </div>
          </Paper>
        </Link>
      </Grid>
    </Grid>
  );
};

Campaigns.propTypes = {
  appBarTitle: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Campaigns);
